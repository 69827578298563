import React from 'react';
import { ContentFile, FileType, UploadFileType } from 'types/feature/content/contents';

type Props = {
  fileType: FileType | undefined;
  contentFile: ContentFile | undefined;
};

const PreviewContainer = ({ contentFile, fileType }: Props) => {
  return (
    <div className='flex items-center justify-center w-full bg-neutral-50'>
      <div className='w-[80%] h-[600px] flex items-center justify-center'>
        {fileType === UploadFileType.CONTENT_IMAGE ? (
          <img className='object-contain h-full' src={contentFile?.uri} alt='content' />
        ) : (
          <video controls className='object-contain h-full' src={contentFile?.uri} muted />
        )}
      </div>
    </div>
  );
};

export default PreviewContainer;
