import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { MouseEvent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FLEX_CENTER } from 'styles/flex';
import { SubMenu } from 'types/common/menus';
import { RoutePath } from 'types/common/paths';
import { TextType } from 'types/common/text';

type SubMenusProps = {
  subMenus: SubMenu[];
  handleOpenSubMenus: () => void;
};

const SubMenus = ({ subMenus, handleOpenSubMenus }: SubMenusProps) => {
  const { navigateToReplacedPath, replaceWorkspaceId } = useCustomNavigate();
  const { workspaceId } = useParams();
  const location = useLocation();

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (e.target instanceof HTMLDivElement) {
      const subMenu = e.target.id as RoutePath;
      navigateToReplacedPath(subMenu, workspaceId);
    }
  };

  return (
    <div
      onFocus={handleOpenSubMenus}
      onMouseOver={handleOpenSubMenus}
      className='w-[120px] absolute top-[62px] z-50 left-[50%] -translate-x-1/2 cursor-pointer'
    >
      <div className='flex justify-center'>
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'>
          <path d='M6 0L11.5 9H0.5L6 0Z' fill='white' />
        </svg>
      </div>

      <div className={`shadow-drop rounded-[2px] py-1 bg-primary-White`}>
        {subMenus.map(({ id, path }) => {
          const subPath = replaceWorkspaceId(path, workspaceId);
          const isSubActive = location.pathname.includes(subPath);

          return (
            <div
              id={path}
              onClick={handleClick}
              className={`h-10 w-full ${FLEX_CENTER.row} ${
                isSubActive ? `${TextType.h4_bold} text-gray-44` : `${TextType.h4} text-gray-99`
              } hover:!font-bold`}
              key={id}
            >
              {id}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default SubMenus;
