import FormControl from 'components/common/form-control/FormControl';
import { Floor } from 'types/feature/floor/floor';

type Props = {
  machineFloor?: Floor;
};

const FloorEditController = ({ machineFloor }: Props) => {
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='층'
        control={<div className='text-h4 h-[30px] flex items-center'>{machineFloor?.name.ko}</div>}
      />
    </div>
  );
};

export default FloorEditController;
