import Next from 'assets/base/Next';
import Previous from 'assets/base/Previous';
import { PAGINATION_LIMIT } from 'data/common';
import ReactPaginate from 'react-paginate';

type PaginationProps = {
  totalElements: number;
  currentPage: number;
  onChangePage: (page: number) => void;
};

// 공통으로 사용하는 pagination component
// 데이터는 최상단에서 관리해주세요
const Pagination = ({ currentPage, totalElements, onChangePage }: PaginationProps) => {
  const handleChangePage = (selectedItem: { selected: number }) => {
    onChangePage.call(null, selectedItem.selected);
  };

  return (
    <ReactPaginate
      className='flex items-center justify-center w-full cursor-pointer'
      previousLabel={<Previous />}
      nextLabel={<Next />}
      pageCount={Math.ceil(totalElements / PAGINATION_LIMIT)}
      forcePage={currentPage}
      onPageChange={handleChangePage}
      pageClassName='w-[40px] h-[40px] text-[14px] text-gray-cc font-medium flex justify-center items-center'
      activeClassName='w-[40px] h-[40px] text-[14px] !text-gray-44 flex justify-center items-center !font-bold'
    />
  );
};

export default Pagination;
