import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';

type Props = {
  currentMenu: RoutePath;
  handleClickItem: (path: RoutePath) => void;
  path: RoutePath;
  name: string;
};

const SettingMenu = ({ handleClickItem, path, name, currentMenu }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const { workspaceId } = useParams();

  const handleActiveMenu = () => {
    if (!workspaceId) {
      return;
    }

    if (currentMenu === path.replace(':workspaceId', workspaceId)) {
      setIsActive(true);
      return;
    }

    setIsActive(false);
  };

  useEffect(() => {
    handleActiveMenu();
  }, [workspaceId, currentMenu]);

  return (
    <div
      onClick={() => handleClickItem(path)}
      className={`${
        isActive && 'font-bold'
      } cursor-pointer text-base hover:font-semibold pr-5 h-[48px] flex items-center text-gray-44`}
    >
      {name}
    </div>
  );
};
export default SettingMenu;
