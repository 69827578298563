import { TableHeader } from 'types/common/tableHeader';

export const MONITORING_TABLE_HEADER: TableHeader = [
  { label: 'No', width: 60, direction: 'center' },
  { label: '구분', width: 100, direction: 'center' },
  { label: '층', width: 80, direction: 'center' },
  { label: '기기코드', width: 140, direction: 'center' },
  { label: '상세위치', width: 580, direction: 'left' },
  { label: '상태', width: 120, direction: 'center' },
  { label: '재시작', width: 120, direction: 'center' },
];

export const REGISTER_TABLE_HEADER: TableHeader = [
  { label: 'No', width: 60, direction: 'center' },
  { label: '구분', width: 100, direction: 'center' },
  { label: '타입', width: 100, direction: 'center' },
  { label: '유형', width: 100, direction: 'center' },
  { label: '층', width: 80, direction: 'center' },
  { label: '기기코드', width: 140, direction: 'center' },
  { label: '상세위치', width: 340, direction: 'left' },
  { label: '최근 수정일', width: 160, direction: 'center' },
  { label: '상태', width: 120, direction: 'center' },
];
