/* eslint-disable no-nested-ternary */
import { Outlet, useLocation } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import Border from '../border/Border';
import TitleContainer from '../title-container/TitleContainer';
import Navigation from './nav/Navigation';
import OuterNav from './outer/OuterNav';

const Layout = () => {
  const location = useLocation();

  const pagesWithoutCommonLayout =
    location.pathname === RoutePath.workspaces ||
    location.pathname === RoutePath.workspaceAdd ||
    location.pathname.includes('/setting') ||
    location.pathname.includes('/workspace');

  return (
    <div>
      {location.pathname === RoutePath.login ? (
        <Outlet />
      ) : pagesWithoutCommonLayout ? (
        <div className='flex flex-col items-center justify-center w-full h-full'>
          <OuterNav />

          <Border />

          <div
            className={`flex flex-col items-center w-full ${
              location.pathname === RoutePath.workspaceAdd ? 'bg-white' : 'bg-bg-f9'
            }`}
          >
            <Outlet />
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center w-full h-full gap-10'>
          <Navigation />
          <div className='flex flex-col items-center justify-center w-[1400px] h-full mt-20 gap-10'>
            <TitleContainer />
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};
export default Layout;
