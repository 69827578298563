import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FailPopup from 'components/common/popup/FailPopup';
import LoadingSpinner from 'components/common/spinner/LoadingSpinner';
import { IMAGES } from 'constants/images';
import useAuth from 'hooks/common/useAuth';
import { ChangeEvent, useState } from 'react';
import { FLEX_CENTER } from 'styles/flex';
import { SIZE } from 'styles/size';
import LoginInputField from './inputs/LoginInputField';

export type LoginFormType = {
  username: string;
  password: string;
};

const LoginForm = () => {
  const [formData, setFormData] = useState<LoginFormType>({
    username: '',
    password: '',
  });
  const [isOpen, setOpen] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isClicked, setClicked] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { login } = useAuth();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setClicked(true);
    setLoading(true);

    try {
      const loginData: LoginFormType = {
        ...formData,
        password: window.btoa(formData.password), // password base64로 인코딩
      };

      await login(loginData);
    } catch (error) {
      setOpen(true);

      if (error instanceof AxiosError) {
        if (error.response?.status) {
          // 유저 에러
          if (error.response?.status === 401 && error.response?.data) {
            return setErrorText('아이디 혹은 비밀번호를 확인해주세요.');
          }
        }
      }

      setErrorText('서버에 문제가 발생했습니다.');
    } finally {
      setClicked(false);
      setLoading(false);
    }
  };

  // login form onchange
  const handleChangeLoginForm = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const isEmpty = !formData.username || !formData.password;

  return (
    <div className={`flex flex-col items-center justify-center ${SIZE.screen} bg-slate-600`}>
      <div className='flex flex-col w-[30%] h-[60%] sm:w-[50%] sm:h-[50%] md:w-[50%] md:h-[60%] lg:w-[25%] items-center justify-between py-28 rounded-xl bg-white gap-10'>
        <div className='w-[25%]'>
          <img src={IMAGES.LOGO_DABEEO} alt='로고' />
        </div>

        <form onSubmit={handleLogin} className={`${FLEX_CENTER.column} w-[80%] y-full gap-4`}>
          <LoginInputField formData={formData} handleChangeLoginForm={handleChangeLoginForm} />
          <Button
            rounded='rounded'
            type='submit'
            size='full'
            disabled={isEmpty || isClicked}
            text='로그인'
            color={isEmpty ? ButtonColor.primary_disable : ButtonColor.primary}
          />
        </form>
      </div>

      <div className='pt-6 text-xs font-light text-white'>Copyright ⓒ 2023 Dabeeo Inc. All Rights Reserved.</div>

      {isOpen && <FailPopup text={errorText} closeModal={() => setOpen(false)} />}
      {isLoading && <LoadingSpinner />}
    </div>
  );
};
export default LoginForm;
