import { ICON } from 'constants/icons';
import { IMAGES } from 'constants/images';
import { ChangeEvent, useRef } from 'react';
import { FileUploadType } from 'types/common/file/fileUpload';

type CommonFileInputProps = {
  fileName?: string;
  name?: string;
  disabled?: boolean;
  fileType?: FileUploadType | null;
  onChangeFile: (e: ChangeEvent<HTMLInputElement>) => void;
  onDeleteFile: () => void;
  visibleIcon?: boolean;
  key?: string;
  id?: string;
  border?: boolean;
};

const CommonFileInput = ({
  onChangeFile,
  onDeleteFile,
  disabled = false,
  name,
  fileType,
  fileName,
  visibleIcon = true,
  key,
  id,
  border = false,
}: CommonFileInputProps) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const acceptType =
    fileType === FileUploadType.CONTENT_VIDEO ? 'video/webm, video/mp4' : 'image/png, image/jpeg, image/jpg';

  const handleClick = () => {
    fileRef.current?.click();
  };

  const deleteFile = () => {
    if (fileRef.current) {
      onDeleteFile();
      fileRef.current.value = '';
    }
  };

  return (
    <div className='flex flex-col gap-3'>
      <div
        className={`flex items-center justify-between gap-2 pl-2 pr-2 h-9 w-[320px] ${
          border && 'border border-gray-99'
        }`}
      >
        <label
          className={`${
            disabled ? 'text-gray-cc' : 'text-gray-99'
          } text-ellipsis overflow-hidden whitespace-nowrap  cursor-pointer text-xs`}
          htmlFor={id}
        >
          {disabled ? '파일 유형을 선택해주세요.' : fileName || '파일을 업로드 해주세요.'}
        </label>
        <input
          key={key}
          accept={acceptType}
          name={name}
          disabled={disabled}
          ref={fileRef}
          type='file'
          id={id}
          className='hidden border-0 text-gray-99 file:hidden'
          onChange={e => {
            const { files } = e.target;
            if (files) {
              onChangeFile(e);
            }
          }}
        />

        {/* // TODO: 아이콘 정리 필요 */}
        {visibleIcon && !fileName && (
          <div className='w-5 h-5 cursor-pointer' onClick={handleClick}>
            <img className='w-full h-full' src={IMAGES.FILE_UPLOAD} alt='파일 업로드' />
          </div>
        )}

        {/* 삭제 아이콘 */}
        {fileName && (
          <div onClick={deleteFile} className='cursor-pointer'>
            <img src={ICON.TRASH} alt='삭제' />
          </div>
        )}
      </div>
    </div>
  );
};
export default CommonFileInput;
