import { IndexType } from 'types/common';
import { CustomPageType } from '../custom-page/customPage';
import { MultiLangs } from '../language/language';

export type CustomMainMenuType = {
  code: string;
  description: string;
};

export type MenuCustomPage = {
  id: string;
  type: CustomPageType;
};

export type MainMenu = {
  id: string;
  type: MenuType;
  active: boolean;
  default: boolean;
  name: MultiLangs;
  sortOrder: number;
  iconId: string;
  iconUsed: boolean;
  mainIconUri: string;
  subIconUri: string;
  isNew?: boolean;
  customPage?: MenuCustomPage;
};

export type PostMainMenu = {
  type: MenuType;
  name: MultiLangs;
  iconUsed: boolean;
  iconId: string;
  customPageType?: CustomPageType;
};

export type PatchMenu = {
  active?: boolean;
  name?: MultiLangs;
  sortOrder?: number;
  iconUsed?: boolean;
  iconId?: string;
};

export type MainMenuIcon = {
  id: string;
  description: string;
  mainIconUri: string;
  subIconUri: string;
};

export type MenuType = keyof typeof ConvertMenuTypeInWord;

export const ConvertMenuTypeInWord: IndexType = {
  HOME: '홈',
  FLOOR: '층별 안내',
  TENANT: '매장 검색',
  FACILITY: '편의시설',
  CUSTOM_BOARD: '커스텀 게시판',
  MOBILE: '모바일',
  NONE: '없음',
} as const;
