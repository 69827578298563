import Input from 'components/common/input/Input';
import Label from 'components/common/label/Label';
import { CustomFieldTitleWordLimit } from 'data/length';
import useLanguage from 'hooks/feature/language/useLanguage';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Language, LanguageName, MultiLangs } from 'types/feature/language/language';
import { TenantField } from 'types/feature/setting/tenant/tenantField';

type Props = {
  customFieldName?: MultiLangs;
  customField?: TenantField;
  handleChangeMenuName: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomFieldMultiLangInputs = ({ handleChangeMenuName, customField, customFieldName }: Props) => {
  const { workspaceId } = useParams();
  const { getLanguages } = useLanguage();
  const [languages, setLanguages] = useState<Language[]>([]);

  const getLanguageList = async () => {
    if (workspaceId) {
      const result = await getLanguages(workspaceId);
      const displayLanguages: Language[] = result.filter(lang => lang.used);

      setLanguages(displayLanguages);
    }
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  return (
    <div className='w-full flex flex-col gap-2.5'>
      {languages.map(language => {
        const multiLangLimit = CustomFieldTitleWordLimit[language.code.toLowerCase()];
        return (
          <div key={language.id} className='flex items-center gap-2'>
            <Label labelSize='md' labelTextSize='h5_bold' name={LanguageName[language.code]} />
            <div className='flex items-center gap-4'>
              <Input
                maxLength={multiLangLimit}
                onChange={e => {
                  const { length } = e.target.value;
                  if (length > multiLangLimit) return;
                  handleChangeMenuName(e);
                }}
                width='w-72'
                defaultValue={customFieldName && customFieldName[language.code.toLowerCase()]}
                placeholder={`${LanguageName[language.code]} 항목 이름을 입력해주세요.`}
                id={language.code.toLowerCase()}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomFieldMultiLangInputs;
