import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertPopup from 'components/common/popup/AlertPopup';
import { ICON } from 'constants/icons';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useCustomPage from 'hooks/feature/custom-page/useCustomPage';
import useMenu from 'hooks/feature/menu/useMenu';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePointStore from 'stores/point';
import { RoutePath } from 'types/common/paths';
import { CustomPage } from 'types/feature/custom-page/customPage';
import { MultiLangs } from 'types/feature/language/language';
import CustomPageTable from './list/CustomPageTable';
import CustomPageSelect from './select/CustomPageSelect';

const CustomPageContainer = () => {
  const { getCustomPage } = useCustomPage();
  const { navigateToReplacedPath } = useCustomNavigate();

  const [userCustomPageList, setUserCustomPageList] = useState<{ name: MultiLangs; customPageId: string }[]>([]);
  const [customPage, setCustomPage] = useState<CustomPage>();
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { workspaceId, customPageId } = useParams();
  const { getMainMenus } = useMenu();

  const pointStore = usePointStore();

  const moveCreateCustomPage = () => {
    navigateToReplacedPath(RoutePath.customPageAdd, workspaceId, ':customPageId', customPageId);
  };

  useEffect(() => {
    // 메인메뉴 리스트를 가져옴
    if (customPageId) {
      fetchMainMenus();
      fetchCustomPageList(customPageId);
    }
  }, []);

  // 메인 메뉴를 가져옴
  const fetchMainMenus = async () => {
    try {
      if (pointStore.currentPoint) {
        const result = await getMainMenus(pointStore.currentPoint.id);

        const customPages = result.data.filter(menu => menu.customPage !== null);
        const customPageItems = customPages.map(menu => {
          return {
            name: menu.name,
            customPageId: menu.customPage?.id ?? '',
          };
        });

        setUserCustomPageList(customPageItems);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status && error.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage(error.message);
        }
      }
    }
  };

  // 커스텀 페이지 리스트 가져오기
  const fetchCustomPageList = async (id: string) => {
    try {
      const result = await getCustomPage(id);
      if (result) {
        setCustomPage(result.data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status && error.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage(error.message);
        }
      }
    }
  };

  // select 변경 시
  const handleChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value: currentCustomPageId } = e.target;

    // refetch
    fetchCustomPageList(currentCustomPageId);

    // 페이지 이동
    navigateToReplacedPath(RoutePath.customPage, workspaceId, ':customPageId', currentCustomPageId);
  };

  return (
    <>
      <div className='flex flex-col items-end w-full gap-10'>
        <div className='flex justify-between w-full'>
          <CustomPageSelect
            currentId={customPageId}
            handleSelectItem={handleChangeSelect}
            customPageList={userCustomPageList}
          />
          <Button color={ButtonColor.secondary} onClick={moveCreateCustomPage}>
            <div className='flex items-center gap-2'>
              <img src={ICON.ADD} alt='추가' />
              <div className='text-sm font-semibold'>콘텐츠 등록</div>
            </div>
          </Button>
        </div>

        <CustomPageTable customBoards={customPage?.contents ?? []} />
      </div>
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default CustomPageContainer;
