import Title from 'components/common/title/Title';
import { useState } from 'react';
import WorkspaceEditForm from './WorkspaceForm';

const WorkspaceAddContainer = () => {
  const [workspaceId, setWorkspaceId] = useState('');

  return (
    <div className={`bg-white w-full max-w-[1400px] py-10 flex flex-col gap-10`}>
      <Title text='새 워크스페이스 생성' titleType='h2' />

      <WorkspaceEditForm setWorkspaceId={setWorkspaceId} workspaceId={workspaceId} />

      {/* {enableNext && (
        <div className='flex flex-col gap-10'>
          <PointForm workspaceId={workspaceId} />
          <Border borderColor={BorderColor.gray} />
          <MobileEditForm isEdit={false} workspaceId={workspaceId} />
        </div>
      )} */}
    </div>
  );
};

export default WorkspaceAddContainer;
