import _ from 'lodash';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { create } from 'zustand';

type ScreensaverStoreProps = {
  screensavers: GetScreensaver[] | [];
  setScreensavers: (screensavers: GetScreensaver[]) => void;
};

const useScreensaverStore = create<ScreensaverStoreProps>((set, get) => ({
  screensavers: [],
  setScreensavers: (screensavers: GetScreensaver[]) => set(() => ({ screensavers: _.cloneDeep(screensavers) })),
}));

export default useScreensaverStore;
