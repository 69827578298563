import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Account } from 'types/common/account';

const useUserAccount = () => {
  const { api } = useAxios();

  const getAccount = async () => {
    const response = await api.get<ApiResponse<Account>>(Endpoint.account);
    return response.data;
  };

  return {
    getAccount,
  };
};

export default useUserAccount;
