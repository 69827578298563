import FlexFormControl from 'components/common/form-control/FlexFormControl';
import AlertPopup from 'components/common/popup/AlertPopup';
import SelectBox from 'components/common/select/SelectBox';
import useCategory from 'hooks/feature/category/useCategory';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import useCategoryStore from 'stores/category';
import { TenantCategoryFeatureCode } from 'types/feature/category/tenantCategory';
import { Point } from 'types/feature/workspace/workspace';
import TenantCategoryGroups from './features/TenantCategoryGroups';
import UserCategories from './user-categories/UserCategories';

type CategoryContainerProps = {
  featureCode: TenantCategoryFeatureCode;
};

const CategoryContainer = ({ featureCode }: CategoryContainerProps) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [poiCategoryCodes, setPoiCategoryCodes] = useState<string[]>([]);
  const [pointList, setPointList] = useState<Point[]>([]);
  const [currentPoint, setCurrentPoint] = useState<string>();

  const { tenantCategoryGroups } = useCategoryStore();
  const { getWorkspace } = useWorkspace();

  const { postTenantCategoryGroup, refetchFeatureCategories } = useCategory();
  const { workspaceId } = useParams();

  const fetchWorkspace = async () => {
    if (!workspaceId) return;
    const workspace = await getWorkspace(workspaceId);
    if (workspace) {
      setCurrentPoint(workspace.data.points[0].id);
      setPointList(workspace.data.points);
    }
    return workspace;
  };

  // 카테고리 추가
  const handleDragCategory = async (dropResult: DropResult) => {
    try {
      // TODO: 이미 등록된 카테고리일 경우 예외 처리 필요
      if (!workspaceId) return;

      // 이상한 곳에 놓았을 경우
      if (!dropResult.destination) return;
      const workspace = await getWorkspace(workspaceId);
      if (workspace) {
        if (currentPoint) {
          const resultOfCategoryGroup = await postTenantCategoryGroup({
            pointId: currentPoint,
            feature: featureCode,
            contentCategoryCode: dropResult.draggableId,
          });
          if (resultOfCategoryGroup) {
            await refetchFeatureCategories(currentPoint, featureCode);
          }
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setOpenAlert(true);
        setAlertMessage('카테고리를 추가할 수 없습니다.');
      }
    }
  };

  const getCategoryGroupCodes = () => {
    const categoryGroups = tenantCategoryGroups.map(categoryGroup => categoryGroup.categories[0].contentCategoryCode);

    setPoiCategoryCodes(categoryGroups);
  };

  const handleChangeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = e.target;

    setCurrentPoint(value);
  };

  useEffect(() => {
    fetchWorkspace();
  }, []);

  useEffect(() => {
    getCategoryGroupCodes();
  }, [tenantCategoryGroups]);

  return (
    <div className='flex flex-col w-full gap-10'>
      <FlexFormControl
        name='빌딩'
        labelSize='md'
        control={
          <SelectBox
            width='w-40'
            onChange={handleChangeCategory}
            options={pointList.map(({ id, name }) => {
              return {
                id,
                value: name.ko,
              };
            })}
          />
        }
      />

      <div className='flex gap-10 h-[640px] w-full'>
        <DragDropContext onDragEnd={handleDragCategory}>
          <>
            <TenantCategoryGroups featureCode={featureCode} pointId={currentPoint} />
            <UserCategories poiCategoryCodes={poiCategoryCodes} pointId={currentPoint} />
          </>
        </DragDropContext>
      </div>
      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </div>
  );
};
export default CategoryContainer;
