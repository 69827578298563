import { ICON } from 'constants/icons';
import { FLEX_CENTER } from 'styles/flex';

const DraggableButton = () => {
  return (
    <div className={`${FLEX_CENTER.row} cursor-pointer w-[36px]`}>
      <img src={ICON.Hamburger} alt='hamburger' />
    </div>
  );
};
export default DraggableButton;
