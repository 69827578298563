import { useState } from 'react';
import { ScreenType } from 'types/feature/screensaver/getScreensaver';

const useMapController = () => {
  const [screenType, setScreenType] = useState<ScreenType>('HORIZONTAL');
  // 기기의 가로 세로 변경
  const handleChangeScreenType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setScreenType(value as ScreenType);
  };

  return {
    handleChangeScreenType,
    screenType,
  };
};

export default useMapController;
