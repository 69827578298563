/* eslint-disable @typescript-eslint/no-shadow */
import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import LoadingBackground from 'components/common/loading/LoadingBackground';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import Textarea from 'components/common/textarea/Textarea';
import useUserAccount from 'hooks/common/useUserAccount';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { WorkspaceForm } from 'types/feature/workspace/workspace';
import ToggleMobileUsed from '../edit/ToggleMobileUsed';
import ToggleZoomButtonUsed from '../edit/ToggleZoomButtonUsed';

type Props = {
  setWorkspaceId: (id: string) => void;
  workspaceId: string;
};

const WorkspaceEditForm = ({ setWorkspaceId, workspaceId }: Props) => {
  const [workspaceForm, setWorkspaceForm] = useState<WorkspaceForm>({
    name: '',
    description: '',
    mapId: '',
    mobileUsed: false,
    zoomButtonUsed: false,
    machineType: 'WEB',
  });
  const [openFailPopup, setOpenFailPopup] = useState(false);
  const [confirmWorkspacePopup, setConfirmWorkspacePopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [userMaps, setUserMaps] = useState<{ id: string; name: string }[]>([]);

  const { getAccount } = useUserAccount();
  const { saveWorkspace } = useWorkspace();

  const navigate = useNavigate();

  useEffect(() => {
    fetchAccount();
  }, []);

  // 계정 정보
  const fetchAccount = async () => {
    const result = await getAccount();
    if (result) {
      const selectMapArr = [{ id: '', name: '지도를 선택해주세요.' }, ...(result.maps || [])];
      setUserMaps(selectMapArr);
    }
  };

  // validate
  const handleSubmitWorkspaceForm = () => {
    if (isEmpty(workspaceForm.name)) {
      setConfirmWorkspacePopup(false);
      handleAlertMessage('워크스페이스 이름을 입력해주세요.');
      return;
    }

    if (isEmpty(workspaceForm.mapId)) {
      handleAlertMessage('연동할 지도를 선택해주세요.');
      setConfirmWorkspacePopup(false);
      return;
    }

    createWorkspace();
  };

  const handleAlertMessage = (message: string) => {
    setOpenFailPopup(true);
    setSaveLoading(false);
    setConfirmPopup(false);
    setAlertMessage(message);
  };

  // 생성 api
  const createWorkspace = async () => {
    try {
      setConfirmWorkspacePopup(false);
      setSaveLoading(true);
      const workspaceId = await saveWorkspace(workspaceForm);

      if (workspaceId) {
        setSaveLoading(false);
        setWorkspaceId(workspaceId);

        setConfirmPopup(true);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          handleAlertMessage('에러가 발생했습니다.');
        }
      }
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setWorkspaceForm((prev: WorkspaceForm) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setWorkspaceForm((prev: WorkspaceForm) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value, id } = e.target;

    setWorkspaceForm((prev: WorkspaceForm) => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <>
      <div className='flex flex-col gap-3'>
        <FlexFormControl
          name='워크스페이스 이름'
          labelSize='md2'
          required
          control={
            <Input
              width='w-[276px]'
              name='name'
              placeholder='워크스페이스 이름을 입력해주세요.'
              onChange={onChangeInput}
            />
          }
        />

        <FlexFormControl
          name='지도'
          labelSize='md2'
          required
          control={
            <CommonSelectBox
              selectId='mapId'
              width='w-[276px]'
              onChange={onChangeSelect}
              options={
                userMaps.map(({ id, name }) => {
                  return {
                    id,
                    value: name,
                  };
                }) || []
              }
            />
          }
        />

        <FlexFormControl
          name='상세 정보'
          labelSize='md2'
          control={
            <Textarea
              placeholder='상세정보를 입력해주세요.'
              name='description'
              width='w-[502px]'
              onChange={onChangeTextArea}
            />
          }
        />

        <FlexFormControl
          name='기기 타입'
          labelSize='md2'
          required
          control={
            <CommonSelectBox
              selectId='machineType'
              width='w-[276px]'
              onChange={onChangeSelect}
              options={
                [
                  { label: 'WEB', value: '웹 키오스크' },
                  { label: 'DESKTOP', value: '설치형 키오스크' },
                ].map(({ label, value }) => {
                  return {
                    id: label,
                    value,
                  };
                }) || []
              }
            />
          }
        />

        <FlexFormControl
          name='모바일 키오스크'
          labelSize='md2'
          control={<ToggleMobileUsed mobileUsed={workspaceForm.mobileUsed} setWorkspaceForm={setWorkspaceForm} />}
        />

        <FlexFormControl
          name='줌버튼'
          labelSize='md2'
          control={
            <ToggleZoomButtonUsed zoomButtonUsed={workspaceForm.zoomButtonUsed} setWorkspaceForm={setWorkspaceForm} />
          }
        />
      </div>

      <div className='flex justify-start pl-40 pt-[30px]'>
        <Button text='생성 ' type='button' color={ButtonColor.primary} onClick={() => setConfirmWorkspacePopup(true)} />
      </div>

      {openFailPopup && <FailPopup closeModal={() => setOpenFailPopup(false)} text={alertMessage} />}

      {confirmPopup && (
        <ConfirmPopup
          okMessage='포인트 설정하기'
          cancelMessage='목록으로 이동하기'
          message={`워크스페이스 저장이 완료되었습니다.\n 포인트 정보를 설정하시겠습니까?`}
          onClickOk={() => navigate(RoutePath.pointSetting.replace(':workspaceId', workspaceId))}
          onClickCancel={() => navigate(RoutePath.workspaces)}
        />
      )}

      {confirmWorkspacePopup && (
        <ConfirmPopup
          okMessage='워크스페이스 생성'
          cancelMessage='취소'
          message={`새로운 워크 스페이스를 생성하시겠습니까?`}
          onClickOk={handleSubmitWorkspaceForm}
          onClickCancel={() => setConfirmWorkspacePopup(false)}
        />
      )}
      {saveLoading && <LoadingBackground message='워크스페이스를 생성 중입니다...' />}
    </>
  );
};

export default WorkspaceEditForm;
