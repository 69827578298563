import { SETTING_MENUS } from 'data/menu/settingMenus';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import SettingMenu from './item/SettingMenu';

type Props = {
  currentMenu: RoutePath;
  setCurrentMenu: Dispatch<SetStateAction<RoutePath>>;
};

const SettingNavigation = ({ currentMenu, setCurrentMenu }: Props) => {
  const { workspaceId } = useParams();
  const { navigateToReplacedPath } = useCustomNavigate();

  const handleClickItem = (path: RoutePath) => {
    navigateToReplacedPath(path, workspaceId);
    setCurrentMenu(path);
  };

  return (
    <div className='flex flex-col justify-start border-r shrink-0 w-[200px] pt-5'>
      {SETTING_MENUS.map(menu => (
        <Fragment key={menu.path}>
          <SettingMenu currentMenu={currentMenu} handleClickItem={handleClickItem} path={menu.path} name={menu.name} />
        </Fragment>
      ))}
    </div>
  );
};

export default SettingNavigation;
