import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { CustomMainMenuType, MainMenu, MainMenuIcon, PatchMenu, PostMainMenu } from 'types/feature/menu/menu';

const useMenu = () => {
  const { api } = useAxios();

  const getMainMenus = async (pointId: string) => {
    const result = await api.get<ApiResponse<MainMenu[]>>(Endpoint.mainMenusV2, {
      params: {
        pointId,
      },
    });
    return result;
  };

  const getCustomMenuTypes = async () => {
    const result = await api.get<ApiResponse<CustomMainMenuType[]>>(Endpoint.customMainMenuTypes);
    return result;
  };

  const getMainMenuIconTypes = async () => {
    const result = await api.get<ApiResponse<MainMenuIcon[]>>(Endpoint.mainMenuIconTypes);
    return result.data;
  };

  const postMainMenu = async (pointId: string, postMenuField: PostMainMenu) => {
    const result = await api.post(Endpoint.mainMenusV2, {
      pointId,
      ...postMenuField,
    });

    return result;
  };

  const deleteMainMenu = async (menuId: string) => {
    await api.delete(`${Endpoint.mainMenus}/${menuId}`);
  };

  const patchMainMenu = async (menuId: string, updateMenuField: PatchMenu) => {
    await api.patch(`${Endpoint.mainMenus}/${menuId}`, updateMenuField);
  };

  return {
    getCustomMenuTypes,
    getMainMenuIconTypes,
    getMainMenus,
    postMainMenu,
    deleteMainMenu,
    patchMainMenu,
  };
};

export default useMenu;
