import Input from 'components/common/input/Input';
import { ICON } from 'constants/icons';
import { ChangeEvent } from 'react';

type FormDataType = {
  username: string;
  password: string;
};

type LoginInputFieldProps = {
  formData: FormDataType;
  handleChangeLoginForm: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const LoginInputField = ({ formData, handleChangeLoginForm }: LoginInputFieldProps) => {
  return (
    <div className={`flex flex-col w-full gap-4`}>
      <Input
        autoComplete='username'
        onChange={handleChangeLoginForm}
        id='username'
        value={formData.username}
        type='text'
        placeholder='아이디'
        width='w-full'
        height='h-12'
        textStyle='h3'
        icon={<ICON.CiUser size={20} />}
      />

      <Input
        autoComplete='current-password'
        onChange={handleChangeLoginForm}
        id='password'
        value={formData.password}
        type='password'
        placeholder='비밀번호'
        width='w-full'
        height='h-12'
        textStyle='h3'
        icon={<ICON.CiLock size={20} />}
      />
    </div>
  );
};
export default LoginInputField;
