export const CONTENT_UPLOAD_GUIDE_INFO = '파일 첨부 시 아래 사항을 확인해주세요.';

export const CONTENT_UPLOAD_GUIDES = [
  '파일명은 영어, 숫자 조합만 가능합니다.',
  '영상 업로드는 파일 확장자 webm만 가능합니다.',
  '이미지 업로드는 파일 확장자 png, jpeg, jpg만 가능합니다.',
  '최적 사이즈는 세로 2160*3400, 가로 3840*1918 입니다.',
  '용량은 50MB 이하만 업로드 가능합니다.',
  '이미지는 화면사이즈 비율에 맞게 확대되어 제공됩니다.',
];
