import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { CustomPage, CustomBoardContent, PostCustomPage, UpdateCustomPage } from 'types/feature/custom-page/customPage';

const useCustomPage = () => {
  const { api } = useAxios();

  const getCustomPage = async (customPageId: string) => {
    const result = await api.get<ApiResponse<CustomPage>>(Endpoint.customPage.replace(':customPageId', customPageId));

    return result;
  };

  const getCustomBoard = async (customBoardId: string) => {
    const result = await api.get<ApiResponse<CustomBoardContent>>(
      Endpoint.customBoard.replace(':customBoardId', customBoardId),
    );
    return result;
  };

  const postCustomBoard = async (uploadCustomPage: PostCustomPage) => {
    await api.post(Endpoint.customBoardAdd, uploadCustomPage);
  };

  const deleteCustomBoard = async (customBoardId: string) => {
    await api.delete(Endpoint.customBoard.replace(':customBoardId', customBoardId));
  };

  const updateCustomBoard = async (customBoardId: string, updateCustomPage: UpdateCustomPage) => {
    await api.patch(Endpoint.customBoard.replace(':customBoardId', customBoardId), updateCustomPage);
  };

  return {
    getCustomPage,
    getCustomBoard,
    postCustomBoard,
    deleteCustomBoard,
    updateCustomBoard,
  };
};

export default useCustomPage;
