// router 설정, 메뉴 관리, navigate할 때 사용하는 enum
// 리스트 ex) /platforms
// 상세 + 수정 /platforms/:id
// 등록 /platforms/add
// 개별 등록

// 백엔드 api에 맞춰서 path
export enum RoutePath {
  internal = '/internal',
  // 내부적으로 사용하는 대시보드 페이지
  internalDashBoard = '/internal/dashboard',
  internalError = '/internal/error',

  // 로그인
  login = '/',

  // 워크스페이스
  workspaces = '/workspaces',
  workspaceAdd = '/workspaces/add',

  // 서비스
  content = '/:workspaceId/contents',
  contentAdd = '/:workspaceId/contents/add',
  contentDetail = '/:workspaceId/contents/:contentId',

  // 광고
  screensaver = '/:workspaceId/screensavers',
  pointScreensaver = '/:workspaceId/screensavers/:pointId',
  pointScreensaverAdd = '/:workspaceId/screensavers/:pointId/add',

  // 매장
  tenant = '/tenants',
  tenantManage = '/:workspaceId/tenants',

  // 카테고리
  categoryManage = '/categories/',
  facilityCategoryManage = '/:workspaceId/categories/facility',
  floorCategoryManage = '/:workspaceId/categories/floor',
  tenantCategoryManage = '/:workspaceId/categories/tenant',

  // 메뉴
  menuSetting = '/:workspaceId/menus',

  // 커스텀 페이지
  customPage = '/:workspaceId/custom-pages/:customPageId',
  customPageAdd = '/:workspaceId/custom-pages/:customPageId/add',
  customPageDetail = '/:workspaceId/custom-pages/contents/:customContentId',

  // 설정
  setting = '/:workspaceId/setting',
  basicSetting = '/:workspaceId/setting/basic',
  languageSetting = '/:workspaceId/setting/language',
  pointSetting = '/:workspaceId/setting/point',
  customFieldSetting = '/:workspaceId/setting/custom-field',
  themeSetting = '/:workspaceId/setting/theme',

  // 기기
  machine = '/:workspaceId/machines',
  machineAdd = '/:workspaceId/machines/register',
  machineDetail = '/:workspaceId/machines/:machineId',

  // 기기 상태
  monitoring = '/:workspaceId/monitoring',

  serverError = '/error/server',
  notFound = '/error/not-found',
}
