import Input from 'components/common/input/Input';
import AlertPopup from 'components/common/popup/AlertPopup';
import Title from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWorkspaceStore from 'stores/workspace';
import { RegisterMachine } from 'types/feature/menu/machine/machine';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  registeredMachine?: RegisterMachine;
  fetchWorkspaceAndMachine?: () => Promise<void>;
};

const DescriptionEditController = ({ registeredMachine, fetchWorkspaceAndMachine }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const { patchMachine } = useMachine();
  const { machineId } = useParams();
  const { workspaceMachineType } = useWorkspaceStore();
  const [description, setDescription] = useState('');
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleChangeMachinePosition = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDescription(value);
  };

  const updateDescription = async () => {
    try {
      if (machineId) {
        if (isEmpty(description)) {
          setOpenAlertPopup(true);
          setAlertMessage('입력된 값이 없습니다.');
          return;
        }

        await patchMachine(workspaceMachineType, machineId, {
          description,
        });

        setEditMode(false);
        if (fetchWorkspaceAndMachine) {
          await fetchWorkspaceAndMachine();
        }
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };

  return (
    <>
      <div className='flex flex-col gap-2.5 p-5 border-b'>
        <div className='flex justify-between h-6'>
          <Title text='상세위치' titleType='h4_bold' textColor='text-gray-77' />
          {editMode ? (
            <div className='flex gap-2.5'>
              <div className='h-6' onClick={updateDescription}>
                <img className='h-full' src={ICON.Success} alt='edit' />
              </div>
              <div className='h-6' onClick={() => setEditMode(false)}>
                <img className='h-full' src={ICON.Cancel} alt='edit' />
              </div>
            </div>
          ) : (
            <div className='h-6' onClick={() => setEditMode(!editMode)}>
              <img className='h-full' src={ICON.Pencil} alt='edit' />
            </div>
          )}
        </div>
        {editMode ? (
          <Input
            height='h-[30px]'
            width='w-[260px]'
            textStyle='h5'
            placeholder='상세위치를 입력해주세요'
            defaultValue={registeredMachine?.description}
            onChange={handleChangeMachinePosition}
          />
        ) : (
          <div className='px-2 h-[30px] flex items-center bg-bg-f9 w-full text-h5 text-gray-99'>
            {registeredMachine?.description}
          </div>
        )}
      </div>
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default DescriptionEditController;
