import { DisplayType } from 'types/common/display';
import { ScreenType } from 'types/common/screenType';
import { MachinePlaceType, MachineState } from 'types/feature/menu/machine/machine';

export type Select = {
  id: string;
  value: string;
};

export const SCREEN_TYPE = [
  { id: ScreenType.all, value: '전체' },
  { id: ScreenType.hori, value: '가로' },
  { id: ScreenType.verti, value: '세로' },
];

export const SCREEN_FILTER = [
  { id: ScreenType.hori, value: '가로' },
  { id: ScreenType.verti, value: '세로' },
];

export const DISPLAY_TYPE = [
  { id: DisplayType.always, value: '상시' },
  { id: DisplayType.duration, value: '기간' },
];

export const MACHINE_PLACE_TYPE = [
  { id: MachinePlaceType.CLIENT, value: '고객사' },
  { id: MachinePlaceType.CLIENT_OFFICE, value: '고객사 사무실' },
  { id: MachinePlaceType.DEVELOPER, value: '개발자' },
  { id: MachinePlaceType.ETC, value: '기타' },
];

export const MACHINE_STATE_TYPE = [
  { id: MachineState.RUNNING, value: '운영중' },
  { id: MachineState.PREPARED, value: '미배치' },
  { id: MachineState.EXPIRED, value: '폐기' },
];
