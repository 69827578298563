import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { ICON } from 'constants/icons';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { MouseEvent, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAccountStore from 'stores/account';
import { AccountMap } from 'types/common/account';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';
import { sliceDate } from 'utils/convert-date/convertDate';

type WorkspaceItemProps = {
  workspace: Workspace;
  handleDelete: (id: string) => void;
};

const WorkspaceItem = ({ workspace, handleDelete }: WorkspaceItemProps) => {
  const [showIcons, setShowIcons] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [mapName, setMapName] = useState('');

  const { navigateToReplacedPath } = useCustomNavigate();
  const navigate = useNavigate();

  const accountStore = useAccountStore();

  useLayoutEffect(() => {
    fetchMapName();
  }, [workspace, accountStore.account]);

  // 계정 정보로부터 지도 이름 가져옴
  const fetchMapName = async () => {
    try {
      if (accountStore.account) {
        const findMap = accountStore.account.maps.find((map: AccountMap) => map.id === workspace.map.id);

        if (findMap) {
          setMapName(findMap.name);
        }
      }
    } catch (error) {
      console.error('계정 정보를 불러올 수 없습니다.', error);
    }
  };

  const moveToEditPage = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigateToReplacedPath(RoutePath.basicSetting, workspace.id);
  };

  const moveToMainPage = () => {
    navigate(RoutePath.content);
  };

  const deleteItem = (e: MouseEvent<HTMLElement>) => {
    handleDelete(workspace.id);
    handleConfirmPopup(e);
  };

  const handleConfirmPopup = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpenConfirmPopup(!openConfirmPopup);
  };

  return (
    <>
      <div
        onFocus={() => setShowIcons(true)}
        onMouseOver={() => setShowIcons(true)}
        onMouseOut={() => setShowIcons(false)}
        onBlur={() => setShowIcons(false)}
        onClick={moveToMainPage}
        className={`w-full h-[200px] cursor-pointer rounded-xl flex flex-col justify-between items-start
         bg-white px-6 py-4 shadow border-2 border-white
         hover:bg-state-blue_bg hover:border-state-blue hover:border-2`}
      >
        <div className='flex flex-col w-full'>
          <div className='flex items-start justify-between w-full h-[26px]'>
            {/* 생성일 */}
            <div className='flex gap-2'>
              {/* <span className='text-xs font-semibold text-gray-77'>생성일</span> */}
              <span className='text-xs text-gray-99'>{sliceDate(workspace.createdDate)}</span>
            </div>

            {/* 아이콘 */}
            {showIcons && (
              <div className='flex gap-1'>
                {/* 설정 */}
                <button type='button' className={'border-0 opacity-40 hover:opacity-100'} onClick={moveToEditPage}>
                  <img src={ICON.Setting} alt='설정' className='h-5' />
                </button>

                {/* 삭제 */}
                <button type='button' className={'border-0 opacity-40 hover:opacity-100'} onClick={handleConfirmPopup}>
                  <img src={ICON.TRASH} alt='삭제' className='h-5' />
                </button>
              </div>
            )}
          </div>

          {/* 워크스페이스 이름 */}
          <div className='flex-1 w-full text-base font-semibold text-gray-44 h-[50px] line-clamp-2'>
            {workspace.name || '새로운 워크스페이스'}
          </div>
        </div>

        {/* 설명 */}
        <div className='flex flex-col gap-1'>
          {/* 지도 이름 */}
          <div className='flex gap-2'>
            <span className='text-xs font-semibold text-gray-77 shrink-0'>지도명</span>
            <span className='text-xs text-gray-99 line-clamp-1 max-h-[20px]'>{mapName}</span>
          </div>

          {/* desc */}
          {workspace.description && (
            <div className='flex gap-2'>
              <span className='text-xs font-semibold text-gray-77 shrink-0'>설명</span>
              <div className='w-full text-xs text-gray-99 line-clamp-2 max-h-[32px]'>{workspace.description}</div>
            </div>
          )}

          {/* <div className='flex gap-2'>
            <span className='text-xs font-semibold text-gray-77'>최종수정일</span>
            <span className='text-xs text-gray-99'>{sliceDate(workspace.updatedDate)}</span>
          </div> */}
        </div>
      </div>

      {openConfirmPopup && (
        <ConfirmPopup
          message='워크스페이스를 삭제하시겠습니까?'
          onClickOk={deleteItem}
          onClickCancel={handleConfirmPopup}
        />
      )}
    </>
  );
};
export default WorkspaceItem;
