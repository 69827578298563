import React from 'react';

const ImageUploadGuide = () => {
  return (
    <div className='bg-[#f9f9f9] py-4 px-5 '>
      <ul className='text-[#999]'>
        <li className='text-inherit text-[10px]'>파일명은 영어, 숫자, -, _ 조합만 가능합니다. </li>
        <li className='text-inherit text-[10px]'>이미지 파일은 png, jpeg, jpg만 가능합니다.</li>
        <li className='text-inherit text-[10px]'>
          사이즈는 UHD 기준 목록이미지 360*360 / 상세이미지 1240*960 를 권장합니다.
        </li>
        <li className='text-inherit text-[10px]'>
          기준 사이즈와 맞지 않을 경우 키오스크 화면에서 정상적으로 표시되지 않을 수 있습니다.
        </li>
      </ul>
    </div>
  );
};

export default ImageUploadGuide;
