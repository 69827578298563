import FormControl from 'components/common/form-control/FormControl';
import AlertPopup from 'components/common/popup/AlertPopup';
import { ICON } from 'constants/icons';
import { ZOOM_REGEX } from 'data/regex';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useWorkspaceStore from 'stores/workspace';
import { RegisterMachine } from 'types/feature/menu/machine/machine';
import { handle400Error } from 'utils/error/handle400Error';
import { changeZoom } from 'utils/map/mapControl';
import ValidateInput from '../../../input/ValidateInput';

type Props = {
  registeredMachine?: RegisterMachine;
  fetchWorkspaceAndMachine?: () => Promise<void>;
};

const ZoomEditController = ({ registeredMachine, fetchWorkspaceAndMachine }: Props) => {
  // state
  const [zoom, setZoom] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // hook
  const { patchMachine } = useMachine();
  const { machineId } = useParams();
  const { workspaceMachineType } = useWorkspaceStore();
  const { workspace } = useWorkspaceStore();
  const { state } = useLocation();

  const handleChangeZoom = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    changeZoom(Number(value));
    setZoom(value);
  };

  const updateZoom = async () => {
    try {
      if (isEmpty(zoom)) {
        setOpenAlertPopup(true);
        setAlertMessage('입력된 값이 없습니다.');
        return;
      }
      if (machineId) {
        await patchMachine(workspaceMachineType, machineId, {
          mapPosition: {
            zoom,
          },
        });

        setEditMode(false);
        if (fetchWorkspaceAndMachine) {
          await fetchWorkspaceAndMachine();
        }
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };
  return (
    <div className='flex flex-col gap-2.5 px-5 p-3'>
      <div className='flex justify-between h-6'>
        <FormControl
          name='Zoom'
          labelSize={50}
          labelTextSize='h4_bold'
          control={
            <div className='flex items-center px-3 bg-gray-f9 h-6 rounded-full text-[11px]'>
              {/* todo 현재 포인트의 min max */}
              MIN {workspace?.points[0].mapMinZoom} MAX {workspace?.points[0].mapMaxZoom}
            </div>
          }
        />

        {editMode ? (
          <div className='flex gap-2.5'>
            <div className='h-6' onClick={updateZoom}>
              <img className='h-full' src={ICON.Success} alt='edit' />
            </div>
            <div className='h-6' onClick={() => setEditMode(false)}>
              <img className='h-full' src={ICON.Cancel} alt='edit' />
            </div>
          </div>
        ) : (
          <div className='h-6' onClick={() => setEditMode(!editMode)}>
            <img className='h-full' src={ICON.Pencil} alt='edit' />
          </div>
        )}
      </div>
      <FormControl
        name='Zoom'
        labelTextSize='h5'
        control={
          editMode ? (
            <ValidateInput
              type='text'
              height='h-[25px]'
              width='w-[100px]'
              onChange={handleChangeZoom}
              defaultValue={registeredMachine?.mapPosition.zoom}
              regexCheck={ZOOM_REGEX}
              regexGuide={`${state?.point.mapMinZoom}과 ${state?.point.mapMaxZoom}사이의 수만 가능합니다.`}
            />
          ) : (
            <div className='flex items-center justify-end bg-bg-f9 w-[100px] h-[30px] px-2 text-h5 text-gray-99 '>
              {registeredMachine?.mapPosition.zoom}
            </div>
          )
        }
      />
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </div>
  );
};

export default ZoomEditController;
