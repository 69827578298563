import { useMatches } from 'react-router-dom';

const useBreadcrumb = () => {
  const matches = useMatches();
  const crumbs = (matches.filter(match => match.handle).map(match => match.handle) as string[]) ?? [];
  const title = crumbs[crumbs.length - 1] as string;

  return { crumbs, title };
};
export default useBreadcrumb;
