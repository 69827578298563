import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import Calendar from 'components/common/calendar/Calendar';
import ModalContainer from 'components/common/modal/ModalContainer';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Radio from 'components/common/radio/Radio';
import Title from 'components/common/title/Title';
import { DISPLAY_TYPE } from 'data/filter';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { useState } from 'react';
import useWaitingListStore from 'stores/waitingList';
import { FLEX_CENTER } from 'styles/flex';
import { DisplayType } from 'types/common/display';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { convertDateToString } from 'utils/convert-date/convertDate';

type DatePopupProps = {
  closePopup: () => void;
  screensaver: GetScreensaver;
  pointId: string;
};

const DisplayEditPopup = ({ closePopup, screensaver, pointId }: DatePopupProps) => {
  // ! 상시노출일 경우에는 endDate를 startDate로 넣어줌
  const initialEndDate =
    screensaver.displayType === DisplayType.always ? new Date(screensaver.startDate) : new Date(screensaver.endDate);

  const [type, setType] = useState<DisplayType>(screensaver.displayType as DisplayType);
  const [startDate, setStartDate] = useState<Date | null>(new Date(screensaver.startDate));
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { updateScreensaver, refetchScreensaver } = useScreensaver();
  const { waitingList, setWaitingList } = useWaitingListStore();

  const handleChangeType = (displayType: DisplayType) => {
    setType(displayType);
    setStartDate(new Date(screensaver.startDate));
  };

  // * 수정하기
  const handleUpdate = async () => {
    try {
      if (!screensaver) return;

      const updateData = {
        displayType: type,
        startDate: convertDateToString(startDate),
        endDate: endDate ? convertDateToString(endDate) : convertDateToString(startDate),
      };

      await updateScreensaver(screensaver.id, updateData);
      await refetchScreensaver(pointId);

      closePopup();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          setAlertMessage('스케줄 수정에 실패하였습니다.');
          setOpenAlert(true);
        }
      }
    }
  };

  const updateSelectedRow = (start: Date | null, end: Date | null) => {
    if (!waitingList) return;

    const selected = waitingList.filter(item => item.id === screensaver.id)[0];
    const selectedIdx = waitingList.findIndex(item => item.id === screensaver.id);

    const newSelected = {
      ...selected,
      startDate: convertDateToString(start),
      endDate: convertDateToString(end),
    };

    if (!newSelected) return;

    waitingList.splice(selectedIdx, 1, newSelected);
    setWaitingList(waitingList);
  };

  // * 날짜 설정
  const handleDate = (date: Date | null) => {
    try {
      if (!date) {
        setAlertMessage('날짜를 입력해주세요.');
        setOpenAlert(true);
        return;
      }

      setStartDate(date);
      updateSelectedRow(date, date);
    } catch (e) {
      if (e instanceof Error) {
        setAlertMessage('날짜 설정을 다시 해주세요.');
        setOpenAlert(true);
      }
    }
  };

  // * 기간 설정
  const handleRange = (dates: [Date | null, Date | null]) => {
    try {
      const [start, end] = dates;

      if (!start) {
        setOpenAlert(true);
        setAlertMessage('날짜를 입력해주세요.');
        return;
      }

      setStartDate(start);
      setEndDate(end);
      updateSelectedRow(start, end);
    } catch (e) {
      if (e instanceof Error) {
        setAlertMessage('기간 설정을 다시 해주세요.');
        setOpenAlert(true);
      }
    }
  };

  const handleConfirmPopup = () => {
    const valid = validDisplay();

    if (valid) {
      setOpenConfirmPopup(!openConfirmPopup);
    }
  };

  const validDisplay = () => {
    // 기간노출인데 종료 날짜가 없을 경우
    if (type === DisplayType.duration && !endDate) {
      setAlertMessage('종료날짜가 필요합니다.');
      setOpenAlert(true);

      return false;
    }

    return true;
  };

  return (
    <>
      <ModalContainer>
        <div className={`bg-white w-[520px] flex flex-col justify-between`}>
          <div className={`w-full ${FLEX_CENTER.row} h-24 px-4 border-b`}>
            <Title titleType='h3_bold' text='노출 기간 수정' />
          </div>

          <div>
            <div className={`w-full flex items-center gap-4 h-14 px-10`}>
              <span className='flex w-28'>
                <Title titleType='h4_bold' text='타이틀' />
              </span>
              <div className='text-sm font-medium break-all line-clamp-1'>{screensaver.content.title}</div>
            </div>

            <div className={`w-full flex items-center gap-4 h-14 px-10`}>
              <span className='flex w-28'>
                <Title titleType='h4_bold' text='노출타입' />
              </span>
              <span className='flex gap-4 text-sm font-medium'>
                {DISPLAY_TYPE.map(({ id, value }) => (
                  <div key={id} className='flex gap-2'>
                    <Radio value={id} checked={id === type} label={value} onChange={handleChangeType.bind(this, id)} />
                  </div>
                ))}
              </span>
            </div>

            <div className={`w-full flex items-center gap-4 h-14 px-10`}>
              <span className='flex w-28'>
                <Title titleType='h4_bold' text={type === DisplayType.duration ? '노출기간' : '노출시작일'} />
              </span>
              <span>
                <Calendar
                  startDate={startDate}
                  endDate={endDate}
                  handleDate={handleDate}
                  handleRange={handleRange}
                  displayType={type}
                  style='h-9 w-full'
                />
              </span>
            </div>
          </div>

          <div className={`${FLEX_CENTER.row} gap-2 h-24`}>
            <Button text='취소' onClick={closePopup} color={ButtonColor.secondary} />
            <Button text='확인' onClick={handleConfirmPopup} color={ButtonColor.primary} />
          </div>
        </div>
      </ModalContainer>

      {openConfirmPopup && (
        <ConfirmPopup
          message='스크린세이버 스케줄을 수정하시겠습니까?'
          onClickOk={handleUpdate}
          onClickCancel={handleConfirmPopup}
        />
      )}

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};
export default DisplayEditPopup;
