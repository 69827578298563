import Title from 'components/common/title/Title';
import { TextType } from 'types/common/text';
import { Tenant } from 'types/feature/tenant/getTenant';

type MapDetailsProps = {
  tenant: Tenant;
};

const MapDetails = ({ tenant }: MapDetailsProps) => {
  const mapData = [
    {
      key: '타이틀',
      value: tenant.content.name.ko,
    },
    {
      key: '위치',
      value: tenant.content.floor.name.ko,
    },
    {
      key: 'Code',
      value: tenant.contentId,
    },
  ];

  return (
    <div className={`flex flex-col gap-3 w-full h-full cursor-default py-6 px-10`}>
      <Title text='지도 정보' titleType='h3_bold' />

      <div className='flex flex-col'>
        {mapData.map(item => (
          <div key={item.key} className={`flex items-center h-9`}>
            <div
              className={`w-24 h-full flex items-center  text-[13px] font-bold whitespace-nowrap min-w-max text-gray-77`}
            >
              {item.key}
            </div>
            <div className={`${TextType.body2} flex-2 grow line-clamp-1 text-gray-77`}>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MapDetails;
