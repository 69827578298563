import { AxiosError } from 'axios';

export const handle400Error = (error: unknown, callback: () => void) => {
  if (error instanceof AxiosError) {
    if (error.response?.status) {
      if (error.response.status >= 400 && error.response.status < 500) {
        callback();
      }
    }
  }
};
