import dayjs from 'dayjs';
import 'dayjs/locale/ko';

export const sliceDate = (date: string) => {
  return date.slice(0, 10);
};

// 휴점일 등록에 필요한 날짜 포맷으로 변경
export const closedDaysToFormat = (closedDays: string[]) => {
  return closedDays.map(day => dayjs(day).format('MM.DD'));
};

export const closedDayToFormat = (closedDay: string | Date) => {
  return dayjs(closedDay).format('MM.DD');
};

export const convertCurrentDay = (date: string) => {
  if (date) {
    const currentYear = dayjs().get('year').toString();
    const currentDate = currentYear.concat('-', date.replace('.', '-'));
    const convertedDate = dayjs(currentDate).locale('ko').format('MM/DD(dd)');
    return convertedDate;
  }
};

// TODO: 테스트 코드 작성 필요
// Date 타입의 객체를 넣으면 '2023-01-01' 형식의 string으로 반환
export function convertDateToString(date: Date | null): string {
  return dayjs(date).format('YYYY-MM-DD');
}
