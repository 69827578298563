import { IMAGES } from 'constants/images';
import { TextType } from 'types/common/text';
import { ColorSet } from 'types/feature/setting/theme/theme.type';
import ThemePreviewFooter from '../common/footer/ThemePreviewFooter';
import ThemePreviewMenu from '../common/menu/ThemePreviewMenu';

type Props = {
  usedColorSet: ColorSet | undefined;
};

const ThemeTenantPreview = ({ usedColorSet }: Props) => {
  const mainColor = usedColorSet?.colors.find(color => color.type === 'MAIN')?.color ?? '';
  const subColor = usedColorSet?.colors.find(color => color.type === 'SUB')?.color ?? '';

  return (
    <div className='w-[280px] h-full'>
      <div className='relative flex items-center justify-center'>
        <img src={IMAGES.PREVIEW_TENANT_LIST} alt='미리보기 매장 리스트' />

        <div className='absolute flex flex-col self-center'>
          <img src={IMAGES.PREVIEW_TENANT_POPUP} alt='미리보기 매장 상세 팝업' />

          <div className='absolute bottom-0 flex w-full gap-1 p-1'>
            <div
              style={{ background: subColor }}
              className={`${TextType.h5_bold} h-7 flex-1 justify-center flex items-center text-white`}
            >
              위치확인
            </div>
            <div
              style={{ background: mainColor }}
              className={`${TextType.h5_bold} h-7 flex-1 justify-center flex items-center text-white`}
            >
              길찾기
            </div>
          </div>
        </div>
      </div>

      <ThemePreviewMenu activeMenu='tenant' usedColorSet={usedColorSet} />
      <ThemePreviewFooter />
    </div>
  );
};
export default ThemeTenantPreview;
