import { FileUploadType } from 'types/common/file/fileUpload';

export const returnFileAccept = (fileType: FileUploadType) => {
  if (fileType === FileUploadType.CONTENT_IMAGE) {
    return {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    };
  }

  if (fileType === FileUploadType.CONTENT_VIDEO) {
    return {
      'video/mp4': ['.mp4'],
    };
  }

  if (fileType === FileUploadType.LOGO_IMAGE) {
    return {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    };
  }

  return {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'video/mp4': ['.mp4'],
  };
};
