import React from 'react';

type Props = {
  length: number | undefined;
  limit: number;
};

const LimitLength = ({ length, limit }: Props) => {
  return (
    <div className='text-xs tracking-wider text-gray-99'>
      {length}/{limit}
    </div>
  );
};
export default LimitLength;
