import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import { THEME_GUIDES } from 'data/guide/setting';
import useTheme from 'hooks/feature/theme/useTheme';
import { Fragment } from 'react';
import ColorBox from './color/ColorBox';
import ThemePreviews from './preview/ThemePreviews';

const ThemeSettingContainer = () => {
  const {
    usedColorSet,
    themes,
    activateColorSet,
    openAlert,
    closeAlert,
    handleOpenConfirm,
    openConfirm,
    closeConfirm,
    alertMessage,
    handleClickTheme,
    disableSave,
  } = useTheme();

  return (
    <div className='flex flex-col gap-5 w-[1000px]'>
      <Title text='테마' titleType='h2' />

      <GuideDescription guides={THEME_GUIDES} width='w-[766px]' />

      <div className='flex flex-col items-start gap-6'>
        <div className='flex w-full gap-5'>
          {themes?.colorSets.map(colorSet => (
            <Fragment key={colorSet.id}>
              <ColorBox colorSet={colorSet} handleClickTheme={handleClickTheme} usedColorSet={usedColorSet} />
            </Fragment>
          ))}
        </div>

        <div className='flex flex-col items-end gap-5'>
          <ThemePreviews usedColorSet={usedColorSet} />

          <Button
            disabled={disableSave || !themes}
            onClick={handleOpenConfirm}
            color={ButtonColor.point}
            size={120}
            text='저장'
          />
        </div>
      </div>

      {openAlert && <AlertPopup message={alertMessage} onClick={closeAlert} />}

      {openConfirm && (
        <ConfirmPopup message='컬러셋을 변경하시겠습니까?' onClickOk={activateColorSet} onClickCancel={closeConfirm} />
      )}
    </div>
  );
};
export default ThemeSettingContainer;
