import { FileUploadType } from 'types/common/file/fileUpload';

// 파일 업로드 타입별 megabyte 반환
export const returnFileMaxSize = (fileType: FileUploadType): number => {
  // 콘텐츠 이미지, 테넌트 이미지
  if (fileType === FileUploadType.CONTENT_IMAGE || fileType === FileUploadType.TENANT_IMAGE) {
    return 10;
  }

  // 콘텐츠 동영상
  if (fileType === FileUploadType.CONTENT_VIDEO) {
    return 50;
  }

  // 사용자 게시판 콘텐츠 파일
  if (fileType === FileUploadType.CUSTOM_BOARD_CONTENT_IMAGE) {
    return 5;
  }

  // 사용자 게시판 이미지, 로고 이미지
  if (fileType === FileUploadType.CUSTOM_BOARD_IMAGE || fileType === FileUploadType.LOGO_IMAGE) {
    return 2;
  }

  return 0;
};
