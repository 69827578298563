import { IMAGES } from 'constants/images';

type Props = {
  deleteFile: () => void;
};

const FileDeleteButton = ({ deleteFile }: Props) => {
  return (
    <div onClick={deleteFile} className='cursor-pointer'>
      <img src={IMAGES.TRASH} alt='delete button' />
    </div>
  );
};
export default FileDeleteButton;
