import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Mobile } from 'types/feature/workspace/workspace';

const useMobile = () => {
  const { api } = useAxios();

  const patchMobile = async (mobileId: string, mobile: Mobile) => {
    const result = await api.patch<ApiResponse<Mobile>>(Endpoint.mobiles.replace(':mobileId', mobileId), mobile);
    return result;
  };

  return {
    patchMobile,
  };
};
export default useMobile;
