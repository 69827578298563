/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-useless-fragment */
import { ButtonColor, ButtonProps, ButtonSizeConstants, ButtonTransition } from './Button.type';

const Button = ({
  text,
  width,
  height,
  onClick,
  size = 'default',
  disabled,
  color,
  rounded,
  textStyle,
  type = 'button',
  children,
  transition = ButtonTransition.none,
}: ButtonProps) => {
  const returnDisableColor = () => {
    switch (color) {
      case ButtonColor.primary:
        return ButtonColor.primary_disable;

      case ButtonColor.secondary:
        return ButtonColor.secondary_disable;

      case ButtonColor.point:
        return ButtonColor.point_disable;

      case ButtonColor.red:
        return ButtonColor.red;
      default:
        return ButtonColor.primary_disable;
    }
  };

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${width} ${height} ${ButtonSizeConstants[size]} ${rounded} ${
        disabled ? `pointer-events-none cursor-not-allowed ${returnDisableColor()}` : color
      } 
      ${transition} ${textStyle}
      `}
    >
      <>
        {text && text}
        {children && children}
      </>
    </button>
  );
};
export default Button;
