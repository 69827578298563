import { ICON } from 'constants/icons';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';
import WorkspaceItem from './item/WorkspaceItem';

const WorkspaceList = () => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);

  const navigate = useNavigate();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { getWorkspaces, deleteWorkspace } = useWorkspace();

  const fetchWorkspaces = async () => {
    const workspaceList = await getWorkspaces();
    setWorkspaces(workspaceList);
  };

  const handleClickItem = (id: string) => {
    navigateToReplacedPath(RoutePath.content, id);
  };

  const handleDelete = async (id: string) => {
    await deleteWorkspace(id);
    await fetchWorkspaces();
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  return (
    <div className={`bg-bg-f9 flex flex-col items-center pb-40 gap-8 w-[1400px] min-h-[860px] pt-10`}>
      {/* 워크스페이스 목록 */}
      <div className='grid w-full grid-cols-4 gap-6'>
        {workspaces?.map((workspace, idx) => (
          <div key={idx} onClick={handleClickItem.bind(this, workspace.id)}>
            <WorkspaceItem workspace={workspace} handleDelete={handleDelete} />
          </div>
        ))}

        <div
          onClick={() => navigate(RoutePath.workspaceAdd)}
          className='w[300px] h-[200px] cursor-pointer rounded-xl flex flex-col justify-center items-center
            bg-state-blue_bg p-8 shadow border-2 border-state-blue_bg 
            hover:bg-state-blue_bg hover:border-state-blue hover:border-2'
        >
          <div className='flex flex-col items-center gap-3 opacity-60'>
            <ICON.PiPlusCircleThin size={40} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WorkspaceList;
