import { create } from 'zustand';

type LoadingStoreProps = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const useLoadingStore = create<LoadingStoreProps>((set, get) => ({
  loading: true,
  setLoading: (loading: boolean) => set(() => ({ loading })),
}));

export default useLoadingStore;
