import React from 'react';

const Off = () => {
  return (
    <div className='w-[14px] h-[20px] flex items-center'>
      <div className='w-[14px] h-[14px]  bg-[#555B73] rounded-full border border-[#555B73]' />
    </div>
  );
};
export default Off;
