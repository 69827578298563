import useBreadcrumb from 'hooks/common/useBreadcrumb';
import Title from '../title/Title';
import Breadcrumb from './breadcrumb/Breadcrumb';

const TitleContainer = () => {
  const { title } = useBreadcrumb();

  return (
    <div className='flex items-center justify-between w-full h-[20px] mt-10'>
      <Title text={title} titleType='h2' />
      <Breadcrumb />
    </div>
  );
};
export default TitleContainer;
