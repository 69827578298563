import Input from 'components/common/input/Input';
import Label from 'components/common/label/Label';
import useLanguage from 'hooks/feature/language/useLanguage';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Language, LanguageName, MultiLangs } from 'types/feature/language/language';

type Props = {
  handleChangeCategoryName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: MultiLangs;
};

const CategoryMultiLangInputs = ({ handleChangeCategoryName, name }: Props) => {
  const { workspaceId } = useParams();
  const { getLanguages } = useLanguage();
  const [languages, setLanguages] = useState<Language[]>([]);

  const getLanguageList = async () => {
    if (workspaceId) {
      const result = await getLanguages(workspaceId);
      const displayLanguages: Language[] = result.filter(lang => lang.used);

      setLanguages(displayLanguages);
    }
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  return (
    <div className='flex flex-col w-full gap-2'>
      {languages.map(language => (
        <div key={language.id} className='flex items-center gap-2'>
          <Label labelSize='md' labelTextSize='h5_bold' name={LanguageName[language.code]} />
          <Input
            onChange={handleChangeCategoryName}
            width='w-full'
            defaultValue={name && name[language.code.toLowerCase()]}
            placeholder={`${LanguageName[language.code]} 카테고리 이름을 입력해주세요.`}
            id={language.code.toLowerCase()}
          />
        </div>
      ))}
    </div>
  );
};
export default CategoryMultiLangInputs;
