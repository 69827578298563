import { FontType, TextType } from 'types/common/text';
import RequiredMark from './required/RequiredMark';

type FormLabelProps = {
  name: string;
  required?: boolean;
  flexStyle?: string;
  labelSize?: keyof typeof LabelSizeConstant;
  labelTextSize?: FontType;
};

export const LabelSizeConstant = {
  none: '',
  50: 'w-[50px]',
  60: 'w-[60px]',
  sm: 'w-[80px]',
  md: 'w-[100px]',
  md2: 'w-[160px]',
  md3: 'w-[190px]',
  lg: 'w-[200px]',
};

const Label = ({ name, required, flexStyle = '', labelTextSize = 'h3', labelSize = 'none' }: FormLabelProps) => {
  return (
    <div className={`${LabelSizeConstant[labelSize]} cursor-default flex gap-1 ${flexStyle}`}>
      <span className={`whitespace-nowrap text-gray-77 font-bold ${TextType[labelTextSize]}`}>{name}</span>
      {required && <RequiredMark />}
    </div>
  );
};

export default Label;
