import useBreadcrumb from 'hooks/common/useBreadcrumb';
import { Fragment } from 'react';

const Breadcrumb = () => {
  const { crumbs } = useBreadcrumb();

  return (
    <div className='flex items-center gap-2.5'>
      {/* home 아이콘 */}
      <svg xmlns='http://www.w3.org/2000/svg' width='19' height='18' viewBox='0 0 19 18' fill='none'>
        <path
          d='M6.62402 14.4545H4.12402V7.83333L9.12402 4L14.124 7.83333V14.4545H11.624'
          stroke='#777777'
          strokeLinecap='square'
        />
        <path d='M10.715 12.9619V10.3636H7.5332V12.9619' stroke='#777777' />
      </svg>

      {crumbs?.map(crumb => (
        <Fragment key={crumb}>
          {/* right 아이콘 */}
          <svg xmlns='http://www.w3.org/2000/svg' width='5' height='8' viewBox='0 0 5 8' fill='none'>
            <path
              d='M2.58125 3.99991L0.124023 1.54276L1.32963 0.337158L4.99974 3.99991L1.32974 7.66273L0.12414 6.45713L2.58125 3.99991Z'
              fill='#777777'
            />
          </svg>

          <span className={`text-gray-77 text-[12px] font-normal leading-[14px]`} key={crumb}>
            {crumb}
          </span>
        </Fragment>
      ))}
    </div>
  );
};
export default Breadcrumb;
