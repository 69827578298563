import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import { ZOOM_SETTING_GUIDES } from 'data/guide/setting';
import useMobile from 'hooks/feature/mobile/useMobile';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TextType } from 'types/common/text';
import { Mobile, Point } from 'types/feature/workspace/workspace';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  mobile: Mobile;
  setPoint: Dispatch<SetStateAction<Point>>;
  point: Point;
  fetchPoint: (pointId: string) => Promise<void>;
};

const MobileSetting = ({ mobile, point, fetchPoint }: Props) => {
  const [mobileForm, setMobileForm] = useState<Mobile>(mobile);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { patchMobile } = useMobile();

  // 입력값이 숫자인지 검증
  const validNumber = (value: any) => {
    if (typeof Number(value) !== 'number') {
      return false;
    }

    return true;
  };

  // 지도 zoom 유효성 검사
  const validZoom = (zoom: string, min: string, max: string) => {
    const minZoom = Number(min);
    const maxZoom = Number(max);
    const defaultZoom = Number(zoom);

    // mapMinZoom <= zoom <= mapMaxZoom
    const zoomValid = minZoom <= defaultZoom && defaultZoom <= maxZoom;

    if (!zoomValid) {
      setOpenAlert(true);
      setAlertMessage(`지도 zoom 기본값은 최소값과\n최대값 사이의 숫자만 가능합니다.`);
      setOpenConfirm(false);

      return false;
    }

    return true;
  };

  // machinePositionX, machinePositionY, mapMinZoom, mapMaxZoom
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }

    setMobileForm((prev: Mobile) => ({ ...prev, [name]: value }));
  };

  // mapPosition (rotation, centerPositionX, centerPositionY, zoom)
  const handleChangeMapPosition = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }

    setMobileForm((prev: Mobile) => ({
      ...prev,
      mapPosition: {
        ...prev.mapPosition,
        [name]: value,
      },
    }));
  };

  // 저장(수정)
  const onClickSave = async () => {
    try {
      // 줌값 유효성 처리
      if (!validZoom(mobileForm.mapPosition?.zoom || '0', mobileForm.mapMinZoom || '0', mobileForm.mapMaxZoom || '0')) {
        return;
      }

      await patchMobile(mobileForm.id, { ...mobileForm });
      await fetchPoint(mobileForm.pointId);

      setOpenConfirm(false);
      setOpenAlert(true);
      setAlertMessage('모바일 설정값을 저장하였습니다.');
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlert(true);
        setAlertMessage('모바일 설정값을 수정할 수 없습니다.');
      });
    }
  };

  useEffect(() => {
    setMobileForm(point.mobile);
  }, [point]);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-2.5'>
        <div className='flex items-center justify-start h-9'>
          <Title text='모바일 지도 설정' titleType='h3_bold' />
        </div>

        <GuideDescription guides={ZOOM_SETTING_GUIDES} width='w-[500px]' />
      </div>

      <div className='flex flex-col gap-5'>
        <FlexFormControl
          name='기기 위치 좌표'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>X</div>

                <Input
                  width='w-[90px]'
                  name='machinePositionX'
                  placeholder='1000.123'
                  onChange={handleChange}
                  type='number'
                  value={mobileForm.machinePositionX}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>Y</div>

                <Input
                  width='w-[90px]'
                  name='machinePositionY'
                  placeholder='1000.123'
                  onChange={handleChange}
                  type='number'
                  value={mobileForm.machinePositionY}
                />
              </div>
            </div>
          }
        />
        {/* 
      <FlexFormControl
        name='지도 중심 좌표'
        control={
          <div className='flex gap-10'>
            <FlexFormControl
              name='x 좌표'
              labelSize='md'
              control={
                <Input
                  width='w-24'
                  name='centerPositionX'
                  placeholder='1000.123'
                  onChange={handleChangeMapPosition}
                  type='number'
                  value={mobileForm.mapPosition?.centerPositionX}
                />
              }
            />

            <FlexFormControl
              name='y 좌표'
              labelSize='md'
              control={
                <Input
                  name='centerPositionY'
                  width='w-24'
                  placeholder='1000.123'
                  onChange={handleChangeMapPosition}
                  type='number'
                  value={mobileForm.mapPosition?.centerPositionY}
                />
              }
            />
          </div>
        }
      /> */}

        <FlexFormControl
          name='방향각'
          control={
            <div className='pl-[70px]'>
              <Input
                width='w-[90px]'
                name='rotation'
                placeholder='rotation'
                onChange={handleChangeMapPosition}
                type='number'
                value={mobileForm.mapPosition?.rotation}
              />
            </div>
          }
        />

        <FlexFormControl
          name='줌 레벨'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최소값</div>

                <Input
                  type='number'
                  width='w-[90px]'
                  name='mapMinZoom'
                  placeholder='0~24'
                  onChange={handleChange}
                  min='0'
                  max='24'
                  value={mobileForm.mapMinZoom}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>기본값</div>
                <Input
                  type='number'
                  width='w-[90px]'
                  name='zoom'
                  placeholder='0~24'
                  onChange={handleChangeMapPosition}
                  min='0'
                  max='24'
                  value={mobileForm.mapPosition?.zoom}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최대값</div>
                <Input
                  type='number'
                  width='w-[90px]'
                  placeholder='0~24'
                  name='mapMaxZoom'
                  onChange={handleChange}
                  min='0'
                  max='24'
                  value={mobileForm.mapMaxZoom}
                />
              </div>
            </div>
          }
        />

        <div>
          <div className='flex pt-5 pb-10'>
            <Border />
          </div>

          <div className={`flex justify-end`}>
            <Button text='저장' type='button' color={ButtonColor.primary} onClick={() => setOpenConfirm(true)} />
          </div>
        </div>
      </div>

      {openConfirm && (
        <ConfirmPopup
          message='모바일 설정을 저장하시겠습니까?'
          onClickOk={onClickSave}
          onClickCancel={() => {
            setOpenConfirm(false);
            setOpenAlert(false);
          }}
        />
      )}

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </div>
  );
};
export default MobileSetting;
