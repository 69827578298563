import { ICON } from 'constants/icons';
import React from 'react';

type FoldIconProps = {
  onClick: () => void;
};

const FoldIcon = ({ onClick }: FoldIconProps) => {
  return (
    <div
      onClick={onClick}
      className={`absolute top-10 -left-4 flex items-center justify-center
           w-10 h-10 font-bold text-gray-700
          transition-all ease-in-out delay-75
          bg-white border hover:border-gray-99 rounded-full shadow-xl cursor-pointer
           hover:bg-badge-positive-bg hover:text-white z-30`}
    >
      <ICON.MdChevronRight size={22} />
    </div>
  );
};
export default FoldIcon;
