import { MutableRefObject } from 'react';
import { create } from 'zustand';

type Props = {
  // toggle
  isToastOpen: boolean;
  setIsToastOpen: (isToastOpen: boolean) => void;

  // 팝업 메시지
  toastMessage: string;
  setToastPopupMessage: (toastMessage: string) => void;

  // 타이머
  toastTimerRef: MutableRefObject<NodeJS.Timer | null> | null;
  setToastTimerRef: (toastTimerRef: MutableRefObject<NodeJS.Timer | null>) => void;
  closeToastAfterSeconds: (callback: () => void) => void;
  clearToastTimer: () => void;
};

/**
 * 전역으로 사용하는 토스트 팝업 관련 store
 */
const useToastStore = create<Props>((set, get) => ({
  // toggle
  isToastOpen: false,
  setIsToastOpen(isToastOpen: boolean) {
    set(() => ({
      isToastOpen,
    }));
  },

  // 팝업 메시지
  toastMessage: '',
  setToastPopupMessage(toastMessage: string) {
    set({
      toastMessage,
    });
  },

  // 타이머
  toastTimerRef: null,
  setToastTimerRef(toastTimerRef: MutableRefObject<NodeJS.Timer | null>) {
    set({
      toastTimerRef,
    });
  },
  closeToastAfterSeconds(callback: () => void) {
    if (this.toastTimerRef) {
      this.toastTimerRef.current = setTimeout(() => {
        callback();
        this.clearToastTimer();
      }, 1000);
    }
  },
  clearToastTimer() {
    if (this.toastTimerRef && this.toastTimerRef.current) {
      clearTimeout(this.toastTimerRef.current);
    }
  },
}));

export default useToastStore;
