import Badge from 'components/common/badge/Badge';
import ThumbnailTableRowCell from 'components/common/thumbnail/ThumbnailTableRowCell';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Display, DisplayStatus, DisplayType } from 'types/common/display';
import { FILE_TYPES } from 'types/common/file/file';
import { SCREEN_TYPE_VALUES } from 'types/common/screenType';
import { TextType } from 'types/common/text';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import ListActionButtons from './buttons/actions/ListActionButtons';
import DraggableButton from './buttons/drag/DraggableButton';

type ScreensaverListTableRowProps = {
  provided: DraggableProvided;
  screensaver: GetScreensaver;
  pointId: string;
};

const ScreensaverListTableRow = ({ provided, screensaver, pointId }: ScreensaverListTableRowProps) => {
  return (
    <tr
      {...provided.draggableProps}
      ref={provided.innerRef}
      key={screensaver.sortOrder}
      className='hover:bg-slate-50 h-[48px]'
    >
      <td {...provided.dragHandleProps}>
        <DraggableButton />
      </td>
      <td className='whitespace-nowrap'>{SCREEN_TYPE_VALUES[screensaver.content.screenType]}</td>
      <td className='whitespace-nowrap'>{FILE_TYPES[screensaver.content.fileType]}</td>
      <td>
        <ThumbnailTableRowCell
          title={screensaver.content.title}
          files={screensaver.content.file}
          fileType={screensaver.content.fileType}
          titleStyle={TextType.h4}
        />
      </td>
      <td className='whitespace-nowrap'>{Display[screensaver.displayType]}</td>
      <td>{screensaver.startDate}</td>
      {/* 기간노출일 경우에만 노출종료일 보여줌 */}
      <td>{screensaver.displayType === DisplayType.duration ? screensaver.endDate : '-'}</td>
      <td>
        <Badge name={DisplayStatus[screensaver.displayStatus]} badgeType={screensaver.displayStatus} />
      </td>
      <td>
        <ListActionButtons screensaver={screensaver} pointId={pointId} />
      </td>
    </tr>
  );
};
export default ScreensaverListTableRow;
