import { Floor } from 'types/feature/floor/floor';

declare global {
  interface Window {
    dabeeo: any;
  }
}

class MapDraw {
  static get mapContainer() {
    return this._mapContainer;
  }

  private static _mapContainer: HTMLDivElement;

  static dabeeoMaps = new window.dabeeo.Maps() as any;

  static map: any = null;

  static mapData: any = null;

  static defaultFloor: Floor | null = null;

  private mapOption = {
    camera: '2d',
    language: 'ko',
    showPoi: true,
    spriteEnable: true,
    spriteKeepRotation: true,
    showWaterMarker: false,
    framerate: 30,
    enableMouseMoveEvent: false,

    enablePoiCollisionTest: false,
    dragDistance: 50,

    backgroundImage: {
      visible: true,
    },
  };

  async showMap(mapContainer: HTMLDivElement, options: any) {
    MapDraw._mapContainer = mapContainer;

    const mapOptions = {
      ...this.mapOption,
      ...options,
    };

    MapDraw.map = await MapDraw.dabeeoMaps.showMap(mapContainer, mapOptions, MapDraw.mapData);

    MapDraw.map?.control.setOption({
      mouseOption: {
        enableZoom: false,
        enablePan: false,
        enableTilt: false,
      },
    });
  }

  cleanup() {
    if (MapDraw.map) {
      MapDraw.map.context.cleanup();
    }
  }
}

export default MapDraw;
