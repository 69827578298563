import { ICON } from 'constants/icons';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';

const NavigateCustomField = () => {
  const { workspaceId } = useParams();
  const { navigateToReplacedPath } = useCustomNavigate();

  const handleNavigate = () => {
    if (workspaceId) {
      navigateToReplacedPath(RoutePath.customFieldSetting, workspaceId);
    }
  };

  return (
    <div className='flex items-center gap-2 pl-3 pr-2 cursor-pointer bg-bg-f9 h-7 rounded-xl' onClick={handleNavigate}>
      <div className='text-xs font-bold text-gray-99 whitespace-nowrap'>항목 설정</div>
      <div className='w-[18px] h-[18px]'>
        <img className='h-full' src={ICON.Setting} alt='setting' />
      </div>
    </div>
  );
};

export default NavigateCustomField;
