import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import DraggableButton from 'components/feature/ad/screensaver/list/table/row/buttons/drag/DraggableButton';
import MultiLangPopup from 'components/feature/category/popup/MultiLangPopup';
import { ICON } from 'constants/icons';
import useCategory from 'hooks/feature/category/useCategory';
import { useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { TenantCategoryGroup } from 'types/feature/category/tenantCategory';

type CategoryGroupRowProps = {
  pointId: string | undefined;
  draggableProvided: DraggableProvided;
  tenantCategoryGroup: TenantCategoryGroup;
  idx: number;
  refetch: () => Promise<void>;
};

const CategoryGroupRow = ({
  draggableProvided,
  idx,
  tenantCategoryGroup: { categories, name, id, feature },
  refetch,
  pointId,
}: CategoryGroupRowProps) => {
  const [openConfirmPopup, setOpenConfirmPopup] = useState<boolean>(false);
  const [openMultiLangPopup, setOpenMultiLangPopup] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const { deleteTenantCategoryGroup, refetchFeatureCategories } = useCategory();

  const handleDeleteCategory = async (groupId: string) => {
    try {
      if (pointId) {
        await deleteTenantCategoryGroup(groupId);
        await refetchFeatureCategories(pointId, feature);
      }

      setOpenConfirmPopup(false);
    } catch (error) {
      setOpenConfirmPopup(false);

      if (error instanceof AxiosError && error.response && error.response?.status === 400) {
        setOpenAlert(true);
      }
    }
  };

  return (
    <>
      <tr
        onClick={() => setOpenMultiLangPopup(true)}
        {...draggableProvided.draggableProps}
        ref={draggableProvided.innerRef}
        key={categories[0].contentCategoryCode}
        className='h-[48px]'
      >
        <td>
          <div {...draggableProvided.dragHandleProps} className='flex justify-center cursor-pointer'>
            <DraggableButton />
          </div>
        </td>
        <td>
          <div>{idx + 1}</div>
        </td>
        {/* todo 카테고리 코드 보여줘야하는 경우 주석 해제 */}
        <td>
          <div>{categories[0].contentCategoryCode.slice(0, 4)}</div>
        </td>

        {/* 타이틀 */}
        <td>
          <div className='text-left'>{name.ko}</div>
        </td>

        {/* 설정 */}
        <td
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setOpenMultiLangPopup(true);
          }}
        >
          <div className='flex justify-center cursor-pointer'>
            <img src={ICON.Setting} alt='설정' className='h-[20px]' />
          </div>
        </td>

        {/* 삭제 */}
        <td
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setOpenConfirmPopup(true);
          }}
        >
          <div className='flex justify-center cursor-pointer'>
            <img src={ICON.TRASH} alt='삭제' className='h-[20px]' />
          </div>
        </td>
      </tr>

      {openConfirmPopup && (
        <ConfirmPopup
          message='등록된 카테고리를 삭제하시겠습니까?'
          onClickOk={handleDeleteCategory.bind(this, id)}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}

      {openMultiLangPopup && (
        <MultiLangPopup
          closePopup={() => setOpenMultiLangPopup(false)}
          tenantCategoryId={id}
          refetch={refetch}
          name={name}
        />
      )}

      {openAlert && <AlertPopup message='카테고리를 삭제할 수 없습니다.' onClick={() => setOpenAlert(false)} />}
    </>
  );
};
export default CategoryGroupRow;
