import React from 'react';

type Props = {
  message: string;
};

const LoadingBackground = ({ message }: Props) => {
  return (
    <div className='w-screen h-screen fixed top-0 left-0 z-50 inset-y-0 bg-gray-400/[0.5] flex flex-col justify-center items-center'>
      <div className='relative inline-block w-20 h-36 '>
        <div className='absolute inline-block w-4 h-full bg-white animate-pow' />
        <div className='absolute inline-block w-4 h-full bg-white left-8 animate-pow' />
        <div className='absolute inline-block w-4 h-full bg-white left-16 animate-pow' />
      </div>

      <div className='text-xl font-bold text-slate-50'>{message}</div>
    </div>
  );
};

export default LoadingBackground;
