import { ICON } from 'constants/icons';
import React from 'react';

const NoCategory = () => {
  return (
    <div className='flex flex-col w-full h-full border border-gray-300 rounded-sm hover:bg-gray-100'>
      <div className='flex flex-col items-center justify-center w-full h-full gap-2'>
        <span className='opacity-80'>
          <ICON.PiPlusThin size={40} />
        </span>
        <span className='text-sm text-gray-500'>추가하실 카테고리를 끌어다 놓아주세요.</span>
      </div>
    </div>
  );
};
export default NoCategory;
