import ContentDetailContainer from 'components/feature/content/detail/ContentDetailContainer';
import React from 'react';

const ContentDetailPage = () => {
  return (
    <div className='w-full h-full'>
      <ContentDetailContainer />
    </div>
  );
};
export default ContentDetailPage;
