/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import FileDeleteButton from '../button/FileDeleteButton';

type Props = {
  handleClick?: () => void;
  id?: string;
  fileName: string | undefined;
  deleteFile: () => void;
  defaultValue?: string;
  disabled: boolean;
  disabledText?: string;
};

const FileName = ({ disabled, id, fileName, deleteFile, defaultValue, disabledText }: Props) => {
  return (
    <>
      {/* 비활성 텍스트 있을 경우 */}
      {disabled ? (
        <div className='flex items-center w-full gap-3 text-xs font-normal truncate h-9 text-gray-99'>
          {disabledText}
        </div>
      ) : (
        <>
          {/* 파일 이름 있을 경우 */}
          {fileName && (
            <div
              className={`${
                fileName ? 'cursor-pointer' : 'cursor-default'
              } flex items-center w-full overflow-hidden h-9`}
            >
              <label
                className={`text-gray-44 text-xs font-normal truncate 
            ${fileName ? 'cursor-pointer' : 'cursor-default'} 
            text-ellipsis overflow-hidden whitespace-nowrap leading-5 flex flex-col justify-center items-start w-full h-full`}
                htmlFor={id}
              >
                {fileName}
              </label>

              <FileDeleteButton deleteFile={deleteFile} />
            </div>
          )}

          {/* 파일 이름 없고 기본 텍스트 있을 경우 */}
          {!fileName && defaultValue && (
            <div className='flex items-center w-full gap-3 text-xs font-normal truncate text-gray-99 h-9'>
              {defaultValue}
            </div>
          )}
        </>
      )}
    </>
  );
};
export default FileName;
