import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { MultiLangs } from 'types/feature/language/language';
import { UpdateCustomField } from 'types/feature/setting/tenant/tenantField';

const useCustomFields = () => {
  const { api } = useAxios();

  const getCustomFields = async (workspaceId: string) => {
    const result = await api.get<ApiResponse<any>>(Endpoint.customField, {
      params: {
        workspaceId,
        featureType: 'TENANT',
      },
    });
    return result;
  };

  // 사용중인 커스텀 필드 목록
  const getCustomFieldsInUse = async (id: string) => {
    const result = await api.get<ApiResponse<any>>(Endpoint.customField.concat(`/${id}/in-use-fields`));
    return result;
  };

  const postCustomFields = async (workspaceId: string, title: MultiLangs) => {
    const result = await api.post<ApiResponse<any>>(Endpoint.customField, {
      title,
      featureType: 'TENANT',
      type: 'TEXT',
      workspaceId,
    });
    return result;
  };

  const deleteCustomField = async (id: string) => {
    const result = await api.delete(Endpoint.customField.concat(`/${id}`));
    return result;
  };

  // 타이틀 수정
  const patchCustomField = async (id: string, updateCustomField: UpdateCustomField) => {
    const result = await api.patch(Endpoint.customField.concat(`/${id}`), { ...updateCustomField });
    return result;
  };

  return {
    getCustomFields,
    getCustomFieldsInUse,
    postCustomFields,
    deleteCustomField,
    patchCustomField,
  };
};

export default useCustomFields;
