import { NO_IMAGE } from 'data/image';
import useWaitingListStore from 'stores/waitingList';
import { formatMegaByte } from 'utils/file/unit/fileUnit';

type ThumbnailContainerProps = {
  selectedId: string;
};

const ThumbnailContainer = ({ selectedId }: ThumbnailContainerProps) => {
  const { waitingList } = useWaitingListStore();

  const selected = waitingList?.filter(item => item.id === selectedId)[0];

  if (!selected) return <> </>;

  return (
    <div className='w-[42%] relative flex flex-col items-center justify-between p-4 rounded border'>
      <div className='h-[300px] flex justify-center'>
        {selected.fileType === 'IMAGE' ? (
          <img className='object-contain' src={selected?.file?.uri ?? NO_IMAGE} alt='썸네일' />
        ) : (
          <video src={selected.file.uri} autoPlay muted />
        )}
      </div>

      {selected.file && (
        <div className='absolute bottom-0 w-full px-10 py-4 border-t border-gray-300'>
          <ul className='list-disc'>
            <li>
              <div className='flex gap-2'>
                <span className='text-xs whitespace-nowrap'>파일명 : </span>
                <div className='text-xs truncate'>{selected?.file?.originalName ?? ''}</div>
              </div>
            </li>

            <li>
              <div className='flex gap-2'>
                <span className='text-xs whitespace-nowrap'>크기 : </span>
                <span className='text-xs'>{`${formatMegaByte(selected?.file?.size)}MB` ?? ''}</span>
              </div>
            </li>

            {/* <li>프레임 : {selected?.file?.size ?? ''}</li> */}
          </ul>
        </div>
      )}
    </div>
  );
};
export default ThumbnailContainer;
