/* eslint-disable react/require-default-props */
import Pagination from 'components/common/pagination/Pagination';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import ThumbnailTableRowCell from 'components/common/thumbnail/ThumbnailTableRowCell';
import { ICON } from 'constants/icons';
import { PAGINATION_LIMIT } from 'data/common';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useContents from 'hooks/feature/content/useContents';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { TextType } from 'types/common/text';
import { ContentItem, ContentTypeCodeKo, ScreenTypeCodeKo } from 'types/feature/content/contents';
import ContentTableHeader from './header/ContentTableHeader';

type ContentTableProps = {
  contentItems: ContentItem[];
  totalElements: number | undefined;
  currentPage: number;
  onChangePage: (pageNum: number) => void;
  refreshContents: () => void;
};

const ContentTable = ({
  contentItems,
  currentPage,
  totalElements,
  onChangePage,
  refreshContents,
}: ContentTableProps) => {
  const { workspaceId } = useParams();
  const { navigateToReplacedPath } = useCustomNavigate();

  const [isDelete, setIsDelete] = useState(false);
  const [deleteContentId, setDeleteContentId] = useState('');
  const { deleteContent } = useContents();

  const handleClickTableRow = (id: string) => {
    navigateToReplacedPath(RoutePath.contentDetail, workspaceId, ':contentId', id);
  };

  const deleteContentRow = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();
    setDeleteContentId(id);
    setIsDelete(true);
  };

  const fetchDelete = async () => {
    try {
      if (deleteContentId) {
        await deleteContent(deleteContentId);
        setIsDelete(false);
        refreshContents();
      }
    } catch (e) {
      console.warn('delete error', e);
    }
  };

  return (
    <div className='flex flex-col gap-10 pb-20'>
      <table className='w-full'>
        <ContentTableHeader />

        <tbody>
          {contentItems.map((row, index) => (
            <tr key={row.id} className='cursor-pointer h-[48px]' onClick={handleClickTableRow.bind(this, row.id)}>
              {/* No. */}
              {/* index 11 21 31... */}
              <td>{currentPage * PAGINATION_LIMIT + (index + 1)}</td>

              {/* 구분 가로/세로 */}
              <td>
                <div>{ScreenTypeCodeKo[row.screenType]}</div>
              </td>

              {/* 파일 유형 */}
              <td>{ContentTypeCodeKo[row.fileType]}</td>

              {/* 타이틀 */}
              <td>
                <ThumbnailTableRowCell
                  title={row.title}
                  files={row.file}
                  fileType={row.fileType}
                  titleStyle={TextType.h4_bold}
                />
              </td>

              {/* 최근 수정일 */}
              <td className={`text-[11px] text-gray-44`}>{row.updatedDate}</td>

              {/* 삭제 */}
              <td onClick={e => deleteContentRow(e, row.id)}>
                <div className='flex items-center justify-center'>
                  <img src={ICON.TRASH_BUTTON} alt='삭제' />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* 페이지네이션 */}
      {contentItems.length > 0 ? (
        <div className='fixed bottom-[80px] left-1/2 -translate-x-1/2'>
          <Pagination totalElements={totalElements ?? 0} currentPage={currentPage} onChangePage={onChangePage} />
        </div>
      ) : (
        <div className='flex justify-center w-full text-sm text-gray-99'>등록된 콘텐츠가 없습니다.</div>
      )}

      {isDelete && (
        <ConfirmPopup
          message='정말 삭제하시겠습니까?'
          onClickOk={fetchDelete}
          onClickCancel={() => setIsDelete(false)}
        />
      )}
    </div>
  );
};

export default ContentTable;
