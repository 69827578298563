import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import DraggableButton from 'components/feature/ad/screensaver/list/table/row/buttons/drag/DraggableButton';
import { ICON } from 'constants/icons';
import useCustomFields from 'hooks/feature/custom-field/useCustomFields';
import { useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { TEXT_OVERFLOW } from 'styles/field';
import { TenantField } from 'types/feature/setting/tenant/tenantField';
import EditCustomFieldPopup from '../../popup/EditCustomFieldPopup';

type Props = {
  provided: DraggableProvided;
  field: TenantField;
  getCustomFieldList: () => Promise<void>;
};

const CustomFieldTableRow = ({ field, provided, getCustomFieldList }: Props) => {
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [inUseMessage, setInUseMessage] = useState('');
  const { getCustomFieldsInUse, deleteCustomField } = useCustomFields();

  // 사용중인 커스텀 필드 확인
  const checkTenantFieldInUse = async () => {
    try {
      const result = await getCustomFieldsInUse(field.id);
      if (result) {
        const countOfInUse = result.data.length;

        if (result.data.length > 0) {
          setInUseMessage(`현재 ${countOfInUse}에서 사용 중인 항목입니다. 정말 삭제 하시겠습니까?`);
        }
        setInUseMessage('현재 사용자 항목을 삭제합니다.');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          console.error('오류가 발생했습니다', error);
          setOpenAlertPopup(true);
          setAlertMessage('오류가 발생했습니다.');
        }
      }
    }
  };

  // 커스텀 필드 삭제
  const handleDeleteCustomField = async () => {
    try {
      await deleteCustomField(field.id);
      await getCustomFieldList();
      setOpenConfirmPopup(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          console.error('오류가 발생했습니다', error);
          setOpenAlertPopup(true);
          setAlertMessage(error.message);
        }
      }
    }
  };

  const onClickDeleteRow = async () => {
    await checkTenantFieldInUse();

    setOpenConfirmPopup(true);
  };

  const handleEditRow = () => {
    setOpenEditPopup(true);
  };

  return (
    <>
      <tr {...provided.draggableProps} ref={provided.innerRef} key={field.id}>
        <td {...provided.dragHandleProps}>
          <div className='w-[60px] flex items-center justify-center'>
            <DraggableButton />
          </div>
        </td>
        <td className='bg-[#f9f9f9]'>
          <div className={`w-[147px] ${TEXT_OVERFLOW}`}>{field.title.ko || ''}</div>
        </td>
        <td>
          <div className={`w-[147px] ${TEXT_OVERFLOW}`}>{field.title.en || ''}</div>
        </td>
        <td>
          <div className={`w-[147px] ${TEXT_OVERFLOW}`}>{field.title.ja || ''}</div>
        </td>
        <td>
          <div className={`w-[147px] ${TEXT_OVERFLOW}`}>{field.title.cn || ''}</div>
        </td>
        <td>
          <div className={`w-[147px] ${TEXT_OVERFLOW}`}>{field.title.tw || ''}</div>
        </td>
        <td className='flex items-center justify-center'>
          <div className='flex gap-2 px-4 py-2 w-[120px]'>
            <div className='w-[36px]' onClick={onClickDeleteRow}>
              <img src={ICON.TRASH_BUTTON} alt='trash' />
            </div>
            <div className='w-[36px]' onClick={handleEditRow}>
              <img src={ICON.Pencil} alt='pencil' />
            </div>
          </div>
        </td>
      </tr>
      {openEditPopup && (
        <EditCustomFieldPopup
          customField={field}
          closePopup={() => setOpenEditPopup(false)}
          getCustomFieldList={getCustomFieldList}
        />
      )}
      {openConfirmPopup && (
        <ConfirmPopup
          message={inUseMessage}
          onClickOk={handleDeleteCustomField}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}

      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default CustomFieldTableRow;
