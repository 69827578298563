import CommonSelectBox from 'components/common/select/CommonSelectBox';
import { MACHINE_PLACE_TYPE } from 'data/filter';
import useFloor from 'hooks/feature/floor/useFloor';
import useMachine from 'hooks/feature/machine/useMachine';
import useMachineSocket from 'hooks/feature/socket/useMachineSocket';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConnectedMachine } from 'types/feature/menu/machine/machine';
import { Point, Workspace } from 'types/feature/workspace/workspace';
import MonitoringTable from './table/MonitoringTable';

const MonitoringContainer = () => {
  // hook
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();
  const { getMachines } = useMachine();
  const { fetchFloors, floorList } = useFloor();

  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [pointList, setPointList] = useState<Point[]>([]);
  const [selectedPointId, setSelectedPointId] = useState('');
  const [placeType, setPlaceType] = useState('');
  const [connectedMachineList, setConnectedMachineList] = useState<ConnectedMachine[]>([]);

  /**
   * 워크스페이스를 조회한다
   */
  const fetchWorkspace = async () => {
    if (!workspaceId) return;

    const workspaceResult = await getWorkspace(workspaceId);
    setWorkspace(workspaceResult.data);
    setPointList(workspaceResult.data.points);
    setSelectedPointId(workspaceResult.data.points[0].id);
  };

  /**
   * 기기 목록을 조회한다
   * @param machinePlaceType
   */
  const fetchFloorsAndMachines = async (machinePlaceType: string) => {
    /**
     * 층 목록을 불러온다
     */
    await fetchFloors(selectedPointId);

    const result = await getMachines(selectedPointId, machinePlaceType || '');
    setConnectedMachineList(result.data);
  };

  // 현재 포인트 변경
  const onChangePoint = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPointId(e.target.value);
  };

  // 기기 구분
  const onChangeMachinePlace = (e: ChangeEvent<HTMLSelectElement>) => {
    setPlaceType(e.target.value);
  };

  useEffect(() => {
    fetchWorkspace();
  }, [workspaceId]);

  /**
   * socket 연결 custom hook
   * socket 이 connect / close 되었을 때 목록을 다시 fetch 하도록 처리한다
   */
  const { updatedMachine } = useMachineSocket({
    callback: async () => {
      await fetchFloorsAndMachines(placeType);
    },
    pointId: selectedPointId,
  });

  useEffect(() => {
    if (updatedMachine.machineId) {
      fetchFloorsAndMachines(placeType);
    }
  }, [updatedMachine]);

  /**
   * 포인트, 기기 위치 구분이 변경될 경우 기기 리스트 다시 가져온다
   */
  useEffect(() => {
    if (!workspace || !selectedPointId) return;

    fetchFloorsAndMachines(placeType);
  }, [workspace, selectedPointId, placeType]);

  return (
    <div className='flex flex-col w-full gap-10'>
      {/* 필터 */}
      <div className='flex gap-2.5'>
        <CommonSelectBox
          width='w-[140px]'
          onChange={onChangePoint}
          selected={selectedPointId}
          options={pointList.map(({ id, name }) => {
            return {
              id,
              value: name.ko,
            };
          })}
        />
        <CommonSelectBox
          name='구분 전체'
          width='w-[140px]'
          onChange={onChangeMachinePlace}
          selected={placeType}
          options={[{ id: '', value: '구분 전체' }, ...MACHINE_PLACE_TYPE]}
        />
      </div>

      {/* 테이블 */}
      <MonitoringTable connectedMachineList={connectedMachineList} floorList={floorList} />
    </div>
  );
};

export default MonitoringContainer;
