import GuideDescription from 'components/common/description/GuideDescription';
import FileDropZone from 'components/common/file/drop-zone/FileDropZone';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import FailPopup from 'components/common/popup/FailPopup';
import SelectBox from 'components/common/select/SelectBox';
import { CONTENT_UPLOAD_GUIDES, CONTENT_UPLOAD_GUIDE_INFO } from 'data/guide/screensaver';
import useContents from 'hooks/feature/content/useContents';
import { Form } from 'pages/service/content/add/AddPage';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FileType } from 'types/common/file/file';
import { FileUploadType } from 'types/common/file/fileUpload';
import { ContentForm, ScreenTypeCode } from 'types/feature/content/contents';
import { returnFileAccept } from 'utils/file/extension/returnFileAccept';
import { returnFileMaxSize } from 'utils/file/size/returnFileSize';
import { validateFileType, validateFileVolume } from 'utils/file/validate-type/validateFileType';

type Props = {
  addForm: Form;
  setAddForm: React.Dispatch<React.SetStateAction<Form>>;
};

const ContentAddForm = ({ addForm, setAddForm }: Props) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [failMessage, setFailMessage] = useState('');
  const [uploadFail, setUploadFail] = useState(false);
  const [fileUri, setFileUri] = useState('');
  const [ableToUpload, setAbleToUpload] = useState(false);

  const { uploadFileItem } = useContents();
  const { workspaceId } = useParams();

  // 타이틀
  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddForm((prev: Form) => ({ ...prev, title: value }));
  };

  // 구분
  const handleScreenTypeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const screenType = e.target.value as ScreenTypeCode;
    setAddForm((prev: Form) => ({ ...prev, screenType }));
  };

  // 유형 선택
  const handleFileSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const fileType = e.target.value as FileUploadType;
    handleResetFile();
    if (workspaceId) {
      setAddForm((prev: Form) => ({
        ...prev,
        workspaceId,
        fileType,
      }));
    }
  };

  // 리셋
  const handleResetFile = () => {
    handleDeleteFile();
    setFileUri('');
    handleDeleteFile();
  };

  // 삭제
  const handleDeleteFile = () => {
    setAddForm((prev: Form) => ({
      ...prev,
      file: {
        name: '',
        originalName: '',
      },
    }));
  };

  // 파일 업로드 실패 시
  const openFileErrorAlert = (message: string) => {
    setFailMessage(message);
    setUploadFail(true);
    setFileLoading(false);
  };

  const uploadContentFile = async (file: File) => {
    try {
      if (file && addForm.fileType) {
        setFileLoading(true);
        const result = await uploadFileItem(file, addForm.fileType);

        if (result) {
          const response = result.data;

          setFileUri(response.fileUri);
          setFileLoading(false);
          setAddForm((prev: Form) => ({
            ...prev,
            file: {
              name: response.fileName,
              originalName: file.name,
            },
          }));
        }
      }
    } catch (e) {
      setFailMessage('파일 업로드 중 오류가 발생했습니다.');
      setUploadFail(true);
      setFileLoading(false);
    }
  };

  const handleChangeFile = async (file: File) => {
    if (file && addForm.fileType) {
      if (!validateFileType(file, addForm.fileType)) {
        setFailMessage('파일 확장자를 확인해주세요.');
        setUploadFail(true);
        return;
      }

      // 파일 용량이 허용한 수준을 넘었을 경우
      const uploadFileVolumeChecked = validateFileVolume(file.size, addForm.fileType);
      if (uploadFileVolumeChecked) {
        if (!uploadFileVolumeChecked.enableUpload) {
          setFailMessage(uploadFileVolumeChecked.message);
          setUploadFail(true);
          return;
        }
      }

      await uploadContentFile(file);
    }
  };

  // file upload type ->  file type
  const convertContentUploadType = () => {
    if (addForm.fileType === FileUploadType.CONTENT_IMAGE) {
      return FileType.image;
    }

    if (addForm.fileType === FileUploadType.CONTENT_VIDEO) {
      return FileType.video;
    }

    return FileType.image;
  };

  useEffect(() => {
    if (addForm.fileType && addForm.screenType) {
      setAbleToUpload(true);
    }
  }, [addForm]);

  return (
    <>
      <div className='flex flex-col w-full gap-4 justify-items-start'>
        <FlexFormControl
          name='타이틀'
          required
          control={
            <Input width='w-[300px]' placeholder='타이틀을 작성하세요' name='title' onChange={handleTitleChange} />
          }
        />
        <FlexFormControl
          name='구분'
          required
          control={
            <SelectBox
              name={ContentForm.screenType}
              width='w-52'
              options={[
                { id: '', value: '선택' },
                { id: ScreenTypeCode.HORIZONTAL, value: '가로형' },
                { id: ScreenTypeCode.VERTICAL, value: '세로형' },
              ]}
              onChange={handleScreenTypeSelect}
            />
          }
        />

        <FlexFormControl
          name='유형'
          required
          control={
            <SelectBox
              name={ContentForm.fileType}
              width='w-52'
              options={[
                { id: '', value: '선택' },
                { id: FileUploadType.CONTENT_IMAGE, value: '이미지' },
                { id: FileUploadType.CONTENT_VIDEO, value: '동영상' },
              ]}
              onChange={handleFileSelectChange}
            />
          }
        />
        <FlexFormControl
          name='파일'
          disabled={!ableToUpload}
          required
          control={
            <div className='flex flex-col items-start justify-center w-full h-full gap-4'>
              <FileDropZone
                maxSize={returnFileMaxSize(addForm.fileType ?? FileUploadType.CONTENT_IMAGE)}
                uploadFile={handleChangeFile}
                fileUri={fileUri}
                fileType={convertContentUploadType() ?? FileType.image}
                deleteFile={handleDeleteFile}
                accept={returnFileAccept(addForm.fileType ?? FileUploadType.CONTENT_IMAGE)}
                openFileErrorAlert={openFileErrorAlert}
                loading={fileLoading}
                fileName={addForm.file.originalName}
                disabled={!ableToUpload}
                height='h-[300px]'
                disabledText='콘텐츠 구분과 유형을 선택해주세요.'
              />

              {ableToUpload && (
                <GuideDescription
                  width='w-[360px]'
                  manualInfo={CONTENT_UPLOAD_GUIDE_INFO}
                  guides={CONTENT_UPLOAD_GUIDES}
                  icon={false}
                  listDisc
                />
              )}
            </div>
          }
        />
      </div>

      {uploadFail && <FailPopup closeModal={() => setUploadFail(false)} text={failMessage} />}
    </>
  );
};

export default ContentAddForm;
