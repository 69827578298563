import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FLEX_CENTER } from 'styles/flex';
import { MenuType } from 'types/common/menus';
import { RoutePath } from 'types/common/paths';
import { TextType } from 'types/common/text';
import SubMenus from './sub/SubMenus';

type MenuProps = {
  menu: MenuType;
};

const Menu = ({ menu: { id, path, subMenus } }: MenuProps) => {
  const [hover, setHover] = useState(false);
  const [isMainActive, setMainActive] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { replaceWorkspaceId } = useCustomNavigate();

  const moveToPage = (page: RoutePath) => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(page, workspaceId));
    }
  };

  //  메인메뉴 active 변경
  const changeMenuActive = () => {
    const includeMain =
      // 서브메뉴 또는 메인메뉴 선택 시
      location.pathname.includes(path) || location.pathname.includes(path.replace('/:workspaceId', ''));

    if (includeMain) {
      setMainActive(true);

      return;
    }

    setMainActive(false);
  };

  useEffect(() => {
    changeMenuActive();
  }, [location.pathname]);

  const handleOpenSubMenus = () => {
    setHover(true);
  };

  return (
    <div
      onFocus={handleOpenSubMenus}
      onMouseOver={handleOpenSubMenus}
      onMouseLeave={() => setHover(false)}
      onClick={moveToPage.bind(this, path)}
      className={`px-5  h-full cursor-pointer relative ${FLEX_CENTER.column}`}
    >
      <span
        className={`${
          isMainActive ? `${TextType.h3_bold} text-gray-22` : `${TextType.h3} text-gray-99`
        } hover:!font-bold whitespace-nowrap`}
      >
        {id}
      </span>
      {subMenus && hover && <SubMenus subMenus={subMenus} handleOpenSubMenus={handleOpenSubMenus} />}
    </div>
  );
};
export default Menu;
