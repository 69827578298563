import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Tenant } from 'types/feature/tenant/getTenant';
import { PatchTenantDetail, PutTenantCustomField } from 'types/feature/tenant/patchTenant';
import useAxiosMultipart from '../../../api/useAxiosMultipart';

const useTenant = () => {
  const { api } = useAxios();
  const { apiFile } = useAxiosMultipart();

  const getTenants = async (pointId: string) => {
    const res = await api.get<ApiResponse<Tenant[]>>(Endpoint.tenants, {
      params: {
        pointId,
      },
    });

    if (!res.data) return;
    return res.data;
  };

  const updateTenantDetail = async (tenantId: string, data: PatchTenantDetail) => {
    const res = await api.patch<ApiResponse<PatchTenantDetail>>(
      `${Endpoint.tenantDetail.replace(':tenantId', tenantId)}`,
      data,
    );

    if (!res.data) return;
    return res.data;
  };

  const updateTenantCustomField = async (tenantCustomFieldId: string, data: PutTenantCustomField) => {
    const res = await api.put<ApiResponse<PutTenantCustomField>>(
      `${Endpoint.tenantCustomFields.replace(':tenantCustomFieldId', tenantCustomFieldId)}`,
      data,
    );

    if (!res.data) return;
    return res.data;
  };

  const downloadTenantsCsv = async (pointId: string) => {
    const res = await api.get<Blob>(`${Endpoint.tenants}/download`, {
      responseType: 'blob',
      params: {
        pointId,
      },
    });
    return res;
  };

  const uploadTenantsCsv = async (file: File, pointId: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('pointId', pointId);
    const res = apiFile.post<Blob>(`${Endpoint.tenants}/upload`, formData);
    return res;
  };

  return { getTenants, updateTenantDetail, updateTenantCustomField, downloadTenantsCsv, uploadTenantsCsv };
};
export default useTenant;
