type Props = {
  length: number | undefined;
  wordLimit: number;
};

const MultiLangLimitLength = ({ length, wordLimit }: Props) => {
  return (
    <div className='text-xs font-light text-gray-cc'>
      {length}/{wordLimit}
    </div>
  );
};

export default MultiLangLimitLength;
