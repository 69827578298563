import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ModalContainer from 'components/common/modal/ModalContainer';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import useCustomFields from 'hooks/feature/custom-field/useCustomFields';
import { useState } from 'react';
import { MultiLangs } from 'types/feature/language/language';
import { TenantField } from 'types/feature/setting/tenant/tenantField';
import CustomFieldMultiLangInputs from '../multi-language/CustomFieldMultiLangInputs';

type Props = {
  customField: TenantField;
  closePopup: () => void;
  getCustomFieldList: () => Promise<void>;
};

const EditCustomFieldPopup = ({ customField, closePopup, getCustomFieldList }: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [customFieldName, setCustomFieldName] = useState<MultiLangs>({
    ko: customField.title.ko,
    en: customField.title.en,
    ja: customField.title.ja,
    cn: customField.title.cn,
    tw: customField.title.tw,
  });
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const { patchCustomField } = useCustomFields();

  const handleChangeCustomFieldName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCustomFieldName((prev: MultiLangs) => ({
      ...prev,
      [id as string]: value,
    }));
  };

  const handleClickEditCustomField = async () => {
    try {
      await patchCustomField(customField.id, { title: customFieldName });
      await getCustomFieldList();
      setOpenConfirmPopup(false);
      closePopup();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          setAlertMessage('오류가 발생했습니다.');
          setOpenAlertMessage(true);
        }
      }
    }
  };
  return (
    <>
      <ModalContainer>
        <div className='w-[480px]  bg-white flex flex-col'>
          <div className='w-full px-10 border-b py-7'>
            <Title text='상세항목 수정' titleType='h3_bold' />
          </div>
          <div className='px-10 border-b py-7'>
            <CustomFieldMultiLangInputs
              handleChangeMenuName={handleChangeCustomFieldName}
              customField={customField}
              customFieldName={customFieldName}
            />
          </div>
          <div className='flex justify-center gap-3 px-10 py-7 '>
            <Button text='취소' size={120} color={ButtonColor.secondary} onClick={closePopup} />
            <Button text='항목 수정' size={120} color={ButtonColor.primary} onClick={() => setOpenConfirmPopup(true)} />
          </div>
        </div>
      </ModalContainer>
      {openConfirmPopup && (
        <ConfirmPopup
          message='해당 항목을 수정 하시겠습니까?'
          onClickOk={handleClickEditCustomField}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}
      {openAlertMessage && <AlertPopup message={alertMessage} onClick={() => setOpenAlertMessage(false)} />}
    </>
  );
};

export default EditCustomFieldPopup;
