import { Floor } from 'types/feature/floor/floor';
import { ConnectedMachine } from 'types/feature/menu/machine/machine';
import { Point } from 'types/feature/workspace/workspace';
import DashboardMachineItem from './machine/DashboardMachineItem';

type Props = {
  floor: Floor;
  isLastFloor: boolean;
  machines: ConnectedMachine[];
  fetchMachines: () => Promise<void>;
  point: Point;
};

const FloorMachines = (props: Props) => {
  return (
    <section className='flex flex-col gap-2.5 self-stretch'>
      {/* 층 */}
      <div className='flex w-[17.5rem] h-[3.75rem] p-[1.25rem_1.5rem] items-end rounded-[1.125rem]'>
        <p className='text-[#777A8B] font-pretendard text-[1rem] font-bold leading-[1.5rem]'>{props.floor.name.ko}</p>
      </div>
      {/* 기기 목록 */}
      <div className='flex w-[17.5rem] flex-col items-start rounded-[0.75rem] gap-2.5'>
        {props.machines.map(machine => (
          <DashboardMachineItem
            key={machine.id}
            machine={machine}
            fetchMachines={props.fetchMachines}
            floor={props.floor.name.ko}
            point={props.point}
          />
        ))}
      </div>
    </section>
  );
};
export default FloorMachines;
