import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertPopup from 'components/common/popup/AlertPopup';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import usePoint from 'hooks/feature/point/usePoint';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useScreensaverStore from 'stores/screensaver';
import { RoutePath } from 'types/common/paths';
import { ScreenType } from 'types/common/screenType';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { Point } from 'types/feature/workspace/workspace';
import FilterContainer from './filter/FilterContainer';
import ScreensaversTable from './table/ScreensaversTable';

const ScreensaverContainer = () => {
  const [points, setPoints] = useState<Point[]>([]);
  const [selectedPointId, setSelectedPointId] = useState<string>('');
  const [selectedType, setSelectedType] = useState(ScreenType.all);

  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId, pointId: paramPointId } = useParams();
  const { getPoints } = usePoint();

  const { getScreensavers } = useScreensaver();
  const { setScreensavers } = useScreensaverStore();

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const fetchPoints = async () => {
    if (workspaceId) {
      const pointList = await getPoints(workspaceId);

      if (pointList) {
        setPoints(pointList);

        if (paramPointId) {
          setSelectedPointId(paramPointId);
          await fetchScreensavers(paramPointId, ScreenType.all);
        } else {
          setSelectedPointId(pointList[0].id);
          await fetchScreensavers(pointList[0].id, ScreenType.all);
        }
      }
    }
  };

  const fetchScreensavers = async (pointId: string, screenType: ScreenType) => {
    try {
      if (pointId) {
        const screensaverList: GetScreensaver[] | undefined = await getScreensavers(pointId);

        if (screensaverList) {
          if (screenType === ScreenType.all) {
            return setScreensavers(screensaverList);
          }

          return setScreensavers(screensaverList.filter(row => row.content.screenType === screenType));
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          setAlertMessage('스크린세이버를 불러올 수 없습니다.');
          setOpenAlert(true);
        }
      }
    }
  };

  const handleChangePoint = async (e: ChangeEvent<HTMLSelectElement>) => {
    const pointId = e.target.value;

    setSelectedPointId(pointId);
    await fetchScreensavers(pointId, selectedType);

    navigateToReplacedPath(RoutePath.pointScreensaver, workspaceId, ':pointId', pointId);
  };

  const handleChangeType = async (e: ChangeEvent<HTMLSelectElement>) => {
    const screenType = e.target.value as ScreenType;

    setSelectedType(screenType);
    await fetchScreensavers(selectedPointId, screenType);
  };

  const handleClick = () => {
    navigateToReplacedPath(RoutePath.pointScreensaverAdd, workspaceId, ':pointId', selectedPointId);
  };

  useEffect(() => {
    fetchPoints();
  }, [workspaceId, paramPointId]);

  return (
    <div className='flex flex-col w-full gap-4 pb-20'>
      <div className='flex items-center justify-between w-full'>
        <FilterContainer
          points={points}
          selectedType={selectedType}
          selectedPointId={selectedPointId}
          handleChangePoint={handleChangePoint}
          handleChangeType={handleChangeType}
        />
        <Button onClick={handleClick} text='스케줄 추가' color={ButtonColor.primary} size='add' />
      </div>

      <div className='flex flex-col items-end gap-6'>
        <span className={`text-primary-DA_Red text-xs font-semibold`}>모든 변동 사항은 실시간 반영됩니다.</span>
        <ScreensaversTable screenType={selectedType} pointId={selectedPointId} fetchScreensavers={fetchScreensavers} />
      </div>

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </div>
  );
};

export default ScreensaverContainer;
