import FlexFormControl from 'components/common/form-control/FlexFormControl';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import SelectBox from 'components/common/select/SelectBox';
import { SCREEN_TYPE } from 'data/filter';
import { ChangeEvent } from 'react';
import { Point } from 'types/feature/workspace/workspace';

type FilterContainerProps = {
  points: Point[];
  selectedType: string;
  selectedPointId: string;
  handleChangePoint: (e: ChangeEvent<HTMLSelectElement>) => void;
  handleChangeType: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const FilterContainer = ({
  points,
  selectedType,
  selectedPointId,
  handleChangePoint,
  handleChangeType,
}: FilterContainerProps) => {
  return (
    <div className='flex gap-6'>
      <FlexFormControl
        name='빌딩'
        labelSize='sm'
        control={
          <CommonSelectBox
            options={points.map(point => ({
              id: point.id,
              value: point.name.ko,
            }))}
            selected={selectedPointId}
            onChange={handleChangePoint}
            width='w-40'
          />
        }
      />

      <FlexFormControl
        name='유형'
        labelSize='sm'
        control={<SelectBox options={SCREEN_TYPE} selected={selectedType} onChange={handleChangeType} width='w-40' />}
      />
    </div>
  );
};

export default FilterContainer;
