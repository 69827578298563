import _ from 'lodash';
import { WaitingList } from 'types/feature/screensaver/waitingList';
import { create } from 'zustand';

type WaitingListStoreProps = {
  waitingList: WaitingList[] | [];
  setWaitingList: (waitingList: WaitingList[]) => void;
};

// 스크린세이버 대기목록 리스트 (refetch 위해서 store 사용)
const useWaitingListStore = create<WaitingListStoreProps>((set, get) => ({
  waitingList: [],
  setWaitingList: (waitingList: WaitingList[]) => set(() => ({ waitingList: _.cloneDeep(waitingList) })),
}));

export default useWaitingListStore;
