import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWaitingListStore from 'stores/waitingList';
import { DisplayType } from 'types/common/display';
import { RoutePath } from 'types/common/paths';
import { PostScreensaver } from 'types/feature/screensaver/postScreensaver';

type ScreensaverAddButtonsProps = {
  selectedId: string;
};

const ScreensaverAddButtons = ({ selectedId }: ScreensaverAddButtonsProps) => {
  const { pointId } = useParams();

  const { postScreensaver } = useScreensaver();
  const { customNavigate } = useCustomNavigate();

  const { waitingList, setWaitingList } = useWaitingListStore();

  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isClicked, setClicked] = useState(false);

  const moveToScreensaverPage = () => {
    customNavigate(RoutePath.pointScreensaver, pointId);
  };

  const handleSubmit = async () => {
    try {
      setClicked(true);

      const selected = waitingList.filter(item => item.id === selectedId)[0];
      if (!selected) return;

      const { id, displayType, startDate, endDate } = selected;

      if (!pointId) return;

      const screensaverToSubmit: PostScreensaver = {
        pointId,
        contentId: id,
        displayType,
        startDate,
        endDate: !endDate ? startDate : endDate, // ! 상시노출일 경우라도 endDate를 빈 값으로 넘겨주면 안됌
      };

      await postScreensaver(screensaverToSubmit);

      moveToScreensaverPage();
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response?.status === 400) {
        setOpenAlert(true);
        setAlertMessage('스크린세이버 스케줄을 추가하는 중에 문제가 발생하였습니다.');
      }
    } finally {
      setClicked(false);
    }
  };

  const handleConfirmPopup = () => {
    const valid = validScreensaver();

    if (valid) {
      setOpenConfirmPopup(true);
    }
  };

  const validScreensaver = () => {
    const selected = waitingList.filter(item => item.id === selectedId)[0];
    if (!selected) return;

    const { displayType, endDate } = selected;

    // 기간노출인데
    if (displayType === DisplayType.duration) {
      // 종료 날짜가 없을 경우
      if (!endDate || endDate === 'Invalid Date') {
        setAlertMessage('종료날짜가 필요합니다.');
        setOpenAlert(true);
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    return () => {
      setWaitingList([]);
    };
  }, []);

  return (
    <>
      <div className='flex flex-col items-center gap-4 mt-10'>
        <span className='text-xs font-semibold text-primary-DA_Red'>저장 시 실시간 반영됩니다.</span>

        <div className='flex justify-center w-full gap-4'>
          <Button onClick={moveToScreensaverPage} text='취소' color={ButtonColor.secondary} />
          <Button
            disabled={waitingList.length < 1}
            onClick={handleConfirmPopup}
            text='저장'
            color={ButtonColor.primary}
          />
        </div>
      </div>

      {openConfirmPopup && (
        <ConfirmPopup
          disabled={isClicked}
          message='스크린세이버 스케줄을 추가하시겠습니까?'
          onClickOk={handleSubmit}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};
export default ScreensaverAddButtons;
