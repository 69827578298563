import { IMAGES } from 'constants/images';
import { TextType } from 'types/common/text';
import { Color, ColorSet } from 'types/feature/setting/theme/theme.type';

type PreviewMenuType = 'main' | 'floor' | 'tenant';

type Props = {
  activeMenu: PreviewMenuType;
  usedColorSet: ColorSet | undefined;
};

const ThemePreviewMenu = ({ activeMenu, usedColorSet }: Props) => {
  const subColor = usedColorSet?.colors?.find((color: Color) => color.type === 'SUB')?.color ?? '';

  const previewMenus: {
    handle: string;
    type: PreviewMenuType;
    activeBackground: string;
  }[] = [
    {
      handle: '홈',
      type: 'main',
      activeBackground: '#222',
    },
    {
      handle: '층별안내',
      type: 'floor',
      activeBackground: subColor,
    },
    {
      handle: '매장안내',
      type: 'tenant',
      activeBackground: subColor,
    },
  ];

  return (
    <div className='h-[60px] flex'>
      {previewMenus.map(({ handle, type, activeBackground }) => (
        <div
          key={type}
          style={{ background: `${activeMenu === type ? activeBackground : '#fff'}` }}
          className={`flex flex-col items-center justify-center flex-1
           ${type === 'floor' && 'border-x-2 border-gray-ea'}`}
        >
          {type === 'main' && activeMenu === 'main' ? (
            <img src={IMAGES.PREVIEW_MENU_LOGO} alt='미리보기 메뉴 로고' />
          ) : (
            <span className={`relative ${TextType.h5} ${activeMenu === type ? 'text-white' : 'bg-white text-gray-44'}`}>
              {handle}
              {activeMenu === type && <div className='absolute w-full h-[1.5px] mt-[2px] bg-white' />}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};
export default ThemePreviewMenu;
