import { SyncResult } from 'types/common/sync';
import ModalContainer from 'components/common/modal/ModalContainer';
import Title from 'components/common/title/Title';
import { sliceDate } from 'utils/convert-date/convertDate';
import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import { TextType } from 'types/common/text';

type Props = {
  syncResult: SyncResult;
  closeModal: () => void;
};

const SyncResultModal = ({ syncResult, closeModal }: Props) => {
  return (
    <ModalContainer>
      <div className='bg-white'>
        <div className='px-8 py-10 w-[480px] gap-6 flex flex-col'>
          <div className='h-9'>
            <Title text='지도 동기화 결과' titleType={'h3_bold'} />
          </div>

          <table>
            <colgroup>
              <col width='240px' />
              <col width='240px' />
            </colgroup>

            <tbody className={`text-center border border-gray-22 text-h4 font-h4 leading-h4`}>
              <tr className={`border-b border-gray-22 h-9`}>
                <td className={`border-r border-gray-22 bg-state-blue_bg`}>동기화 진행일</td>
                <td className='font-semibold'>{sliceDate(syncResult.synchronizedDate)}</td>
              </tr>

              <tr className={`border-b border-gray-22 h-9`}>
                <td className={`border-r border-gray-22 bg-state-blue_bg`}>변경된 지도 버전</td>
                <td className='font-semibold'>{syncResult.mapVersion}v</td>
              </tr>

              <tr className={`border-b border-gray-22 h-9`}>
                <td className={`border-r border-gray-22 bg-state-blue_bg`}>변경된 매장(POI) 수</td>
                <td className='font-semibold'>{syncResult.changedTenantCount}개</td>
              </tr>
            </tbody>
          </table>

          <Border />

          <div className='flex items-center justify-center w-full gap-2.5'>
            <Button
              onClick={closeModal}
              color={ButtonColor.secondary}
              size={80}
              text='확인'
              textStyle={TextType.body2}
            />
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
export default SyncResultModal;
