import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { ICON } from 'constants/icons';
import useTenant from 'hooks/feature/tenant/useTenant';
import { ChangeEvent, useState } from 'react';
import { TEXT_OVERFLOW } from 'styles/field';
import { FLEX_CENTER } from 'styles/flex';
import { Language, PatchLanguage } from 'types/feature/language/language';
import { TenantCustomField } from 'types/feature/tenant/getTenant';

type CustomEditableFieldProps = {
  field: TenantCustomField;
  tenantId: string;
  displayLanguageList: Language[];
  fetchTenants: () => Promise<void>;
};

const CustomEditableField = ({ field, tenantId, displayLanguageList, fetchTenants }: CustomEditableFieldProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const [customFieldContent, setCustomFieldContent] = useState<PatchLanguage>({
    ko: field.content.ko,
    en: field.content.en,
    ja: field.content.ja,
    cn: field.content.cn,
    tw: field.content.tw,
  });

  const { updateTenantCustomField } = useTenant();

  // 커스텀 필드 컨텐츠 등록
  const updateMultiLangContent = async () => {
    try {
      await updateTenantCustomField(field.id, {
        tenantId,
        customFieldId: field.customField.id,
        content: customFieldContent,
      });
      await fetchTenants();
      setOpenConfirmPopup(false);
      setIsEditMode(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          console.error('오류가 발생했습니다', error);
          setIsOpenAlert(true);
          setAlertMessage('오류가 발생했습니다.');
        }
      }
    }
  };

  const handleShowEditBtn = () => {
    if (isEditMode) return;
    setShowEditMode(true);
  };

  const handleClickEditBtn = () => {
    setShowEditMode(false);
    setIsEditMode(!isEditMode);
  };

  const handleChangeMultiLangInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setCustomFieldContent((prev: PatchLanguage) => ({ ...prev, [name]: value }));
  };

  return (
    <div
      className={`relative flex flex-col gap-2 ${showEditMode && 'bg-bg-f9'}`}
      onFocus={handleShowEditBtn}
      onMouseOver={handleShowEditBtn}
      onBlur={() => setShowEditMode(false)}
      onMouseLeave={() => setShowEditMode(false)}
    >
      {displayLanguageList.map((language, index) => (
        <div className='flex items-center w-full h-9' key={index}>
          <div className='w-28 flex gap-2.5'>
            <div className={`${FLEX_CENTER.row} bg-gray-99 text-white w-[22px] text-[10px]`}>{language.code}</div>
            <div className={`w-20 ${TEXT_OVERFLOW} text-xs text-gray-77 tracking-tighter`}>
              {field.customField.title[language.code.toLowerCase()] || '-'}
            </div>
          </div>
          <div className='flex-1 w-80'>
            {isEditMode ? (
              <input
                className='w-[80%] border border-gray-99 h-9 px-2'
                defaultValue={field.content[language.code.toLowerCase()]}
                placeholder={`사용자 정의 항목의 값을 입력해주세요.`}
                name={language.code.toLowerCase()}
                onChange={handleChangeMultiLangInput}
              />
            ) : (
              <div className={`text-sm text-[#222] tracking-tight ${TEXT_OVERFLOW}`}>
                {field.content[language.code.toLowerCase()] || '-'}
              </div>
            )}
          </div>
        </div>
      ))}

      {showEditMode && (
        <div className='absolute bottom-0 right-0' onClick={handleClickEditBtn}>
          <img src={ICON.Pencil} alt='pencil' />
        </div>
      )}

      {!showEditMode && isEditMode && (
        <div className='absolute bottom-0 right-0 flex gap-2'>
          <div onClick={() => setIsEditMode(false)}>
            <img src={ICON.Cancel} alt='cancel' />
          </div>

          <div onClick={() => setOpenConfirmPopup(true)}>
            <img src={ICON.Success} alt='success' />
          </div>
        </div>
      )}
      {openConfirmPopup && (
        <ConfirmPopup
          message={`해당 사용자 정의 항목의 값을 등록하시겠습니까?`}
          onClickOk={updateMultiLangContent}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}

      {isOpenAlert && <AlertPopup message={alertMessage} onClick={() => setIsOpenAlert(false)} />}
    </div>
  );
};
export default CustomEditableField;
