import { DraggableProvided } from 'react-beautiful-dnd';
import { PoiCategory } from 'types/feature/category/poiCategory';

type NoUserCategoryProps = {
  category: PoiCategory;
  draggableProvided: DraggableProvided;
  isAddedInList: boolean;
};

const NoChildrenCategory = ({
  category: { title, code, level },
  draggableProvided,
  isAddedInList,
}: NoUserCategoryProps) => {
  return (
    <div
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
      ref={draggableProvided.innerRef}
      className={`flex flex-col items-center justify-center cursor-pointer bg-gray-200 min-h-[5rem] rounded px-4 gap-1 text-center
      ${level === 1 ? 'w-1/3' : 'w-full'} 
      `}
    >
      <span className='text-sm font-semibold'>{title.ko}</span>
      <span className='text-sm'>{code.slice(0, 4)}</span>
    </div>
  );
};
export default NoChildrenCategory;
