import { useAxios } from 'api/useAxios';
import { LoginFormType } from 'components/feature/login/form/LoginForm';
import { Endpoint } from 'data/endPoint';
import { useNavigate } from 'react-router';
import { LoginResponse } from 'types/api/loginResponse';
import { ApiResponse } from 'types/api/response';
import { RoutePath } from 'types/common/paths';

const useAuth = () => {
  const { api } = useAxios();
  const navigate = useNavigate();

  const login = async (loginData: LoginFormType) => {
    const response: ApiResponse<LoginResponse> = await api.post(Endpoint.auth, loginData);

    if (response) {
      localStorage.setItem('KIOSK_ADMIN_LOGIN_STATUS', String(true));

      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        localStorage.setItem('KIOSK_ADMIN_ACCESS', response.data.accessToken);
      }

      navigate(RoutePath.workspaces);
    }
  };

  const logout = async () => {
    await api.post(Endpoint.logout);

    localStorage.removeItem('KIOSK_ADMIN_LOGIN_STATUS');

    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      localStorage.removeItem('KIOSK_ADMIN_ACCESS');
    }

    navigate(RoutePath.login);
  };

  return {
    login,
    logout,
  };
};
export default useAuth;
