import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import AlertPopup from 'components/common/popup/AlertPopup';
import Title from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { CUSTOM_FIELD_GUIDES } from 'data/guide/setting';
import useCustomFields from 'hooks/feature/custom-field/useCustomFields';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TenantField } from 'types/feature/setting/tenant/tenantField';
import AddCustomFieldPopup from './custom-field/popup/AddCustomFieldPopup';
import CustomFieldTable from './custom-field/table/CustomFieldTable';

const CustomFieldSettingContainer = () => {
  const [customFields, setCustomFields] = useState<TenantField[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const { getCustomFields } = useCustomFields();
  const { workspaceId } = useParams();

  useEffect(() => {
    fetchCustomFields();
  }, []);

  const fetchCustomFields = async () => {
    try {
      if (workspaceId) {
        const result = await getCustomFields(workspaceId);

        if (result) {
          setCustomFields(result.data);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          console.error('오류가 발생했습니다', error);
        }
      }
    }
  };

  // 새로운 커스텀 필드 추가
  const openNewCustomFieldPopup = () => {
    if (customFields.length >= 5) {
      return setShowAlert(true);
    }
    setOpenAddPopup(true);
  };

  return (
    <>
      <div className='flex flex-col justify-start w-full h-full gap-5'>
        <Title text='사용자 정의' titleType='h2' />

        <div className='flex justify-between'>
          <GuideDescription guides={CUSTOM_FIELD_GUIDES} />

          <Button width='w-44' height='h-12' color={ButtonColor.secondary} onClick={openNewCustomFieldPopup}>
            <div className='flex items-center gap-2'>
              <img src={ICON.ADD} alt='추가' />
              <div className='text-sm'>상세 항목 추가</div>
            </div>
          </Button>
        </div>
        <CustomFieldTable customFieldList={customFields.slice(0, 5)} getCustomFieldList={fetchCustomFields} />
      </div>
      {showAlert && (
        <AlertPopup message='더 이상 새로운 항목을 추가할 수 없습니다.' onClick={() => setShowAlert(false)} />
      )}
      {openAddPopup && (
        <AddCustomFieldPopup closePopup={() => setOpenAddPopup(false)} getCustomFieldList={fetchCustomFields} />
      )}
    </>
  );
};

export default CustomFieldSettingContainer;
