import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { LocationForm } from 'types/feature/menu/machine/machine';
import { changeZoom } from 'utils/map/mapControl';

type Props = {
  setMachinePlaceForm: Dispatch<SetStateAction<LocationForm>>;
};

const ZoomController = ({ setMachinePlaceForm }: Props) => {
  const { state } = useLocation();

  const onChangeMapZoom = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setMachinePlaceForm((prev: LocationForm) => ({
      ...prev,
      mapPosition: {
        ...prev.mapPosition,
        zoom: value,
      },
    }));

    changeZoom(Number(value));
  };

  return (
    <div className='p-5'>
      <div className='flex flex-col gap-2.5'>
        <FormControl
          name='Zoom'
          labelSize='none'
          control={
            <div className='flex items-center px-3 bg-gray-f9 h-6 rounded-full text-[11px]'>
              MIN {state?.point.mapMinZoom} MAX {state?.point.mapMaxZoom}
            </div>
          }
        />

        <FormControl
          name='줌 레벨'
          labelTextSize='h5_bold'
          control={
            <Input placeholder='zoom' type='text' height='h-[30px]' width='w-[150px]' onChange={onChangeMapZoom} />
          }
        />
      </div>
    </div>
  );
};

export default ZoomController;
