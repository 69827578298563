/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import useMouseOverlay from 'hooks/common/useTableAction';
import { TextType } from 'types/common/text';
import { ContentFile, FileType } from 'types/feature/content/contents';
import ThumbnailOverlay from './overlay/ThumbnailOverlay';

type ThumbnailTableRowCellProps = {
  title: string;
  files: ContentFile;
  fileType: FileType;
  titleStyle: TextType;
};

const ThumbnailTableRowCell = ({ files, fileType, title, titleStyle }: ThumbnailTableRowCellProps) => {
  const { overlayPosition, handleOverlayPosition, handleMouseLeave } = useMouseOverlay();

  return (
    <>
      <div
        onMouseOver={handleOverlayPosition}
        onMouseMove={handleOverlayPosition}
        onMouseLeave={handleMouseLeave}
        className={`${titleStyle} w-full px-2 overflow-hidden text-left cursor-default line-clamp-1`}
      >
        {title}
      </div>
      {!(overlayPosition.top === 0 && overlayPosition.left === 0) && (
        <ThumbnailOverlay overlayPosition={overlayPosition} contentFile={files} fileType={fileType} />
      )}
    </>
  );
};
export default ThumbnailTableRowCell;
