import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import AlertPopup from 'components/common/popup/AlertPopup';
import Title from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingStore from 'stores/loading';
import useWorkspaceStore from 'stores/workspace';
import { RegisterMachine } from 'types/feature/menu/machine/machine';
import { handle400Error } from 'utils/error/handle400Error';
import { drawKioskMarker } from 'utils/map/mapControl';

type Props = {
  registeredMachine: RegisterMachine;
  fetchWorkspaceAndMachine: () => Promise<void>;
};

const PositionEditController = ({ registeredMachine, fetchWorkspaceAndMachine }: Props) => {
  // hook
  const { patchMachine } = useMachine();
  const { machineId } = useParams();

  // state
  const [editMode, setEditMode] = useState(false);
  const [position, setPosition] = useState({
    positionX: registeredMachine.positionX,
    positionY: registeredMachine.positionY,
  });
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // ref
  const positionXRef = useRef<HTMLInputElement>(null);
  const positionYRef = useRef<HTMLInputElement>(null);

  // store
  const workspaceStore = useWorkspaceStore();
  const loadingStore = useLoadingStore();

  useEffect(() => {
    if (!loadingStore.loading) {
      drawKioskMachine(position.positionX, position.positionY);
    }
  }, [position.positionX, position.positionY, loadingStore.loading]);

  /**
   * 기기 위치 x 좌표를 업데이트 한다
   */
  const onChangeMachinePositionX = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setPosition((prev: any) => ({
      ...prev,
      positionX: value,
    }));
  };

  /**
   * 기기 위치 y 좌표를 업데이트 한다
   */
  const onChangeMachinePositionY = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPosition((prev: any) => ({
      ...prev,
      positionY: value,
    }));
  };

  const drawKioskMachine = async (posX: string, posY: string) => {
    await drawKioskMarker({ x: Number(posX), y: Number(posY) });
  };

  /**
   * 기기 위치 좌표를 업데이트 한다
   */
  const updatePosition = async () => {
    try {
      if (machineId) {
        if (isEmpty(positionXRef.current?.value) || isEmpty(positionYRef.current?.value)) {
          setOpenAlertPopup(true);
          setAlertMessage('입력된 값이 없습니다.');
          return;
        }

        await patchMachine(workspaceStore.workspaceMachineType, machineId, {
          positionX: position.positionX || registeredMachine.positionX,
          positionY: position.positionY || registeredMachine.positionY,
        });

        setEditMode(false);

        await fetchWorkspaceAndMachine();
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };
  return (
    <div className='flex flex-col gap-2.5 p-5 border-b'>
      <div className='flex justify-between h-6'>
        <Title text='기기 위치 좌표' titleType='h4_bold' textColor='text-gray-77' />
        {editMode ? (
          <div className='flex gap-2.5'>
            <div className='h-6' onClick={updatePosition}>
              <img className='h-full' src={ICON.Success} alt='edit' />
            </div>
            <div className='h-6' onClick={() => setEditMode(false)}>
              <img className='h-full' src={ICON.Cancel} alt='edit' />
            </div>
          </div>
        ) : (
          <div className='h-6' onClick={() => setEditMode(!editMode)}>
            <img className='h-full' src={ICON.Pencil} alt='edit' />
          </div>
        )}
      </div>
      <div className='flex gap-5 h-[30px]'>
        <FormControl
          labelTextSize='h5'
          labelSize={50}
          name='X'
          control={
            editMode ? (
              <Input
                inputRef={positionXRef}
                width='w-[60px]'
                height='h-[30px]'
                name='positionX'
                defaultValue={registeredMachine?.positionX}
                onChange={onChangeMachinePositionX}
              />
            ) : (
              <div className='flex items-center bg-bg-f9 w-[60px] h-[30px] px-2 text-h5 text-gray-99 '>
                {position?.positionX}
              </div>
            )
          }
        />
        <FormControl
          labelTextSize='h5'
          labelSize={50}
          name='Y'
          control={
            editMode ? (
              <Input
                inputRef={positionYRef}
                width='w-[60px]'
                height='h-[30px]'
                name='positionY'
                defaultValue={registeredMachine?.positionY}
                onChange={onChangeMachinePositionY}
              />
            ) : (
              <div className='flex items-center bg-bg-f9 w-[60px] h-[30px] px-2 text-h5 text-gray-99 '>
                {position?.positionY}
              </div>
            )
          }
        />
      </div>
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </div>
  );
};

export default PositionEditController;
