import React from 'react';

const ContentTableHeader = () => {
  return (
    <>
      <colgroup>
        <col width={80} />
        <col width={100} />
        <col width={120} />
        <col width={760} />
        <col width={120} />
        <col width={140} />
      </colgroup>

      <thead>
        <tr className='whitespace-nowrap text-gray-77 text-[13px] font-bold h-[40px]'>
          <th className='text-inherit'>No.</th>
          <th className='text-inherit'>구분</th>
          <th className='text-inherit'>파일 유형</th>
          <th className='text-left text-inherit'>타이틀</th>
          <th className='text-inherit'>최근 수정일</th>
          <th className='text-inherit'>삭제</th>
        </tr>
      </thead>
    </>
  );
};
export default ContentTableHeader;
