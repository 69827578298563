import React from 'react';

const OnGray = () => {
  return (
    <div className='w-[14px] h-[20px] flex items-center'>
      <div className='w-[14px] h-[14px] shrink-0 bg-[#CCC] rounded-full border border-[#CCC]' />
    </div>
  );
};
export default OnGray;
