import { TextType } from 'types/common/text';
import { BadgeProps, Badges } from './badge.type';

const Badge = ({ name, badgeType, height, cursorStyle = 'cursor-pointer' }: BadgeProps) => {
  return (
    <div className={`flex h-full`}>
      <div
        className={`flex ${cursorStyle} items-center justify-center font-medium text-center px-5 rounded-full ${
          Badges[badgeType]
        } ${height || 'h-[32px]'} ${TextType.h5} m-auto min-w-[60px]`}
      >
        {name}
      </div>
    </div>
  );
};

export default Badge;
