import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { ICON } from 'constants/icons';
import { GLOBAL_MENUS } from 'data/menu/globalMenus';
import useAuth from 'hooks/common/useAuth';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import useAccountStore from 'stores/account';
import { RoutePath } from 'types/common/paths';
import { TextType } from 'types/common/text';
import Menu from './menu/Menu';

const Navigation = () => {
  // hook
  const navigate = useNavigate();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();
  const { logout } = useAuth();

  // state
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');

  // store
  const accountStore = useAccountStore();

  const moveToMainPage = () => {
    navigateToReplacedPath(RoutePath.content, workspaceId);
  };

  const moveToWorkspacePage = () => {
    navigate(RoutePath.workspaces);
  };

  const moveToSetting = () => {
    navigateToReplacedPath(RoutePath.basicSetting, workspaceId);
  };

  // 로그아웃
  const handleLogout = async () => {
    try {
      await logout();

      setOpenConfirmPopup(false);
      accountStore.setAccount(null);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage('로그아웃을 할 수 없습니다.');
        }
      }
    }
  };

  const handleConfirmPopup = () => {
    setOpenConfirmPopup(!openConfirmPopup);
  };

  // 워크스페이스 조회
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);

      if (result) {
        setWorkspaceName(result.data.name);
      }
    }
  };

  useEffect(() => {
    fetchWorkspace();
  }, []);

  return (
    <>
      <div className='fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full bg-white'>
        <div className='flex items-center w-full h-20 max-w-[1400px] gap-10'>
          {/* 이름 */}
          <div
            onClick={moveToMainPage}
            className='flex items-center justify-start w-full gap-2.5 cursor-pointer text-gray-22 h-[28px]'
          >
            {/* 계정 */}
            {/* {accountStore.account?.name && (
              <span className={`${TextType.h2} shrink-0`}>{accountStore.account.name}</span>
            )} */}

            {/* 워크스페이스 */}
            <div className={`${TextType.h2} shrink-0`}>{workspaceName}</div>
          </div>

          {/* 메뉴들 */}
          <div className='flex justify-end w-full h-full text-zinc-500'>
            {GLOBAL_MENUS.map(menu => (
              <Menu key={menu.id} menu={menu} />
            ))}
          </div>

          <div className={`flex items-center gap-5 shrink-0 pr-5`}>
            {/* 워크스페이스 목록으로 이동하는 버튼 */}
            <div className='cursor-pointer w-[24px] h-[24px]' onClick={moveToWorkspacePage}>
              <img src={ICON.WORKSPACE} alt='워크스페이스' />
            </div>

            {/* 설정 */}
            <div className='w-[24px] h-[24px] cursor-pointer' onClick={moveToSetting}>
              <img src={ICON.Setting} alt='설정' />
            </div>

            {/* 로그아웃 */}
            <div className='w-[24px] h-[24px] cursor-pointer' onClick={handleConfirmPopup}>
              <img src={ICON.LOGOUT} alt='로그아웃' />
            </div>
          </div>
        </div>
        <div className='absolute left-0 w-screen border-b border-gray-22 top-20' />
      </div>

      {openConfirmPopup && (
        <ConfirmPopup message='로그아웃 하시겠습니까?' onClickOk={handleLogout} onClickCancel={handleConfirmPopup} />
      )}

      {openAlertPopup && (
        <AlertPopup
          message={alertMessage}
          onClick={() => {
            setOpenAlertPopup(false);
          }}
        />
      )}
    </>
  );
};
export default Navigation;
