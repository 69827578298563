import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';

const InternalErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className='absolute top-0 left-0 flex flex-col items-center justify-center w-screen h-screen gap-10 bg-primary-DA_Black'>
      <div className='flex flex-col items-center gap-4'>
        {/* 슬픈 얼굴 */}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1}
          stroke='#ebebeb'
          className='size-14'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z'
          />
        </svg>

        <div className='flex flex-col gap-1'>
          <span className='text-lg text-center text-gray-eb'>서버에 일시적인 오류가 발생했습니다</span>
          <span className='text-lg text-center text-gray-eb'> 잠시 후 다시 시도해주세요</span>
        </div>
      </div>

      <button
        type='button'
        className='w-[280px] h-14 border border-gray-eb text-gray-eb text-base'
        onClick={() => {
          navigate(RoutePath.internalDashBoard);
        }}
      >
        다시 시도하기
      </button>
    </div>
  );
};

export default InternalErrorPage;
