import React from 'react';

type Props = {
  key?: string;
  acceptType: string;
  name?: string;
  disabled?: boolean;
  onChangeFile: (file: File) => void;
  id?: string;
  fileRef: React.RefObject<HTMLInputElement>;
};

const FileInput = ({ key, acceptType, name, disabled = false, fileRef, id, onChangeFile }: Props) => {
  return (
    <input
      key={key}
      accept={acceptType}
      name={name}
      disabled={disabled}
      ref={fileRef}
      type='file'
      id={id}
      className='hidden border-0 file:hidden'
      onChange={e => {
        const { files } = e.target;
        if (files) {
          onChangeFile(files[0]);
        }
      }}
    />
  );
};
export default FileInput;
