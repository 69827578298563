import React, { useState } from 'react';

export type OverlayPosition = {
  left: number;
  top: number;
};

const useMouseOverlay = () => {
  const [overlayPosition, setOverlayPosition] = useState<OverlayPosition>({ top: 0, left: 0 });

  const handleOverlayPosition = (e: React.MouseEvent<HTMLDivElement>) => {
    const { clientX, clientY } = e;

    setOverlayPosition({
      left: clientX,
      top: clientY,
    });
  };

  const handleMouseLeave = () => {
    setOverlayPosition({ top: 0, left: 0 });
  };

  return { overlayPosition, handleOverlayPosition, handleMouseLeave };
};
export default useMouseOverlay;
