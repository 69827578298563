import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <div className='absolute top-0 left-0 flex items-center justify-center w-screen h-screen opacity-70 bg-slate-400'>
      <span className='loader' />
    </div>
  );
};
export default LoadingSpinner;
