import Off from 'components/common/badge/status/Off';
import On from 'components/common/badge/status/On';
import OnGray from 'components/common/badge/status/OnGray';
import { Point } from 'types/feature/workspace/workspace';
import { ConnectedStatus } from 'types/internal/dashboard/internalDashboardMachine';

type Props = {
  connectedStatus: ConnectedStatus;
  activePoint: Point;
};

const InternalDashboardMachineStatus = (props: Props) => {
  return (
    <div className='flex w-max h-[90px] px-0 py-[25px] items-center gap-[20px]'>
      <div className='text-[#9EA2B7] font-pretendard text-[24px] font-bold leading-[40px] tracking-[-0.48px]'>
        {props.activePoint.name.ko}
      </div>
      <div className='flex items-center justify-center gap-5 rounded-[1.25rem] bg-[#1E1F24] px-5 h-[40px]'>
        <div className='flex items-center gap-3'>
          {/* ON */}
          <div>
            <OnGray />
          </div>
          {/* 기기 수 */}
          <h2 className='text-[#CCC] text-[1.25rem] font-bold leading-[1.75rem] tracking-[-0.025rem]'>
            {props.connectedStatus?.connected}
          </h2>
        </div>
        <div className='flex items-center gap-3'>
          {/* OFF */}
          <div>
            <Off />
          </div>
          {/* 기기 수 */}
          <h2 className='text-[#555B73] text-[1.25rem] font-bold leading-[1.75rem] tracking-[-0.025rem]'>
            {props.connectedStatus?.disconnected}
          </h2>
        </div>
      </div>
    </div>
  );
};
export default InternalDashboardMachineStatus;
