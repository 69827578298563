import { MouseEvent } from 'react';

export type ButtonProps = {
  text?: string;
  width?: string;
  height?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  color: ButtonColor;
  rounded?: string;
  size?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  textStyle?: string;
  children?: React.ReactElement;
  transition?: ButtonTransition;
};

export type ButtonSize = keyof typeof ButtonSizeConstants;

export const ButtonSizeConstants = {
  default: 'px-5 h-12 min-w-24',
  add: 'px-5 h-10',
  rounded: 'h-6 px-3 rounded',
  filter: 'h-9 w-20',
  full: 'w-full h-11',

  80: 'w-20 h-9',
  100: 'w-[100px] h-9',
  120: 'w-[120px] h-[52px]',
};

export enum ButtonColor {
  // 어두운 남색 버튼
  primary = 'border bg-button-primary-normal border-button-primary-border-normal text-gray-ea hover:bg-button-primary-hover hover:border-button-primary-border-hover active:bg-button-primary-press active:border-button-primary-border-press',
  primary_disable = 'border bg-button-primary-disable border-button-primary-border-disable text-gray-ea bg-gray-cc border-gray-cc',

  // 투명 버튼
  secondary = 'border border-gray-22 bg-button-secondary-normal border-button-secondary-border-normal text-gray-44 hover:bg-button-secondary-hover hover:border-button-secondary-border-hover hover:font-bold active:font-bold active:bg-button-secondary-press active:border-button-secondary-border-press ',
  secondary_disable = 'border bg-button-secondary-disable border-button-secondary-border-disable text-gray-cc',

  // 포인트 색(파란색) 버튼
  point = 'border bg-primary-DA_Blue_light border-button-point-normal text-gray-ea hover:bg-button-point-hover hover:border-button-point-border-hover active:bg-button-point-press active:border-button-point-border-press',
  point_disable = 'border bg-button-point-disable border-button-point-disable text-gray-ea',

  // 회색 버튼 필요 시 추가
  // gray = '',
  // gray_disable = '',

  red = 'border border-primary-DA_Red text-primary-DA_Red',

  delete = 'border border-gray-99 text-gray-99 bg-white',
}

export enum ButtonTransition {
  bright = 'hover:brightness-125 transition-all delay-50',
  scale = 'hover:scale-105 transition-all delay-50',
  brightAndScale = 'hover:brightness-125 hover:scale-105 transition-all delay-50',
  none = '',
}
