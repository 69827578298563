import FormControl from 'components/common/form-control/FormControl';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import React, { Dispatch, SetStateAction } from 'react';
import { Floor } from 'types/feature/floor/floor';
import { LocationForm } from 'types/feature/menu/machine/machine';
import { changeFloor } from 'utils/map/mapControl';

type Props = {
  setMachinePlaceForm: Dispatch<SetStateAction<LocationForm>>;
  floorList: Floor[];
  selectedFloorId: string;
  setSelectedFloorId: (floorId: string) => void;
};

const FloorController = ({ selectedFloorId, setSelectedFloorId, setMachinePlaceForm, floorList }: Props) => {
  /**
   * 층을 변경한다
   */
  const onChangeFloor = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    if (!setSelectedFloorId) return;
    await changeFloor(value);

    const findFloor = floorList.find(floor => floor.id === value);
    if (findFloor) {
      setSelectedFloorId(findFloor.id);
    }

    setMachinePlaceForm((prev: LocationForm) => ({
      ...prev,
      floorId: value,
    }));
  };

  return (
    <div className='flex w-full p-5 border-b'>
      <FormControl
        name='층'
        control={
          <CommonSelectBox
            width='w-[100px]'
            height='h-[30px]'
            selected={selectedFloorId || ''}
            options={
              floorList.map(floor => ({
                id: floor.id,
                value: floor.mainName || floor.name.ko,
              })) || []
            }
            onChange={onChangeFloor}
          />
        }
      />
    </div>
  );
};

export default FloorController;
