/* eslint-disable @typescript-eslint/no-shadow */
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  // workspaceId를 replace한 path를 return
  const replaceWorkspaceId = (path: RoutePath, workspaceId: string | undefined): RoutePath | string => {
    if (workspaceId) {
      return path.replace(':workspaceId', workspaceId);
    }

    return path;
  };

  // replace한 path로 navigate(workspaceId는 알아서 replace 해줌)
  const navigateToReplacedPath = (
    path: RoutePath,
    workspaceId: string | undefined,
    searchValue?: string,
    replaceValue?: string,
  ) => {
    if (!workspaceId) return;

    // todo 추후 pointId도 받아서 replace 할 수 있도록 변경
    const replacedPath = replaceWorkspaceId(path, workspaceId);

    // replace할 value가 workspaceId 말고 더 있을 경우
    if (searchValue && replaceValue) {
      navigate(replacedPath.replace(searchValue, replaceValue));
      return;
    }

    // replace할 value가 workspaceId 뿐일 경우
    navigate(replacedPath);
  };

  // 필요한 id 들을 모두 replace 한 경로로 페이지 이동
  const customNavigate = (
    routePath: RoutePath,
    pointId?: string,
    customPageId?: string,
    customBoardId?: string,
    option?: NavigateOptions,
  ): void => {
    const navigatePath = routePath
      .replace(':workspaceId', workspaceId || '')
      .replace(':pointId', pointId || '')
      .replace(':customPageId', customPageId || '')
      .replace(':customBoardId', customBoardId || '');

    if (option) {
      navigate(navigatePath, option);
    } else {
      navigate(navigatePath);
    }
  };

  return {
    navigateToReplacedPath,
    replaceWorkspaceId,
    customNavigate,
  };
};

export default useCustomNavigate;
