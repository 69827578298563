import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import Title from 'components/common/title/Title';
import { MAKE_ACCOUNT_GUIDE, MAKE_ACCOUNT_HELPER } from 'data/guide/makeAccount';
import { PASSWORD_REGEX, USER_REGEX } from 'data/regex';
import useMachine from 'hooks/feature/machine/useMachine';
import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from 'react';
import useAccountStore from 'stores/account';
import ValidateInput from '../../input/ValidateInput';

type Props = {
  setMachineBasicForm: Dispatch<SetStateAction<any>>;
};

const WebMachineAddForm = ({ setMachineBasicForm }: Props) => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isSamePassword, setIsSamePassWord] = useState(true);
  const [usernameMessage, setUsernameMessage] = useState('');
  const { setCheckDuplicate, setDuplicateUsername } = useAccountStore();
  const { checkDuplicateUsername } = useMachine();

  const handleChangeAccount = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMachineBasicForm((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateSamePassword = (e: ChangeEvent<HTMLInputElement>) => {
    if (passwordRef.current) {
      const originPassword = passwordRef.current.value;
      const { value } = e.target;
      if (value === '') {
        return setIsSamePassWord(true);
      }
      if (originPassword !== value) {
        return setIsSamePassWord(false);
      }

      if (originPassword === value) {
        setMachineBasicForm((prev: any) => ({
          ...prev,
          password: window.btoa(value),
        }));
        return setIsSamePassWord(true);
      }
    }
  };

  const handleCheckDuplicateUsername = async () => {
    if (usernameRef.current) {
      const result = await checkDuplicateUsername(usernameRef.current.value);
      if (result.data) {
        setCheckDuplicate(true);
        // 중복 검사 여부
        const isDuplicate = result.data.duplicated;

        if (isDuplicate) {
          setDuplicateUsername(true);
          setUsernameMessage('중복된 아이디가 존재합니다.');
        } else {
          setDuplicateUsername(false);
          setUsernameMessage('사용 가능한 아이디 입니다.');
        }
      }
    }
  };
  return (
    <div className='flex flex-col gap-5'>
      <Title text='WEB 접속정보' titleType='h3_bold' />
      <main className='flex w-full gap-3'>
        <section className='flex flex-col w-full gap-3'>
          <FormControl
            name='아이디'
            control={
              <div className='flex gap-2.5'>
                <ValidateInput
                  inputRef={usernameRef}
                  name='username'
                  placeholder='아이디 입력'
                  regexCheck={USER_REGEX}
                  guide={MAKE_ACCOUNT_GUIDE.id}
                  type='text'
                  onChange={handleChangeAccount}
                  regexGuide={MAKE_ACCOUNT_HELPER.id}
                />
                <div>
                  <Button
                    text='중복 체크'
                    height='h-9'
                    color={ButtonColor.secondary}
                    onClick={handleCheckDuplicateUsername}
                  />

                  <div className='text-h5'>{usernameMessage}</div>
                </div>
              </div>
            }
          />
          <FormControl
            name='비밀번호'
            control={
              <ValidateInput
                name='username'
                inputRef={passwordRef}
                placeholder='비밀번호 입력'
                regexCheck={PASSWORD_REGEX}
                guide={MAKE_ACCOUNT_GUIDE.password}
                type='password'
                onChange={() => {}}
                regexGuide={MAKE_ACCOUNT_HELPER.password}
              />
            }
          />
          <FormControl
            name='비밀번호 확인'
            control={
              <div className='flex flex-col gap-[6px]'>
                <Input
                  width='w-[330px]'
                  height='h-10'
                  type='password'
                  placeholder='비밀번호 재입력'
                  showWarning={!isSamePassword}
                  onChange={validateSamePassword}
                />
                {!isSamePassword && (
                  <div className='text-[11px] text-primary-DA_Red'>비밀번호가 일치하지 않습니다.</div>
                )}
              </div>
            }
          />
        </section>
      </main>
    </div>
  );
};

export default WebMachineAddForm;
