import Next from 'assets/base/Next';
import Previous from 'assets/base/Previous';

export type CalendarHeaderProps = {
  decreaseMonth: () => void;
  increaseMonth: () => void;
  date: Date;
};

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const CalendarHeader = ({ decreaseMonth, increaseMonth, date }: CalendarHeaderProps) => {
  return (
    <div className='flex items-center justify-around w-full gap-10 shrink-0'>
      <span className='font-sans text-xl'>
        {MONTHS[date.getMonth()]} {date.getFullYear()}
      </span>

      <div className='flex gap-6 cursor-pointer'>
        <div onClick={decreaseMonth}>
          <Previous />
        </div>

        <div onClick={increaseMonth}>
          <Next />
        </div>
      </div>
    </div>
  );
};
export default CalendarHeader;
