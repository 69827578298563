import Title from 'components/common/title/Title';
import { Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { ControlMode } from 'types/common';
import { Floor } from 'types/feature/floor/floor';
import { MachineState, RegisterMachine } from 'types/feature/menu/machine/machine';
import { ScreenType } from 'types/feature/screensaver/getScreensaver';
import MachineMapContainer from '../../add/map/MachineMapContainer';
import CenterEditController from '../../add/map/controller/center/CenterEditController';
import DescriptionEditController from '../../add/map/controller/description/DescriptionEditController';
import FloorEditController from '../../add/map/controller/floor/FloorEditController';
import MachineTypeEditController from '../../add/map/controller/machine-type/MachineTypeEditController';
import PositionEditController from '../../add/map/controller/position/PositionEditController';
import RotationEditController from '../../add/map/controller/rotation/RotationEditController';
import ZoomEditController from '../../add/map/controller/zoom/ZoomEditController';

type Props = {
  mode?: ControlMode;
  screenType: string;
  floorList: Floor[];
  registeredMachine: RegisterMachine;
  fetchWorkspaceAndMachine: () => Promise<void>;
  setScreenType?: Dispatch<SetStateAction<string>>;
  machineFloor?: Floor;
};

const MachineDetailContainer = ({
  registeredMachine,
  screenType,
  setScreenType,
  floorList,
  fetchWorkspaceAndMachine,
  machineFloor,
}: Props) => {
  const { state } = useLocation();

  const editProps = {
    fetchWorkspaceAndMachine,
    setScreenType,
    registeredMachine,
    machineFloor,
  };

  return (
    <div className='flex flex-col gap-5 py-10 border-b border-gray-cc'>
      <div className='flex items-center gap-2.5'>
        <Title text='기기위치 정보' titleType='h3_bold' />
        <div className='text-xs'>
          현재 기기가 위치한 포인트는 <b>{state.point?.name.ko || ''}</b>입니다.
        </div>
      </div>
      {registeredMachine?.status === MachineState.EXPIRED && (
        <Title text='폐기된 기기는 수정할 수 없습니다.' titleType='h4' />
      )}

      <section className='h-[666px] flex border relative'>
        {registeredMachine?.status === MachineState.EXPIRED && (
          <div className='absolute z-20 w-full h-full opacity-25 bg-gray-cc' />
        )}

        {/* 지도 */}
        <MachineMapContainer
          mode='edit'
          floorList={floorList}
          registeredMachine={registeredMachine}
          screenType={screenType as ScreenType}
        />

        {/* 수정 */}
        <div className='w-full border-l'>
          <FloorEditController {...editProps} />
          <MachineTypeEditController {...editProps} />
          <PositionEditController {...editProps} />
          <DescriptionEditController {...editProps} />
          <CenterEditController {...editProps} />
          <RotationEditController {...editProps} />
          <ZoomEditController {...editProps} />
        </div>
      </section>
    </div>
  );
};

export default MachineDetailContainer;
