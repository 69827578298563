/* eslint-disable react/jsx-no-useless-fragment */
import Badge from 'components/common/badge/Badge';
import React from 'react';

type DisplayBadgeProps = {
  display: boolean;
};

const DisplayBadge = ({ display }: DisplayBadgeProps) => {
  return (
    <Badge cursorStyle='cursor-default' name={display ? '사용' : '미사용'} badgeType={display ? 'SUCCESS' : 'NONE'} />
  );
};
export default DisplayBadge;
