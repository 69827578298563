import { IndexType } from 'types/common';
import { DisplayType } from 'types/common/display';
import { MultiLangs } from '../language/language';

export const CustomPageTypeKo: IndexType = {
  LIST: '목록형',
  CARD: '카드형',
} as const;

export type CustomPageType = (typeof CustomPageTypeKo)[keyof typeof CustomPageTypeKo];

export type CustomBoardContent = {
  id: string;
  title: MultiLangs;
  displayType: DisplayType;
  startDate: string;
  endDate: string;
  mainImage: Image;
  contentImage: Image;
  customPageId: string;
  createdDate: string;
  updatedDate: string;
  displayStatus: 'DISPLAY' | 'WAIT' | 'CLOSE' | 'NONE';
};

export type CustomPage = {
  id: string;
  type: CustomPageType;
  contents: CustomBoardContent[];
};

export type PostCustomPage = {
  title: MultiLangs;
  customPageId: string;
  displayType: DisplayType;
  startDate: string;
  endDate: string;
  mainImage: PostImage;
  contentImage: PostImage;
};

export type UpdateCustomPage = {
  title?: MultiLangs;
  displayType?: string;
  startDate?: string;
  endDate?: string;
  mainImage?: PostImage;
  contentImage?: PostImage;
};

type Image = {
  uri: string;
  originalName: string;
  size: number;
  width: number;
  height: number;
};

export type PostImage = {
  name: string;
  originalName: string;
  delete: boolean;
};

export enum IMAGE {
  MAIN_IMAGE = 'mainImage',
  CONTENT_IMAGE = 'contentImage',
}

export const CustomUploadFileType: IndexType = {
  mainImage: 'CUSTOM_BOARD_IMAGE',
  contentImage: 'CUSTOM_BOARD_CONTENT_IMAGE',
};
