import { IndexType } from 'types/common';

export enum FileType {
  image = 'IMAGE',
  video = 'VIDEO',
}

export const FILE_TYPES: IndexType = {
  IMAGE: '이미지',
  VIDEO: '영상',
};

export type CommonFile = {
  uri: string;
  originalName: string;
  size: number;
  width: number;
  height: number;
};
