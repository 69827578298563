// title
export const TITLE_CLASS = 'flex justify-start w-full text-lg  font-extrabold cursor-default';

// container
export const FIELD_CONTAINER = 'flex flex-col  w-full cursor-default';

// field
export const EDITABLE_FIELD_CLASS = 'py-2 flex gap-2 items-center hover:bg-slate-100 rounded';
export const UNEDITABLE_FIELD_CLASS = 'flex items-center';

// key
export const FIELD_KEY_CLASS = 'w-24 h-full text-[13px] font-bold whitespace-nowrap min-w-max text-[#777]';

// value
export const EDITABLE_FIELD_VALUE_CLASS = 'text-sm flex-2 grow line-clamp-1';
export const UNEDITABLE_FIELD_VALUE_CLASS = 'text-sm flex-2 grow line-clamp-1';

// padding
export const FIELD_PADDING = 'pt-10 pb-10 pl-10 pr-10';

// text ...
export const TEXT_OVERFLOW = 'whitespace-nowrap text-ellipsis overflow-hidden';

export const TEXT_VALUE = 'text-h4';
