import { useState } from 'react';
import Badge from '../../../common/badge/Badge';

type Props = {
  iconUsed: boolean;
  handleClickIconUsed: (used: boolean) => void;
};

const ToggleIconUsed = ({ iconUsed, handleClickIconUsed }: Props) => {
  const [toggleIconUsed, setToggleIconUsed] = useState(iconUsed);

  const handleToggleIconUsed = () => {
    const selectedIconUsed = !toggleIconUsed;
    setToggleIconUsed(selectedIconUsed);
    handleClickIconUsed(selectedIconUsed);
  };

  return (
    <div
      className={`
      w-20 
      flex justify-start items-center
      `}
      onClick={handleToggleIconUsed}
    >
      <Badge height='h-9' name={toggleIconUsed ? '사용' : '미사용'} badgeType={toggleIconUsed ? 'USED' : 'NONE'} />
    </div>
  );
};

export default ToggleIconUsed;
