// 값이 0 || undefined || NaN 등 falsy한 값일 경우
export const throwErrorIfFalsy = (value: number) => {
  if (!value) {
    throw new Error();
  }
};

// 값이 음수일 경우
export const throwErrorIfNegative = (value: number) => {
  if (value < 0) {
    throw new Error();
  }
};
