export enum ModalType {
  alert = 'alert',
  succeed = 'succeed',
  fail = 'fail',
  confirm = 'confirm',
}

export type ModalProps = {
  openModal?: (type: ModalType) => void;
  closeModal: () => void;
  text?: string;
};

export type ModalContainerProps = {
  message?: string | undefined;
  modalType: ModalType;
} & ModalProps;
