import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import FormControl from 'components/common/form-control/FormControl';
import Label from 'components/common/label/Label';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import CustomContentMultiLangEditInputs from 'components/feature/custom-page/add/multi-lang/CustomContentMultiLangEditInputs';
import { IMAGES } from 'constants/images';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useCustomPage from 'hooks/feature/custom-page/useCustomPage';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { CustomBoardContent } from 'types/feature/custom-page/customPage';
import { MultiLangs } from 'types/feature/language/language';
import UpdateCustomDate from './update/UpdateCustomDate';
import UpdateCustomFile from './update/UpdateCustomFile';

const CustomDetailContainer = () => {
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId, customContentId } = useParams();

  const [isEdit, setIsEdit] = useState(false);
  const [customBoard, setCustomBoard] = useState<CustomBoardContent>();
  const [customBoardTitle, setCustomBoardTitle] = useState<MultiLangs>({ ko: '', en: '', ja: '', cn: '', tw: '' });

  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] = useState(false);
  const [openConfirmTitlePopup, setOpenConfirmTitlePopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { deleteCustomBoard, getCustomBoard, updateCustomBoard } = useCustomPage();

  const moveToCustomList = () => {
    navigateToReplacedPath(RoutePath.customPage, workspaceId, ':customPageId', customBoard?.customPageId);
  };

  useEffect(() => {
    fetchCustomBoard();
  }, []);

  // 삭제
  const deleteCustomPageItem = async () => {
    try {
      if (customContentId) {
        await deleteCustomBoard(customContentId);
        moveToCustomList();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status && error.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage(error.message);
          setOpenConfirmDeletePopup(false);
        }
      }
    }
  };

  // 커스텀 게시판 정보 가져오기
  const fetchCustomBoard = async () => {
    try {
      if (customContentId) {
        const result = await getCustomBoard(customContentId);
        if (result) {
          const currentCustomBoard = result.data;
          setCustomBoard(currentCustomBoard);
          setCustomBoardTitle({ ...currentCustomBoard.title });
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setOpenAlertPopup(true);
        setAlertMessage(error.message);
      }
    }
  };

  // 타이틀 저장 버튼 클릭시
  const handleClickSaveBtn = () => {
    if (validTitle()) {
      setOpenConfirmTitlePopup(true);
    }
  };

  // 타이틀 변경
  const updateCustomBoardTitle = async () => {
    try {
      if (customContentId) {
        await updateCustomBoard(customContentId, { title: customBoardTitle });
        setOpenConfirmTitlePopup(false);
        setIsEdit(false);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setOpenAlertPopup(true);
        setAlertMessage('타이틀을 수정하는 중에 문제가 발생하였습니다. \n다시 시도해주세요.');
      }
    }
  };

  const cancelUpdateTitle = () => {
    if (customBoard) {
      setIsEdit(false);
      setCustomBoardTitle({ ...customBoard?.title });
    }
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCustomBoardTitle((prev: MultiLangs) => ({
      ...prev,
      [id as string]: value,
    }));
  };

  const validTitle = () => {
    // TODO: valid 수정
    if (isEmpty(customBoardTitle)) {
      setOpenAlertPopup(true);
      setOpenConfirmTitlePopup(false);
      setAlertMessage('타이틀을 입력해주세요.');
      return false;
    }

    return true;
  };

  // alert 닫기
  const handleAlert = () => {
    setOpenAlertPopup(false);
    setOpenConfirmTitlePopup(false);
    setOpenConfirmDeletePopup(false);
  };

  return (
    <div className='w-full pb-20'>
      {customBoard && (
        <div className='flex flex-col w-full gap-8'>
          <Border />

          <div className='flex items-start'>
            <div className='flex items-start justify-start flex-1'>
              <div className='flex items-center h-9'>
                <Label name='타이틀' labelTextSize='h4_bold' />
              </div>
              <div className={`flex items-end w-[510px] gap-2.5`}>
                <CustomContentMultiLangEditInputs
                  handleChangeTitle={handleChangeTitle}
                  isEdit={isEdit}
                  defaultValue={customBoardTitle}
                />

                {/* buttons */}
                {isEdit ? (
                  <div className='flex gap-2'>
                    <div onClick={cancelUpdateTitle} className='w-[20px] cursor-pointer'>
                      <img src={IMAGES.CANCEL} alt='취소' />
                    </div>
                    <div onClick={handleClickSaveBtn} className='w-[20px] cursor-pointer'>
                      <img src={IMAGES.SUCCESS} alt='저장' />
                    </div>
                  </div>
                ) : (
                  <div onClick={() => setIsEdit(!isEdit)} className='cursor-pointer pr-7'>
                    <img src={IMAGES.PENCIL} alt='수정' />
                  </div>
                )}
              </div>
            </div>

            <div className='flex-1'>
              <FormControl
                name='기간'
                labelTextSize='h4_bold'
                required
                control={<UpdateCustomDate customBoard={customBoard} />}
              />
            </div>
          </div>

          <Border />

          <div className='flex'>
            <FlexFormControl
              name='목록 이미지'
              required
              labelTextSize='h4_bold'
              control={
                <div className='flex flex-col gap-3'>
                  <UpdateCustomFile
                    getCustomBoards={fetchCustomBoard}
                    customBoard={customBoard}
                    uploadName='mainImage'
                  />
                  <div className='w-[25rem] h-[30rem]'>
                    <img className='h-full' src={customBoard?.mainImage.uri} alt='main' />
                  </div>
                </div>
              }
            />
            <FlexFormControl
              name='상세 이미지'
              required
              labelTextSize='h4_bold'
              control={
                <div className='flex flex-col gap-3'>
                  <UpdateCustomFile
                    getCustomBoards={fetchCustomBoard}
                    customBoard={customBoard}
                    uploadName='contentImage'
                  />
                  <div className='w-[25rem] h-[30rem]'>
                    <img className='h-full' src={customBoard?.contentImage.uri} alt='content' />
                  </div>
                </div>
              }
            />
          </div>

          <Border />

          <div className='flex justify-center gap-3'>
            <Button text='목록' color={ButtonColor.secondary} width='w-20' onClick={moveToCustomList} />
            <Button
              text='삭제'
              color={ButtonColor.primary}
              width='w-20'
              onClick={() => setOpenConfirmDeletePopup(true)}
            />
          </div>
          {openConfirmDeletePopup && (
            <ConfirmPopup
              message='삭제 하시겠습니까?'
              onClickCancel={() => setOpenConfirmDeletePopup(false)}
              onClickOk={deleteCustomPageItem}
            />
          )}

          {openConfirmTitlePopup && (
            <ConfirmPopup
              message='수정 하시겠습니까?'
              onClickCancel={() => setOpenConfirmTitlePopup(false)}
              onClickOk={updateCustomBoardTitle}
            />
          )}
        </div>
      )}
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={handleAlert} />}
    </div>
  );
};

export default CustomDetailContainer;
