import AlertPopup from 'components/common/popup/AlertPopup';
import useUserAccount from 'hooks/common/useUserAccount';
import useCategory from 'hooks/feature/category/useCategory';
import { Fragment, useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PoiCategory } from 'types/feature/category/poiCategory';
import NoChildrenCategory from './no-children/NoChildrenCategory';
import UserCategoryItem from './user-category-item/UserCategoryItem';

type UserCategoriesProps = {
  poiCategoryCodes: string[];
  pointId: string | undefined;
};

const UserCategories = ({ poiCategoryCodes, pointId }: UserCategoriesProps) => {
  const { getUserCategories } = useCategory();
  const { getAccount } = useUserAccount();

  const [categories, setCategories] = useState<PoiCategory[] | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  // 유저 정보
  const fetchAccount = async () => {
    try {
      const result = await getAccount();

      if (result) {
        setUserName(result.name);
      }
    } catch (error) {
      setAlertMessage('사용자 정보를 불러올 수 없습니다.');
      setIsOpenAlert(true);
    }
  };

  // 유저가 가지고 있는 poi category (지도에서 가져온 카테고리)
  const fetchPoiCategories = async () => {
    try {
      if (pointId) {
        const resultOfCategories = await getUserCategories(pointId);

        if (resultOfCategories) {
          setCategories(resultOfCategories);
        }
      }
    } catch (error) {
      setAlertMessage('카테고리를 불러올 수 없습니다.');
      setIsOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchAccount();
    fetchPoiCategories();
  }, [pointId]);

  return (
    <>
      <Droppable droppableId='poi-categories' isDropDisabled>
        {provided => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className='flex flex-col flex-1 h-full gap-3 p-6 pr-3 cursor-default bg-gray-50 shrink-0'
            >
              <div className='text-xl'>
                <span className='font-medium'>{userName}</span> 님이 가지고 있는 카테고리
              </div>

              <div className='flex flex-col w-full gap-4 pr-3 overflow-y-auto'>
                {categories
                  ?.sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((category, idx) => {
                    const isAddedInList = poiCategoryCodes?.includes(category.code);

                    return category.children.length > 0 ? (
                      <Fragment key={category.code}>
                        <UserCategoryItem poiCategoryCodes={poiCategoryCodes} category={category} />
                      </Fragment>
                    ) : (
                      !isAddedInList && (
                        <Droppable isDropDisabled droppableId='user-categories-wrapper' key={category.code}>
                          {droppableProvided => (
                            <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                              <Draggable draggableId={`${category.code}`} index={idx} key={`${category.code}`}>
                                {draggableProvided => (
                                  <NoChildrenCategory
                                    isAddedInList={isAddedInList}
                                    draggableProvided={draggableProvided}
                                    category={category}
                                  />
                                )}
                              </Draggable>
                            </div>
                          )}
                        </Droppable>
                      )
                    );
                  })}
              </div>
            </div>
          );
        }}
      </Droppable>

      {isOpenAlert && <AlertPopup message={alertMessage} onClick={() => setIsOpenAlert(false)} />}
    </>
  );
};
export default UserCategories;
