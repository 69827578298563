import { AxiosError } from 'axios';
import GuideDescription from 'components/common/description/GuideDescription';
import FileDropZone from 'components/common/file/drop-zone/FileDropZone';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { LOGO_GUIDES } from 'data/guide/setting';
import useFileUpload from 'hooks/common/useFileUpload';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FileType } from 'types/common/file/file';
import { FileUploadType } from 'types/common/file/fileUpload';
import { Point } from 'types/feature/workspace/workspace';
import { returnFileAccept } from 'utils/file/extension/returnFileAccept';
import { returnFileMaxSize } from 'utils/file/size/returnFileSize';

type Props = {
  setPointForm: Dispatch<SetStateAction<Point>>;
  point?: Point;
};
const PointLogo = ({ setPointForm, point }: Props) => {
  const [fileName, setFileName] = useState('');
  const [fileUri, setFileUri] = useState('');

  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [fileLoading, setFileLoading] = useState(false);

  const { uploadFile } = useFileUpload();

  // 파일 삭제
  const deleteFile = () => {
    setFileName('');
    setFileUri('');
    setPointForm((prev: Point) => ({
      ...prev,
      logo: {
        name: '',
        originalName: '',
      },
    }));
  };

  // 파일 업로드 실패 시
  const openFileErrorAlert = (message: string) => {
    setAlertMessage(message);
    setOpenAlertPopup(true);
    setFileLoading(false);
  };

  const onChangeFile = async (file: File) => {
    try {
      if (file) {
        setFileLoading(true);

        const response = await uploadFile(file, FileUploadType.LOGO_IMAGE);

        if (response) {
          setFileUri(response.fileUri);
          setFileName(response.fileName);

          setPointForm((prev: Point) => ({
            ...prev,
            logo: {
              name: response.fileName,
              originalName: file.name,
              uri: response.fileUri,
            },
          }));

          setFileLoading(false);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage('오류가 발생했습니다.');
          setFileLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    setFileUri(point?.logo?.uri || '');
    setFileName(point?.logo?.originalName || '');
  }, [point?.logo]);
  return (
    <>
      <div className='flex flex-col gap-2.5'>
        {/* 파일 업로드 */}
        <FileDropZone
          maxSize={returnFileMaxSize(FileUploadType.LOGO_IMAGE)}
          uploadFile={onChangeFile}
          fileUri={fileUri}
          fileType={FileType.image}
          deleteFile={deleteFile}
          accept={returnFileAccept(FileUploadType.LOGO_IMAGE)}
          openFileErrorAlert={openFileErrorAlert}
          loading={fileLoading}
          fileName={fileName}
          width='w-[360px]'
          height='h-[120px]'
          isLogo
        />

        {/* 가이드 */}
        <GuideDescription guides={LOGO_GUIDES} />
      </div>

      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
      {openConfirmPopup && (
        <ConfirmPopup
          message={'로고를 삭제 하시겠습니까?'}
          onClickOk={() => {}}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}
    </>
  );
};

export default PointLogo;
