import React from 'react';
import { FontType } from 'types/common/text';
import Label, { LabelSizeConstant } from '../label/Label';

type FormControlProps = {
  name: string;
  disabled?: boolean;
  required?: boolean;
  control: React.ReactElement;
  direction?: 'column' | 'row';
  gap?: string;
  labelSize?: keyof typeof LabelSizeConstant;
  labelTextSize?: FontType;
};

const FlexFormControl = ({
  name,
  required,
  control,
  disabled,
  direction = 'row',
  gap,
  labelSize = 'md2',
  labelTextSize = 'h4',
}: FormControlProps) => {
  return (
    <div
      className={`
      flex
      ${direction === 'column' && 'flex-col'}
      items-start justify-start
      ${gap}
      ${disabled && 'opacity-50'} 
      `}
    >
      <div className={`flex items-center h-9 ${LabelSizeConstant[labelSize]}`}>
        <Label labelTextSize={labelTextSize} labelSize='none' required={required} name={name} />
      </div>

      <div className='shrink-0'>{control}</div>
    </div>
  );
};

export default FlexFormControl;
