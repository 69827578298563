import App from 'App';
import LanguageSettingContainer from 'components/feature/setting/language/LanguageSettingContainer';
import PointSettingContainer from 'components/feature/setting/point/PointSettingContainer';
import CustomFieldSettingContainer from 'components/feature/setting/tenant/CustomFieldSettingContainer';
import ThemeSettingContainer from 'components/feature/setting/theme/ThemeSettingContainer';
import WorkspaceEditContainer from 'components/feature/setting/workspace/WorkspaceEditContainer';
import InternalErrorPage from 'pages/InternalErrorPage';
import LoginPage from 'pages/LoginPage';
import NotFound from 'pages/NotFound';
import ServerErrorPage from 'pages/ServerErrorPage';
import KioskScreensaverPage from 'pages/ad/screensaver/kiosk/KioskScreenSaverPage';
import KioskScreenSaverAddPage from 'pages/ad/screensaver/kiosk/add/KioskScreenSaverAddPage';
import FacilityCategoryManagePage from 'pages/category/FacilityCategoryManagePage';
import FloorCategoryManagePage from 'pages/category/FloorCategoryManagePage';
import TenantCategoryManagePage from 'pages/category/TenantCategoryManagePage';
import CustomPage from 'pages/custom-page/CustomPage';
import CustomPageAdd from 'pages/custom-page/add/CustomPageAdd';
import CustomPageDetail from 'pages/custom-page/detail/CustomPageDetail';
import InternalDashboardPage from 'pages/internal/InternalDashboardPage';
import MachineAddPage from 'pages/machine/add/MachineAddPage';
import RegisteredMachineDetailPage from 'pages/machine/add/detail/MachineDetailPage';
import MachineManagePage from 'pages/machine/manage/MachineManagePage';
import MachineMonitoringPage from 'pages/machine/monitoring/MachineMonitoringPage';
import MenuSettingPage from 'pages/menu/MenuSettingPage';
import ContentPage from 'pages/service/content/ContentPage';
import ContentAddPage from 'pages/service/content/add/AddPage';
import ContentDetailPage from 'pages/service/content/detail/DetailPage';
import SettingPage from 'pages/setting/SettingPage';
import TenantPage from 'pages/tenant/TenantPage';
import WorkspaceListPage from 'pages/workspace/WorkspaceListPage';
import WorkspaceAddPage from 'pages/workspace/add/WorkspaceAddPage';
import { createBrowserRouter } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';

export const router = createBrowserRouter([
  {
    path: RoutePath.internal,
    errorElement: <InternalErrorPage />,
    children: [
      { path: RoutePath.internalError, element: <InternalErrorPage /> },
      {
        index: true,
        handle: '기기 상태 대시보드',
        path: RoutePath.internalDashBoard,
        errorElement: <InternalErrorPage />,
        element: <InternalDashboardPage />,
      },
    ],
  },
  {
    path: RoutePath.login,
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { path: RoutePath.login, element: <LoginPage /> },
      { path: RoutePath.workspaces, element: <WorkspaceListPage /> },
      { path: RoutePath.workspaceAdd, element: <WorkspaceAddPage /> },
      { path: RoutePath.serverError, element: <ServerErrorPage /> },
      { path: RoutePath.notFound, element: <NotFound /> },

      { index: true, path: RoutePath.content, element: <ContentPage /> },
      {
        handle: '콘텐츠 관리',
        path: RoutePath.content,
        children: [
          { index: true, element: <ContentPage /> },
          { handle: '콘텐츠 등록', path: RoutePath.contentAdd, element: <ContentAddPage /> },
          { handle: '콘텐츠 상세', path: RoutePath.contentDetail, element: <ContentDetailPage /> },
        ],
      },
      {
        handle: '광고 관리',
        path: RoutePath.screensaver,
        element: <KioskScreensaverPage />,
        children: [{ path: RoutePath.pointScreensaver, element: <KioskScreensaverPage /> }],
      },
      { handle: '스케줄 추가', path: RoutePath.pointScreensaverAdd, element: <KioskScreenSaverAddPage /> },
      {
        handle: '매장 관리',
        path: RoutePath.tenantManage,
        element: <TenantPage />,
      },
      {
        handle: '카테고리 관리',
        children: [
          {
            handle: '층별안내 카테고리',
            path: RoutePath.floorCategoryManage,
            element: <FloorCategoryManagePage />,
          },
          {
            handle: '매장 분류 카테고리',
            path: RoutePath.tenantCategoryManage,
            element: <TenantCategoryManagePage />,
          },
          {
            handle: '편의시설',
            path: RoutePath.facilityCategoryManage,
            element: <FacilityCategoryManagePage />,
          },
        ],
      },
      {
        handle: '메뉴 관리',
        children: [
          {
            path: RoutePath.menuSetting,
            element: <MenuSettingPage />,
          },
        ],
      },
      {
        handle: '메뉴 콘텐츠 관리',
        children: [
          { path: RoutePath.customPage, index: true, element: <CustomPage /> },
          { handle: '메뉴 콘텐츠 등록', path: RoutePath.customPageAdd, element: <CustomPageAdd /> },
          { handle: '메뉴 콘텐츠 상세', path: RoutePath.customPageDetail, element: <CustomPageDetail /> },
        ],
      },
      {
        handle: '기기 관리',
        path: RoutePath.machine,
        children: [
          { index: true, element: <MachineManagePage /> },
          { handle: '기기 등록', path: RoutePath.machineAdd, element: <MachineAddPage /> },
          { handle: '기기 상세', path: RoutePath.machineDetail, element: <RegisteredMachineDetailPage /> },
        ],
      },
      {
        handle: '기기 상태',
        path: RoutePath.monitoring,
        element: <MachineMonitoringPage />,
      },
      {
        handle: '설정',
        children: [
          {
            path: RoutePath.setting,
            element: <SettingPage />,
            children: [
              { index: true, handle: '기본', path: RoutePath.basicSetting, element: <WorkspaceEditContainer /> },
              { handle: '포인트', path: RoutePath.pointSetting, element: <PointSettingContainer /> },
              { handle: '언어', path: RoutePath.languageSetting, element: <LanguageSettingContainer /> },
              { handle: '사용자 정의', path: RoutePath.customFieldSetting, element: <CustomFieldSettingContainer /> },
              { handle: '테마', path: RoutePath.themeSetting, element: <ThemeSettingContainer /> },
            ],
          },
        ],
      },
    ],
  },
]);
