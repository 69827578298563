import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import ModalContainer from 'components/common/modal/ModalContainer';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import { useState } from 'react';
import usePointStore from 'stores/point';
import { CustomPageType } from 'types/feature/custom-page/customPage';
import { MultiLangs } from 'types/feature/language/language';
import { MainMenu, MainMenuIcon, PostMainMenu } from 'types/feature/menu/menu';
import ToggleIconUsed from './ToggleIconUsed';
import MenuPopupHeader from './header/MenuPopupHeader';
import MenuIcons from './menu-icon/MenuIcons';
import AddMenuType from './menu-type/AddMenuType';
import MenuMultiLangInputs from './multi-language/MenuMultiLangInputs';

type Props = {
  menuIcons: MainMenuIcon[];
  closePopup: () => void;
  createMainMenu: (pointId: string, menu: PostMainMenu) => Promise<void>;
  mainMenus: MainMenu[];
};

const CreateMenuPopup = ({ menuIcons, closePopup, createMainMenu }: Props) => {
  const [menuName, setMenuName] = useState<MultiLangs>({
    ko: '',
    en: '',
    ja: '',
    cn: '',
    tw: '',
  });

  const [selectedCustomMenuType, setSelectedCustomMenuType] = useState<CustomPageType>('LIST');
  const [selectedIconId, setSelectedIconId] = useState<string>(menuIcons[0].id);
  const [selectedIconUsed, setSelectedIconUsed] = useState<boolean>(true);

  const [openConfirm, setOpenConfirm] = useState(false);

  const pointStore = usePointStore();

  // 메뉴 이름 입력 시
  const handleChangeMenuName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setMenuName((prev: MultiLangs) => ({
      ...prev,
      [id as string]: value,
    }));
  };

  // 메뉴 타입 선택 시
  const handleClickMenuType = (type: string) => {
    setSelectedCustomMenuType(type as CustomPageType);
  };

  // 메뉴 아이콘 선택 시
  const handleClickIcon = (id: string) => {
    setSelectedIconId(id);
  };

  // 메뉴 아이콘 사용 여부 선택 시
  const handleClickIconUsed = (used: boolean) => {
    setSelectedIconUsed(used);
  };

  // 추가 버튼 클릭 시
  const handleClickAddBtn = () => {
    setOpenConfirm(true);
  };

  // 메뉴 저장
  const handleSaveMenu = async () => {
    const menu: PostMainMenu = {
      type: 'CUSTOM_BOARD',
      customPageType: selectedCustomMenuType,
      name: menuName,
      iconId: selectedIconId,
      iconUsed: selectedIconUsed,
    };

    await createMainMenu(pointStore.currentPoint?.id || '', menu);
  };

  return (
    <>
      <ModalContainer>
        <div className='flex flex-col items-start bg-white shadow-xl w-[840px]'>
          {/* header */}
          <MenuPopupHeader headerText='사용자 메뉴 생성' />

          {/* 메뉴 타입 */}
          <AddMenuType handleClickMenuType={handleClickMenuType} selectedCustomMenuType={selectedCustomMenuType} />

          <div className='flex h-[278px] w-full'>
            {/* 메뉴 이름 */}
            <div className='flex flex-col flex-1 w-full gap-3 px-10 py-5 border-r shrink-0'>
              <Title text='메뉴 이름' titleType='h3_bold' />
              <MenuMultiLangInputs handleChangeMenuName={handleChangeMenuName} menuName={menuName} />
            </div>

            {/* 메뉴 아이콘 */}
            <div className='flex flex-col flex-1 w-full gap-3 px-10 py-5 shrink-0'>
              <Title text='메뉴 아이콘' titleType='h3_bold' />

              {/* 아이콘 사용 유무 */}
              <FlexFormControl
                labelSize='md'
                name={'사용 유무'}
                control={<ToggleIconUsed iconUsed handleClickIconUsed={handleClickIconUsed} />}
              />

              {/* 아이콘 */}
              <MenuIcons menuIcons={menuIcons} handleClickIconId={handleClickIcon} selectedIconId={selectedIconId} />
            </div>
          </div>

          <div className='w-full flex gap-2.5 justify-center py-6 h-[104px] border-t'>
            <Button text='취소' color={ButtonColor.secondary} size={120} height='h-14' onClick={closePopup} />
            <Button onClick={handleClickAddBtn} text='메뉴 생성' color={ButtonColor.primary} size={120} height='h-14' />
          </div>
        </div>
      </ModalContainer>

      {openConfirm && (
        <ConfirmPopup
          message='메뉴를 생성하시겠습니까?'
          onClickOk={handleSaveMenu}
          onClickCancel={() => setOpenConfirm(false)}
        />
      )}
      {/* validation */}
      {/* {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />} */}
    </>
  );
};

export default CreateMenuPopup;
