import React from 'react';

const OffDark = () => {
  return (
    <div className='w-[14px] h-[20px] flex items-center'>
      <div className='w-[14px] h-[14px]  bg-[#2D3141] rounded-full border border-[#2D3141]' />
    </div>
  );
};
export default OffDark;
