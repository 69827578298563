import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import useCategory from 'hooks/feature/category/useCategory';
import { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import useCategoryStore from 'stores/category';
import { TenantCategoryFeatureCode } from 'types/feature/category/tenantCategory';
import CategoryGroupsTable from './category-groups-table/CategoryGroupsTable';
import NoCategory from './no-category/NoCategory';

type TenantCategoryGroupsProps = {
  featureCode: TenantCategoryFeatureCode;
  pointId: string | undefined;
};

const TenantCategoryGroups = ({ featureCode, pointId }: TenantCategoryGroupsProps) => {
  const { getFeatureCategories } = useCategory();

  const { setTenantCategoryGroups, tenantCategoryGroups } = useCategoryStore();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const fetchCategories = async () => {
    try {
      if (pointId) {
        const resultOfFeatureCategories = await getFeatureCategories(pointId, featureCode);

        if (resultOfFeatureCategories) {
          setTenantCategoryGroups(resultOfFeatureCategories);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 400) {
        setOpenAlert(true);
        setAlertMessage('카테고리를 불러올 수 없습니다.');
      }
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [pointId]);

  return (
    <>
      <Droppable droppableId='tenant-category-groups'>
        {provided => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className='flex flex-col flex-1 w-full gap-3 cursor-default shrink-0'
            >
              {tenantCategoryGroups.length > 0 ? (
                <CategoryGroupsTable tenantCategoryGroups={tenantCategoryGroups} pointId={pointId} />
              ) : (
                <NoCategory />
              )}
            </div>
          );
        }}
      </Droppable>

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};
export default TenantCategoryGroups;
