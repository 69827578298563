import LoginForm from 'components/feature/login/form/LoginForm';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';

const LoginPage = () => {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem('KIOSK_ADMIN_LOGIN_STATUS') === 'true';

  const navigateToMain = () => {
    if (isLogin) {
      navigate(RoutePath.workspaces);
    }
  };

  useEffect(() => {
    navigateToMain();
  }, [isLogin]);

  return (
    <div className='fixed top-0 left-0 w-full'>
      <LoginForm />
    </div>
  );
};
export default LoginPage;
