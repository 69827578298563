import { useAxiosInternal } from 'api/useAxiosInternal';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Floor } from 'types/feature/floor/floor';

const useInternalFloor = () => {
  const { api } = useAxiosInternal();

  /**
   * 층 목록을 조회한다
   */
  const getInternalFloors = async (pointId: string): Promise<Floor[]> => {
    const response = await api.get<ApiResponse<Floor[]>>(Endpoint.internalFloors, {
      params: {
        pointId,
      },
    });
    return response.data;
  };

  return { getInternalFloors };
};
export default useInternalFloor;
