import Badge from 'components/common/badge/Badge';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { useState } from 'react';
import { Floor } from 'types/feature/floor/floor';
import { ConnectedMachine, MACHINE_PLACE_TYPE_VALUES } from 'types/feature/menu/machine/machine';

type Props = {
  machineIndex: number;
  connectedMachine: ConnectedMachine;
  machineFloor: Floor | undefined;
};

const MonitoringRow = ({ machineIndex, connectedMachine, machineFloor }: Props) => {
  // hook
  const { resetMachine } = useMachine();

  // state
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  /**
   * machine on/off 여부
   */
  const isMachineConnected = connectedMachine.connected;

  /**
   * 기기를 재시작한다
   */
  const handleReloadMachine = async () => {
    await resetMachine(connectedMachine.id);
    setOpenConfirmPopup(false);
  };

  return (
    <>
      <tbody>
        <tr className='h-12 cursor-default'>
          {/* No. */}
          <td className='px-2.5'>{machineIndex}</td>
          {/* 구분 */}
          <td className='px-2.5'>{MACHINE_PLACE_TYPE_VALUES[connectedMachine.placeType]}</td>
          {/* 층 */}
          <td className='px-2.5'>{machineFloor?.name.ko || ''}</td>
          {/* 기기 코드 */}
          <td className='px-2.5'>{connectedMachine.idSuffix}</td>
          {/* 상세위치 */}
          <td className='text-left px-2.5'>{connectedMachine.description}</td>
          {/* 상태 */}
          <td className='px-2.5'>
            <Badge
              cursorStyle='cursor-default'
              badgeType={isMachineConnected ? 'OPERATE' : 'CLOSE'}
              name={isMachineConnected ? 'ON' : 'OFF'}
            />
          </td>
          {/* 재시작 */}
          <td className='flex justify-center items-center h-12 px-2.5'>
            {isMachineConnected ? (
              <div className='cursor-pointer' onClick={() => setOpenConfirmPopup(true)}>
                <img src={ICON.Reload} alt='restart' />
              </div>
            ) : (
              <div>
                <img src={ICON.Reload_Disabled} alt='restart' />
              </div>
            )}
          </td>
        </tr>
      </tbody>

      {openConfirmPopup && (
        <ConfirmPopup
          message='기기를 재시작 하시겠습니까?'
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={handleReloadMachine}
        />
      )}
    </>
  );
};

export default MonitoringRow;
