import { Floor } from 'types/feature/floor/floor';
import { ConnectedMachine } from 'types/feature/menu/machine/machine';
import { Point } from 'types/feature/workspace/workspace';
import FloorMachines from './floor-machines/FloorMachines';

type Props = {
  notEmptyFloors: Floor[];
  floorMachinesMap: Map<string, ConnectedMachine[]>;
  fetchMachines: () => Promise<void>;
  point: Point;
};

const InternalDashboardMachines = (props: Props) => {
  return (
    <div
      className='flex flex-wrap rounded-[1.25rem] bg-[#313443] pt-[20px] pr-[40px] pb-[40px] pl-[40px] h-full'
      style={{ height: 'calc(100% - 90px)' }}
    >
      <section className='flex flex-1 flex-col w-full h-full flex-wrap gap-y-2.5 gap-x-7 '>
        {props.notEmptyFloors.map((floor, index) => {
          const machines = props.floorMachinesMap.get(floor.id) || [];
          return (
            <FloorMachines
              key={floor.id}
              floor={floor}
              machines={machines}
              isLastFloor={index === props.notEmptyFloors.length - 1}
              fetchMachines={props.fetchMachines}
              point={props.point}
            />
          );
        })}
      </section>
    </div>
  );
};
export default InternalDashboardMachines;
