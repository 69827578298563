import LoadingContainer from 'components/common/loading/LoadingContainer';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingStore from 'stores/loading';
import { Floor } from 'types/feature/floor/floor';
import { RegisterMachine } from 'types/feature/menu/machine/machine';
import { ScreenType } from 'types/feature/screensaver/getScreensaver';
import MapDraw from 'utils/map/mapDraw';
import FloorList from './floor/FloorList';
import MapScreen from './screen/MapScreen';
import MachineCenterSign from './sign/MachineCenterSign';

type Props = {
  mode: 'register' | 'edit';
  registeredMachine?: RegisterMachine;
  screenType: ScreenType;
  floorList: Floor[];
};

const MachineMapContainer = ({ screenType, mode, registeredMachine, floorList }: Props) => {
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();
  const loadingStore = useLoadingStore();

  const mapRef = useRef<HTMLDivElement>(null);

  const mapInstance = new MapDraw();

  const fetchWorkspaceAndMapData = async () => {
    try {
      if (workspaceId) {
        const response = await getWorkspace(workspaceId);

        if (response) {
          const workspace = response.data;

          const mapData = await MapDraw.dabeeoMaps.getMapData({
            clientId: workspace.map.clientId,
            clientSecret: workspace.map.clientSecret,
            version: workspace.map.version,
            serverType: 'SERVER_STUDIO4',
          });

          if (mapData) {
            MapDraw.mapData = mapData;

            const findMainFloor = floorList?.find(floor => floor.main);

            if (findMainFloor) {
              MapDraw.defaultFloor = { ...findMainFloor };

              // 등록, 수정 모드에 따라 지도 설정이 달라지기 때문에 모드별로 분기 처리
              if (mode === 'edit') {
                if (registeredMachine) {
                  if (mapRef.current) {
                    await mapInstance.showMap(mapRef.current, {
                      controlOption: {
                        zoom: Number(registeredMachine?.mapPosition.zoom) || 0,
                        rotate: Number(registeredMachine?.mapPosition.rotation) || 0,
                        pan: {
                          x: Number(registeredMachine?.mapPosition.centerPositionX) || 0,
                          y: Number(registeredMachine?.mapPosition.centerPositionY) || 0,
                          z: 0,
                        },
                      },
                      floor: registeredMachine.floorId,
                      enableGeoreferencing: false,
                    });
                  }
                }

                loadingStore.setLoading(false);
                return;
              }

              if (mode === 'register') {
                if (mapRef.current) {
                  await mapInstance.showMap(mapRef.current, { floor: findMainFloor.id, enableGeoreferencing: false });

                  loadingStore.setLoading(false);
                }
              }
            }
          }
        }
      }
    } catch {
      loadingStore.setLoading(false);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkspaceAndMapData();
  }, [floorList, registeredMachine]);

  useEffect(() => {
    return () => {
      if (MapDraw.map) {
        if (!loadingStore.loading) {
          mapInstance.cleanup();
        }
      }
    };
  }, [loadingStore.loading]);

  useEffect(() => {
    return () => {
      loadingStore.setLoading(true);
    };
  }, []);

  return (
    <div className='relative shrink-0'>
      {loadingStore.loading ? (
        <LoadingContainer />
      ) : (
        <div>
          <MachineCenterSign />
          <MapScreen screenType={screenType} />
          {mode === 'edit' && (
            <FloorList floors={floorList || []} currentFloorId={registeredMachine && registeredMachine.floorId} />
          )}
        </div>
      )}

      <div ref={mapRef} className='w-[900px] h-[666px]' />
    </div>
  );
};

export default MachineMapContainer;
