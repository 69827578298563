import Badge from 'components/common/badge/Badge';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Display, DisplayStatus } from 'types/common/display';
import { RoutePath } from 'types/common/paths';
import { CustomBoardContent } from 'types/feature/custom-page/customPage';

type Props = {
  customBoards: CustomBoardContent[];
};

const CustomPageTable = ({ customBoards }: Props) => {
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();

  const moveToDetailPage = (id: string) => {
    navigateToReplacedPath(RoutePath.customPageDetail, workspaceId, ':customContentId', id);
  };

  return (
    <>
      <table className='w-full cursor-pointer'>
        <colgroup>
          <col width='5%' />
          <col width='20%' />
          <col width='5%' />
          <col width='8%' />
          <col width='8%' />
        </colgroup>

        <thead>
          <tr className='whitespace-nowrap'>
            <th className='text-xs'>No.</th>
            <th className='text-xs'>타이틀</th>
            <th className='text-xs'>노출 유형</th>
            <th className='text-xs'>기간</th>
            <th className='text-xs'>진행 여부</th>
          </tr>
        </thead>

        <tbody>
          {customBoards.map((row, index) => {
            return (
              <tr key={row.id} onClick={() => moveToDetailPage(row.id)} className='hover:bg-slate-50 h-[48px]'>
                <td className='text-xs'>{index + 1}</td>
                <td className='text-xs text-left'>{row.title.ko}</td>
                <td className='text-xs'>{Display[row.displayType]}</td>
                <td className='text-xs'>
                  {row.startDate} ~ {row.endDate}
                </td>
                <td>
                  <Badge name={DisplayStatus[row.displayStatus]} badgeType={row.displayStatus} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {customBoards.length === 0 && (
        <div className='w-full flex justify-center text-[#aaa] text-xs'>현재 등록된 데이터가 없습니다.</div>
      )}
    </>
  );
};

export default CustomPageTable;
