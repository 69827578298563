import React, { ChangeEvent, RefObject, useState } from 'react';

// 필요한거 글자수 제한
// 아래 validate 노출
type Props = {
  name?: string;
  width?: string;
  height?: string;
  placeholder?: string;
  guide?: string;
  type: 'text' | 'password' | 'number';
  regexCheck?: RegExp;
  regexGuide: string;
  defaultValue?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: RefObject<HTMLInputElement>;
};

const ValidateInput = ({
  name,
  placeholder,
  guide,
  type,
  width = 'w-[330px]',
  height = 'h-9',
  regexCheck,
  regexGuide,
  inputRef,
  defaultValue,
  onChange,
}: Props) => {
  const [showWarning, setShowWarning] = useState(false);
  const [helperText, setHelperText] = useState(guide);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === '') {
      setShowWarning(false);
      setHelperText(guide);
      return;
    }
    // 통과
    if (regexCheck?.test(value)) {
      setShowWarning(false);
      setHelperText('');
    } else {
      setShowWarning(true);
      setHelperText(regexGuide);
    }
  };
  return (
    <div className='flex flex-col gap-[6px] items-end'>
      <input
        name={name}
        ref={inputRef}
        defaultValue={defaultValue}
        type={type}
        className={`${width} ${height} 
        outline-none focus:outline-none border
       ${showWarning ? 'border-[#D94545] focus:border-[#D94545]' : 'border-gray-99 focus:border-gray-99'}  
       placeholder:text-gray-cc placeholder:text-xs text-xs px-2`}
        placeholder={placeholder}
        onChange={e => {
          onChange(e);
          handleChangeInput(e);
        }}
      />
      <div className={`${showWarning ? 'text-[#D94545]' : 'text-gray-cc'} text-[11px] whitespace-nowrap`}>
        {helperText}
      </div>
    </div>
  );
};

export default ValidateInput;
