import { AxiosError } from 'axios';
import FileDropZone from 'components/common/file/drop-zone/FileDropZone';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import useFileUpload from 'hooks/common/useFileUpload';
import useTenant from 'hooks/feature/tenant/useTenant';
import { useEffect, useState } from 'react';
import { CommonFile, FileType } from 'types/common/file/file';
import { FileUploadType, PatchFile } from 'types/common/file/fileUpload';
import { returnFileAccept } from 'utils/file/extension/returnFileAccept';
import { returnFileMaxSize } from 'utils/file/size/returnFileSize';

type TenantImageFieldProps = {
  title: string;
  fieldId: string;
  tenantId: string;
  logoFile: CommonFile;
  fetchTenants: () => Promise<void>;
};

const TenantImageField = ({ title, fieldId, tenantId, logoFile, fetchTenants }: TenantImageFieldProps) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [uploadFail, setUploadFail] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [fileUri, setFileUri] = useState(logoFile && logoFile.uri);

  const { uploadFile } = useFileUpload();
  const { updateTenantDetail } = useTenant();

  // 파일 업로드 실패 시
  const openFileErrorAlert = (message: string) => {
    setAlertMessage(message);
    setUploadFail(true);
    setFileUploading(false);
  };

  // 테넌트 디테일 업데이트
  const handleUpdate = async (isDelete: boolean, imageFileName?: string, imageOriginalName?: string) => {
    try {
      const fileData: PatchFile = {
        name: imageFileName ?? '',
        originalName: imageOriginalName ?? '',
        delete: isDelete,
      };

      await updateTenantDetail(tenantId, {
        [fieldId]: fileData,
      });
      await fetchTenants();

      setFileUploading(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          openFileErrorAlert('파일 업로드 중 문제가 발생했습니다.');
        }
      }
    }
  };

  // 파일 input change
  const onChangeFile = async (file: File) => {
    try {
      setFileUploading(true);

      if (file) {
        // 파일 업로드하다 취소 했을 경우 validate 하지 않도록 처리
        if (!file) return;

        const response = await uploadFile(file, FileUploadType.TENANT_IMAGE);

        if (response) {
          await handleUpdate(false, response?.fileName, file.name);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          openFileErrorAlert('파일 업로드 중 문제가 발생했습니다.');
        }
      }
    }
  };

  // 파일 리셋
  const resetFileRef = () => {
    setFileUri('');
  };

  // 파일 삭제
  const handleDeleteFile = async () => {
    resetFileRef();
    setUploadFail(false);
    setOpenConfirm(false);

    await handleUpdate(true);
  };

  useEffect(() => {
    setFileUri(logoFile && logoFile.uri);
  }, [tenantId, logoFile]);

  return (
    <>
      <div className='flex flex-col flex-1'>
        <div className={`flex items-center h-[35px] text-sm font-bold whitespace-nowrap min-w-max text-gray-77`}>
          {title}
        </div>

        <FileDropZone
          maxSize={returnFileMaxSize(FileUploadType.LOGO_IMAGE)}
          uploadFile={onChangeFile}
          fileUri={fileUri}
          fileType={FileType.image}
          fileName={logoFile && logoFile.originalName}
          deleteFile={() => setOpenConfirm(true)}
          accept={returnFileAccept(FileUploadType.LOGO_IMAGE)}
          openFileErrorAlert={openFileErrorAlert}
          loading={fileUploading}
          previewWidth='w-full'
          defaultValue='기본 이미지입니다.'
        />
      </div>

      {openConfirm && (
        <ConfirmPopup
          message={`${title}를 삭제하시겠습니까?`}
          onClickCancel={() => setOpenConfirm(false)}
          onClickOk={handleDeleteFile}
        />
      )}

      {uploadFail && <FailPopup closeModal={() => setUploadFail(false)} text={alertMessage} />}
    </>
  );
};

export default TenantImageField;
