import { ICON } from 'constants/icons';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Language } from 'types/feature/language/language';

type Props = {
  languageList: Language[];
};

const NavigateLanguageSetting = ({ languageList }: Props) => {
  const { workspaceId } = useParams();
  const { navigateToReplacedPath } = useCustomNavigate();

  const handleNavigate = () => {
    if (workspaceId) {
      navigateToReplacedPath(RoutePath.languageSetting, workspaceId);
    }
  };
  return (
    <div
      className='flex items-center gap-2 bg-[#f9f9f9] cursor-pointer h-8 pl-3 pr-2 rounded-xl'
      onClick={handleNavigate}
    >
      <div className='text-xs font-bold text-gray-99 whitespace-nowrap'>노출 언어</div>
      <div className='flex'>
        {languageList.map((language, index) => (
          <div key={language.id} className='flex items-center'>
            <div className='text-xs font-bold text-gray-99'>{language.code}</div>
            {index !== languageList.length - 1 && <div className='h-2 ml-2 mr-2 bg-gray-cc w-[2px]' />}
          </div>
        ))}
      </div>
      <div className='w-[18px] h-[18px]'>
        <img className='h-full' src={ICON.Setting} alt='setting' />
      </div>
    </div>
  );
};

export default NavigateLanguageSetting;
