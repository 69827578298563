export type Content = {
  content: ContentItem[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  number: number;
  numberOfElements: number;
  size: number;
  empty: boolean;
};

export type ContentItem = {
  id: string;
  title: string;
  type: ContentType;
  fileType: FileType;
  screenType: ScreenType;
  updatedDate: string;
  file: ContentFile;
};

export type ContentFile = {
  uri: string;
  originalName: string;
  size: number;
  width: number;
  height: number;
};

type Pageable = {
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
};

export type UploadFile = {
  fileName: string;
  fileUri: string;
};

type ContentType = 'KIOSK' | 'MOBILE';

export enum ContentTypeCode {
  IMAGE = 'CONTENT_IMAGE',
  VIDEO = 'CONTENT_VIDEO',
}

export type FileType = keyof typeof ContentTypeCode;

export enum ContentTypeCodeKo {
  IMAGE = '이미지',
  VIDEO = '동영상',
}

export enum ScreenTypeCode {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  ETC = 'ETC',
}

export enum ScreenTypeCodeKo {
  VERTICAL = '세로형',
  HORIZONTAL = '가로형',
  ETC = '기타',
}

type ScreenType = keyof typeof ScreenTypeCode;

export enum ContentForm {
  screenType = 'screenType',
  fileType = 'fileType',
  file = 'file',
}

export const UploadFileType = {
  CONTENT_IMAGE: 'IMAGE',
  CONTENT_VIDEO: 'VIDEO',
};

export enum FileExtensionName {
  WEBM = 'video/webm',
  MP4 = 'video/mp4',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}
