import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import SucceedPopup from 'components/common/popup/SucceedPopup';
import Textarea from 'components/common/textarea/Textarea';
import Title from 'components/common/title/Title';
import MapInfo from 'components/feature/workspace/edit/MapInfo';
import ToggleMobileUsed from 'components/feature/workspace/edit/ToggleMobileUsed';
import ToggleZoomButtonUsed from 'components/feature/workspace/edit/ToggleZoomButtonUsed';
import useUserAccount from 'hooks/common/useUserAccount';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IndexType } from 'types/common';
import { AccountMap } from 'types/common/account';
import { TextType } from 'types/common/text';
import { Workspace, WorkspaceForm } from 'types/feature/workspace/workspace';

const MACHINE_TYPE: IndexType = {
  WEB: '웹 키오스크',
  DESKTOP: '설치형 키오스크',
};

const WorkspaceEditContainer = () => {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceForm, setWorkspaceForm] = useState<WorkspaceForm>({
    name: workspace?.name || '',
    description: workspace?.description || '',
    mobileUsed: workspace?.mobileUsed || false,
    zoomButtonUsed: workspace?.zoomButtonUsed || false,
  });
  const [activeConfirm, setActiveConfirm] = useState(false);
  const [openFailPopup, setOpenFailPopup] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [userSyncMap, setUserSyncMap] = useState<string>('');

  const { getWorkspace, putWorkspace } = useWorkspace();
  const { getAccount } = useUserAccount();
  const { workspaceId } = useParams();

  // 계정 정보 조회
  const fetchAccount = async () => {
    const account = await getAccount();
    if (account) {
      const findMap = account.maps.find((map: AccountMap) => map.id === workspace?.map.id || '');

      if (findMap) {
        setUserSyncMap(findMap.name);
      }
    }
  };

  // 워크스페이스 정보 조회
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);
      if (result) {
        setWorkspace(result.data);
        setWorkspaceForm(result.data);
      }
    }
  };

  // 입력폼 change event
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (workspace) {
      const { name, value } = e.target;

      const updateContent = {
        name: workspace.name,
        description: workspace.description,
        mobileUsed: workspace.mobileUsed,
        zoomButtonUsed: workspace.zoomButtonUsed,
      };

      setWorkspaceForm(() => ({
        ...updateContent,
        [name]: value,
      }));
    }
  };

  // 상세정보 onchange
  const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    setWorkspaceForm(prev => ({
      ...prev,
      description: value,
    }));
  };

  // 저장
  const onSubmitWorkspace = () => {
    if (isEmpty(workspaceForm.name)) {
      setOpenFailPopup(true);
      setActiveConfirm(false);
      return;
    }

    setActiveConfirm(true);
  };

  // 워크스페이스 수정
  const updateWorkspace = async () => {
    try {
      if (workspaceForm) {
        const result = await putWorkspace(workspace?.id || '', workspaceForm);
        if (result.result === 'SUCCESS') {
          setActiveConfirm(false);
          setOpenSuccessPopup(true);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setOpenFailPopup(true);
        }
      }
    }
  };

  useLayoutEffect(() => {
    fetchAccount();
  }, [workspace]);

  useEffect(() => {
    fetchWorkspace();
  }, [workspaceId]);

  if (!workspace) return <> </>;

  return (
    <div className='flex flex-col justify-start w-full h-full gap-12'>
      {/* 지도 정보 */}
      <div className='flex flex-col gap-5'>
        <Title text='지도' titleType='h3_bold' />
        <FlexFormControl
          labelSize='md2'
          name='지도 정보'
          control={<MapInfo workspace={workspace} mapName={userSyncMap} fetchWorkspace={fetchWorkspace} />}
        />
      </div>
      <Border />

      {/* 워크스페이스 설정 */}
      <div className='flex flex-col gap-5'>
        <Title text='워크스페이스' titleType='h3_bold' />

        <div className='flex flex-col gap-3'>
          <FlexFormControl
            name='워크스페이스 이름'
            labelSize='md2'
            required
            control={
              <Input
                width='w-72'
                name='name'
                placeholder='워크스페이스 이름을 입력하세요'
                defaultValue={workspace.name}
                onChange={onChangeInput}
              />
            }
          />

          <FlexFormControl
            name='상세 정보'
            labelSize='md2'
            control={
              <Textarea
                name='description'
                defaultValue={workspace.description}
                placeholder='상세 정보를 입력하세요.'
                onChange={onChangeTextArea}
                width='w-[518px]'
              />
            }
          />

          <FlexFormControl
            labelSize='md2'
            name='기기 타입'
            control={
              <div className={`${TextType.body1} h-9 text-gray-44 text-center flex items-center`}>
                {MACHINE_TYPE[workspace.machineType]}
              </div>
            }
          />

          <FlexFormControl
            labelSize='md2'
            name='모바일 키오스크'
            control={<ToggleMobileUsed mobileUsed={workspace.mobileUsed} setWorkspaceForm={setWorkspaceForm} />}
          />

          <FlexFormControl
            labelSize='md2'
            name='줌버튼'
            control={
              <ToggleZoomButtonUsed zoomButtonUsed={workspace.zoomButtonUsed} setWorkspaceForm={setWorkspaceForm} />
            }
          />
        </div>

        <Border />

        <div className='flex justify-end w-full'>
          <Button text='저장' type='button' size={120} color={ButtonColor.primary} onClick={onSubmitWorkspace} />
        </div>
      </div>
      {activeConfirm && (
        <ConfirmPopup
          message='워크 스페이스 정보를 수정하시겠습니까?'
          onClickCancel={() => setActiveConfirm(false)}
          onClickOk={updateWorkspace}
        />
      )}
      {openFailPopup && (
        <FailPopup closeModal={() => setOpenFailPopup(false)} text='워크스페이스 이름을 입력해주세요.' />
      )}

      {openSuccessPopup && <SucceedPopup text='수정되었습니다.' closeModal={() => setOpenSuccessPopup(false)} />}
    </div>
  );
};

export default WorkspaceEditContainer;
