type IndexType = {
  [index: string]: number;
};

// 메뉴 타이틀 글자 수 제한
export const MenuTitleWordLimit: IndexType = {
  ko: 7,
  en: 11,
  ja: 7,
  cn: 7,
  tw: 7,
};

// 테넌트 타이틀 글자 수 제한
export const TenantTitleWordLimit: IndexType = {
  ko: 20,
  en: 20,
  ja: 20,
  cn: 20,
  tw: 20,
};

// 테넌트 상세 설명 글자 수 제한
export const TenantDescWordLimit: IndexType = {
  ko: 150,
  en: 150,
  ja: 150,
  cn: 150,
  tw: 150,
};

// 커스텀 필드 타이틀 글자 수 제한
export const CustomFieldTitleWordLimit: IndexType = {
  ko: 7,
  en: 11,
  ja: 7,
  cn: 7,
  tw: 7,
};

// 커스텀 메뉴 콘텐츠 타이틀 글자 수 제한
export const CustomContentTitleWordLimit: IndexType = {
  ko: 40,
  en: 58,
  ja: 40,
  cn: 40,
  tw: 40,
};
