import UsedBadge from 'components/common/badge/used-badge/UsedBadge';
import GuideDescription from 'components/common/description/GuideDescription';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import LimitLength from 'components/common/length/LimitLength';
import { OPERATION_GUIDES } from 'data/guide/setting';
import { Dispatch, SetStateAction } from 'react';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  setPointForm: Dispatch<SetStateAction<Point>>;
  pointForm: Point;
  handleChangeFormOperation: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PointOperationHours = ({ setPointForm, pointForm, handleChangeFormOperation }: Props) => {
  const handleHoursUsedBadge = () => {
    setPointForm((prev: Point) => ({
      ...prev,
      operation: {
        ...prev?.operation,
        hoursUsed: !pointForm.operation.hoursUsed,
      },
    }));
  };

  const onChangeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPointForm((prev: Point) => ({
      ...prev,
      operation: {
        ...prev?.operation,
        [name]: value,
      },
    }));
  };

  return (
    <div className='flex flex-col w-full gap-5'>
      <div className='flex flex-col gap-5'>
        <FlexFormControl
          name='운영시간 사용 여부'
          labelSize='md2'
          control={
            <div className='flex flex-col items-start gap-4'>
              <UsedBadge used={pointForm?.operation.hoursUsed} toggleBadge={handleHoursUsedBadge} />
              <GuideDescription guides={OPERATION_GUIDES} />
            </div>
          }
        />

        <FlexFormControl
          name='운영시간 대체 설명'
          labelSize='md2'
          control={
            <div className='flex items-end gap-4'>
              <Input
                width='w-80'
                name='hoursDescription'
                value={pointForm?.operation.hoursDescription || ''}
                placeholder='운영시간을 대체할 설명을 입력하세요.'
                onChange={handleChangeFormOperation}
                key='hoursDescription'
              />

              <LimitLength length={pointForm?.operation.hoursDescription.length} limit={10} />
            </div>
          }
        />

        <FlexFormControl
          name='운영 시작 시간'
          labelSize='md2'
          control={
            <Input
              width='w-48'
              type='time'
              name='hoursStart'
              onChange={onChangeHours}
              value={pointForm.operation.hoursStart || ''}
              key='hoursStart'
            />
          }
        />

        <FlexFormControl
          name='운영 종료 시간'
          labelSize='md2'
          control={
            <Input
              width='w-48'
              type='time'
              name='hoursEnd'
              value={pointForm.operation.hoursEnd || ''}
              onChange={onChangeHours}
              key='hoursEnd'
            />
          }
        />
      </div>
    </div>
  );
};
export default PointOperationHours;
