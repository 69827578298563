import { useParams } from 'react-router-dom';
import { useAxios } from 'api/useAxios';
import { ApiResponse } from 'types/api/response';
import { SyncCheckResult, SyncResult } from 'types/common/sync';
import { Endpoint } from 'data/endPoint';

const useSync = () => {
  const { api } = useAxios();
  const { workspaceId } = useParams();

  // 지도 동기화
  const postSync = async () => {
    if (workspaceId) {
      const res = await api.post<ApiResponse<SyncResult>>(Endpoint.sync, {
        workspaceId,
      });
      return res.data;
    }
  };

  // 지도 동기화 가능 여부 확인
  const getSyncCheck = async () => {
    if (workspaceId) {
      const res = await api.get<ApiResponse<SyncCheckResult>>(Endpoint.syncCheck, {
        params: {
          workspaceId,
        },
      });
      return res.data;
    }
  };

  // 지도 동기화 결과 확인
  const getSyncResult = async () => {
    if (workspaceId) {
      const res = await api.get<ApiResponse<SyncResult>>(Endpoint.syncResult, {
        params: {
          workspaceId,
        },
      });
      return res.data;
    }
  };

  return {
    postSync,
    getSyncCheck,
    getSyncResult,
  };
};

export default useSync;
