/* eslint-disable react/destructuring-assignment */
import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import { ICON } from 'constants/icons';
import useQrDownload from 'hooks/feature/tenant/share/useQrDownload';
import OutsideClickHandler from 'react-outside-click-handler';
import { TextType } from 'types/common/text';
import { Tenant } from 'types/feature/tenant/getTenant';

type MobileUrl = {
  type: 'location' | 'parkingPositioning' | 'parkingNavi' | 'parkingDetail';
  title: string;
  description: string;
  notice?: string;
  url: string;
};

type Props = {
  closePopup: () => void;
  tenant: Tenant;
  pointId: string;
};

const QrDownloadPopup = (props: Props) => {
  const { downQrImage } = useQrDownload();

  const mobileDidBaseUrl = process.env.REACT_APP_MOBILE_DID_BASE_URL;

  const mobileUrls: MobileUrl[] = [
    {
      type: 'location',
      title: '매장 위치 확인',
      description: '층별안내 > 매장 위치 화면으로 이동',
      url: `${mobileDidBaseUrl}/?type=location&pointId=${props.pointId}&destTenantId=${props.tenant.id}`,
    },
    {
      type: 'parkingPositioning',
      title: '내 주차위치',
      description: '내 주차위치로 지정 팝업 노출',
      url: `${mobileDidBaseUrl}/?type=parkingPositioning&pointId=${props.pointId}&originTenantId=${props.tenant.id}`,
    },
    {
      type: 'parkingNavi',
      title: '주차위치 길찾기',
      description: '내 주차위치로 길찾기 팝업 노출',
      notice: '지정된 내 주차위치 있는 경우에만 팝업 노출',
      url: `${mobileDidBaseUrl}/?type=parkingNavi&pointId=${props.pointId}&originTenantId=${props.tenant.id}`,
    },
    {
      type: 'parkingDetail',
      title: '주차위치 확인',
      description: '내 주차위치 상세 정보 화면 노출',
      url: `${mobileDidBaseUrl}/?type=parkingDetail&pointId=${props.pointId}&destTenantId=${props.tenant.id}`,
    },
  ];

  /**
   * 전달받은 url을 새창으로 연다
   * @param url mobile DID URL
   */
  const openMobileUrl = (url: string) => {
    window.open(url);
  };

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-10'>
      <OutsideClickHandler onOutsideClick={props.closePopup}>
        <div className='w-[500px] h-auto bg-white flex flex-col pb-10 gap-5'>
          <div className='h-[60px] pl-10 pr-5 flex justify-between items-center border-b border-gray-44'>
            <div className={`${TextType.h3_bold} cursor-default`}>매장 QR 다운로드</div>
            <button type='button' onClick={props.closePopup}>
              <img src={ICON.CLOSE} alt='닫기' />
            </button>
          </div>

          <div className='flex flex-col gap-5'>
            <div className='flex items-start gap-5 pl-10'>
              {/* 매장 위치 확인, 내 주차위치 */}
              {[mobileUrls[0], mobileUrls[1]].map(mobile => (
                <div key={mobile.type} className='flex flex-col w-[220px] h-[96px] items-start justify-between gap-3'>
                  <div className='flex flex-col gap-[6px]'>
                    <div
                      onClick={() => {
                        openMobileUrl(mobile.url);
                      }}
                      className='flex items-center h-[18px] gap-2 cursor-pointer'
                    >
                      {/* 타이틀 */}
                      <div className={`${TextType.h4_bold} text-gray-44`}>{mobile.title}</div>
                      {/* 새 창 열기 */}
                      <img src={ICON.NEW_WINDOW} alt='새 창 열기' />
                    </div>

                    <div className='flex flex-col gap-[2px]'>
                      {/* 상세 설명 */}
                      <div className={`${TextType.body2} text-gray-44`}>{mobile.description}</div>
                      {/* 안내 */}
                    </div>
                    {mobile.notice && <div className={`${TextType.body3} text-gray-99`}>{mobile.notice}</div>}
                  </div>
                  <div className='flex gap-2'>
                    <Button
                      onClick={() => {
                        downQrImage(`${props.tenant.name.ko}-${mobile.title}`, mobile.url);
                      }}
                      size='rounded'
                      color={ButtonColor.secondary}
                      textStyle={`${TextType.h5}`}
                    >
                      <div className='flex items-center justify-center'>
                        <img src={ICON.DOWNLOAD} alt='다운로드' />
                        <span>QR 다운로드</span>
                      </div>
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            <Border />

            {/* 주차위치 길찾기, 주차위치 확인 */}
            <div className='flex items-start gap-5 pl-10'>
              {[mobileUrls[2], mobileUrls[3]].map(mobile => (
                <div key={mobile.type} className='flex flex-col w-[220px] h-[96px] items-start justify-between gap-3'>
                  <div className='flex flex-col gap-[6px]'>
                    <div
                      onClick={() => {
                        openMobileUrl(mobile.url);
                      }}
                      className='flex items-center h-[18px] gap-2 cursor-pointer'
                    >
                      {/* 타이틀 */}
                      <div className={`${TextType.h4_bold} text-gray-44`}>{mobile.title}</div>
                      {/* 새 창 열기 */}
                      <img src={ICON.NEW_WINDOW} alt='새 창 열기' />
                    </div>

                    <div className='flex flex-col gap-[2px]'>
                      {/* 상세 설명 */}
                      <div className={`${TextType.body2} text-gray-44`}>{mobile.description}</div>
                      {/* 안내 */}
                    </div>
                    {mobile.notice && <div className={`${TextType.body3} text-gray-99`}>{mobile.notice}</div>}
                  </div>
                  <div className='flex gap-2'>
                    <Button
                      onClick={() => {
                        downQrImage(`${props.tenant.name.ko}-${mobile.title}`, mobile.url);
                      }}
                      size='rounded'
                      color={ButtonColor.secondary}
                      textStyle={`${TextType.h5}`}
                    >
                      <div className='flex items-center justify-center'>
                        <img src={ICON.DOWNLOAD} alt='다운로드' />
                        <span>QR 다운로드</span>
                      </div>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
export default QrDownloadPopup;
