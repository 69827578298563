import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import SucceedPopup from 'components/common/popup/SucceedPopup';
import Textarea from 'components/common/textarea/Textarea';
import Title from 'components/common/title/Title';
import PointHoliday from 'components/feature/setting/point/holiday/PointHoliday';
import PointLogo from 'components/feature/setting/point/logo/PointLogo';
import PointOperationHours from 'components/feature/setting/point/operation/PointOperationHours';
import { ZOOM_SETTING_GUIDES } from 'data/guide/setting';
import usePoint from 'hooks/feature/point/usePoint';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextType } from 'types/common/text';
import { Point } from 'types/feature/workspace/workspace';
import { validLength } from 'utils/length/validLength';
import MobileSetting from './mobile/MobileSetting';
import PointName from './name/PointName';

const PointSettingContainer = () => {
  const [points, setPoints] = useState<Point[]>();
  const [activePoint, setActivePoint] = useState<Point>({
    id: '',
    name: {
      ko: '',
      en: '',
      ja: '',
      cn: '',
      tw: '',
    },
    description: '',
    mapZoom: '',
    mapMinZoom: '',
    mapMaxZoom: '',
    operation: {
      hoursDescription: '',
      hoursUsed: true,
      hoursStart: '',
      hoursEnd: '',
      closedDaysUsed: true,
      closedDays: [],
    },
    logo: {
      name: '',
      uri: '',
      originalName: '',
      size: 0,
      width: 0,
      height: 0,
    },
    mobile: {
      id: '',
      machinePositionX: '',
      machinePositionY: '',
      mapPosition: {
        rotation: '',
        centerPositionX: '',
        centerPositionY: '',
        zoom: '',
      },
      mapMinZoom: '',
      mapMaxZoom: '',
      pointId: '',
    },
  });

  const [activeConfirm, setActiveConfirm] = useState(false);
  const [openFailPopup, setOpenFailPopup] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [failMessage, setFailMessage] = useState('');

  const { getPoints, getPoint, putPoint } = usePoint();
  const { workspaceId } = useParams();

  // point 목록 조회
  const fetchPoints = async (point?: Point) => {
    if (workspaceId) {
      const response = await getPoints(workspaceId);

      if (response) {
        setPoints(response);
        setActivePoint(point || response[0]);
      }
    }
  };

  // point 목록 조회
  const fetchPoint = async (pointId: string) => {
    const response = await getPoint(pointId);

    if (response) {
      setActivePoint(response);
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, id } = e.target;

    // TODO: 다국어 처리
    if (name === 'name') {
      setActivePoint((prev: Point) => ({
        ...prev,
        name: {
          ...prev.name,
          [id]: value,
        },
      }));

      return;
    }

    setActivePoint((prev: Point) => ({
      ...prev,
      [name]: value,
    }));
  };

  // 상세 설명
  const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setActivePoint((prev: Point) => ({
      ...prev,
      [name]: value,
    }));
  };

  // 운영시간
  const onChangeFormOperation = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // 운영시간 대체 설명 validation
    if (name === 'hoursDescription') {
      if (!validLength(value, 10)) {
        setOpenFailPopup(true);
        setFailMessage('운영시간 대체 설명은 10자까지 작성 가능합니다.');

        return setActivePoint((prev: Point) => ({
          ...prev,
          operation: {
            ...prev.operation,
            [name]: value.slice(0, 10),
          },
        }));
      }
    }

    setActivePoint((prev: Point) => ({
      ...prev,
      operation: {
        ...prev.operation,
        [name]: value,
      },
    }));
  };

  const validatePointForm = () => {
    const pointName = activePoint.name;
    const minZoom = Number(activePoint.mapMinZoom);
    const maxZoom = Number(activePoint.mapMaxZoom);

    if (isEmpty(pointName)) {
      setOpenFailPopup(true);
      return setFailMessage('포인트 이름을 입력해주세요.');
    }

    if (maxZoom < 0 || maxZoom > 24 || minZoom < 0 || minZoom > 24) {
      setOpenFailPopup(true);
      return setFailMessage('zoom값은 0 ~ 24 사이의 값만 입력 가능합니다.');
    }

    return setActiveConfirm(true);
  };

  // 포인트 정보 수정
  const updatePoint = async () => {
    try {
      const useLogo = isEmpty(activePoint.logo?.originalName);

      const requestPoint: Point = {
        ...activePoint,
        mapMinZoom: activePoint.mapMinZoom || '0',
        mapMaxZoom: activePoint.mapMaxZoom || '0',
        operation: {
          ...activePoint?.operation,
          closedDays: activePoint.operation.closedDaysUsed ? activePoint.operation.closedDays : [],
        },
        logo: {
          delete: useLogo,
          name: activePoint.logo?.name || '',
          originalName: activePoint.logo?.originalName || '',
        },
      };

      await putPoint(activePoint.id, requestPoint);
      await fetchPoint(activePoint.id);

      setActiveConfirm(false);
      setOpenSuccessPopup(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setOpenFailPopup(true);
          setFailMessage('포인트 정보를 수정할 수 없습니다.');
        }
      }
    }
  };

  // 포인트 변경
  const onChangeActivePoint = async (point: Point) => {
    await fetchPoint(point.id);
  };

  useEffect(() => {
    fetchPoints();
  }, [workspaceId]);

  if (!activePoint) {
    return <> </>;
  }

  return (
    <div className='flex flex-col h-full'>
      {/* 포인트 탭 */}
      <div className='flex mb-10 border-b'>
        {points &&
          points.length > 0 &&
          points.map(point => (
            <div
              key={point.id}
              onClick={onChangeActivePoint.bind(this, point)}
              className={`min-w-[120px] h-[60px] flex items-center justify-center cursor-pointer 
               ${point.id === activePoint?.id ? 'text-gray-44 border-b-2 border-gray-22' : 'text-gray-99'}`}
            >
              {point.name.ko}
            </div>
          ))}
      </div>

      <div className='flex flex-col gap-5'>
        {/* 기본 정보 */}
        <div className='flex items-center justify-start h-9'>
          <Title text='기본 정보' titleType='h3_bold' />
        </div>

        <FlexFormControl
          name='이름'
          labelSize='md2'
          required
          control={<PointName pointName={activePoint.name} onChange={onChangeInput} />}
        />

        <FlexFormControl
          name='로고 이미지'
          labelSize='md2'
          control={<PointLogo setPointForm={setActivePoint} point={activePoint} />}
        />

        <FlexFormControl
          name='상세 정보'
          labelSize='md2'
          control={
            <Textarea
              width='w-[518px]'
              value={activePoint.description || ''}
              placeholder='상세정보를 입력해주세요.'
              name='description'
              onChange={onChangeTextArea}
              key='detail'
            />
          }
        />

        <FlexFormControl
          name='줌 레벨'
          control={
            <div className='flex flex-col gap-5'>
              <div className='flex gap-10'>
                {/* 최소 줌 */}
                <div className='flex gap-[10px] items-center'>
                  <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최소값</div>

                  <Input
                    type='number'
                    placeholder={`0~${activePoint.mapZoom || '24'}`}
                    name='mapMinZoom'
                    width='w-[90px]'
                    onChange={onChangeInput}
                    value={activePoint.mapMinZoom || ''}
                    min='0'
                    max={activePoint.mapZoom || '24'}
                  />
                </div>

                {/* 기본 줌 */}
                <div className='flex gap-[10px] items-center'>
                  <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>기본값</div>

                  <Input
                    type='number'
                    placeholder={`${activePoint.mapMinZoom || '0'}~${activePoint.mapMaxZoom || '24'}`}
                    name='mapZoom'
                    width='w-[90px]'
                    onChange={onChangeInput}
                    value={activePoint.mapZoom || ''}
                    min={activePoint.mapMinZoom || '0'}
                    max={activePoint.mapMaxZoom || '24'}
                  />
                </div>

                {/* 최대 줌 */}
                <div className='flex gap-[10px] items-center'>
                  <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최대값</div>

                  <Input
                    type='number'
                    placeholder={`${activePoint.mapZoom || '0'}~24`}
                    name='mapMaxZoom'
                    width='w-[90px]'
                    onChange={onChangeInput}
                    value={activePoint.mapMaxZoom || ''}
                    min={activePoint.mapZoom || '0'}
                    max='24'
                  />
                </div>
              </div>
              <GuideDescription guides={ZOOM_SETTING_GUIDES} width='w-[500px]' />
            </div>
          }
        />
      </div>

      <div className='py-10'>
        <Border />
      </div>

      {/* 운영시간 */}
      <div className='flex flex-col gap-5 pb-10'>
        <div className='flex items-center justify-start h-9'>
          <Title text='운영시간' titleType='h3_bold' />
        </div>

        <PointOperationHours
          pointForm={activePoint}
          setPointForm={setActivePoint}
          handleChangeFormOperation={onChangeFormOperation}
        />
      </div>

      {/* 휴점일 */}
      <div className='flex flex-col gap-5'>
        <div className='flex items-center justify-start h-9'>
          <Title text='휴점일' titleType='h3_bold' />
        </div>

        <PointHoliday pointInfo={activePoint} setPointInfo={setActivePoint} />
      </div>

      <div className='py-10'>
        <Border />
      </div>

      <div className='flex justify-end pb-[60px]'>
        <Button text='저장' type='button' color={ButtonColor.primary} onClick={validatePointForm} />
      </div>

      {activeConfirm && (
        <ConfirmPopup
          message='포인트 정보를 수정하시겠습니까?'
          onClickCancel={() => setActiveConfirm(false)}
          onClickOk={updatePoint}
        />
      )}

      {/* 모바일 지도 설정 */}
      <MobileSetting
        mobile={activePoint.mobile}
        point={activePoint}
        setPoint={setActivePoint}
        fetchPoint={fetchPoint}
      />

      {openFailPopup && <FailPopup closeModal={() => setOpenFailPopup(false)} text={failMessage} />}
      {openSuccessPopup && <SucceedPopup text='저장 완료되었습니다.' closeModal={() => setOpenSuccessPopup(false)} />}
    </div>
  );
};

export default PointSettingContainer;
