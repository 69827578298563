import FormControl from 'components/common/form-control/FormControl';
import SelectBox from 'components/common/select/SelectBox';
import { SCREEN_TYPE } from 'data/filter';
import { ChangeEvent } from 'react';

type FilterContainerProps = {
  handleScreenType: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const FilterContainer = ({ handleScreenType }: FilterContainerProps) => {
  return (
    <FormControl
      name='유형'
      control={<SelectBox width='w-52' options={SCREEN_TYPE} onChange={handleScreenType} />}
      required
    />
  );
};
export default FilterContainer;
