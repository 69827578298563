import React from 'react';
import { TextType } from 'types/common/text';

type Props = {
  blockTitle: string;
};

const ThemePreviewSearchBlock = ({ blockTitle }: Props) => {
  return (
    <div className='h-[68px] bg-white border-t-2 border-gray-ea flex items-center justify-center'>
      <div className={`${TextType.h4} text-gray-cc`}>{blockTitle}</div>
    </div>
  );
};
export default ThemePreviewSearchBlock;
