import Badge from 'components/common/badge/Badge';
import dayjs from 'dayjs';
import useWorkspaceStore from 'stores/workspace';
import { SCREEN_TYPE_VALUES } from 'types/common/screenType';
import { Floor } from 'types/feature/floor/floor';
import { MACHINE_PLACE_TYPE_VALUES, MACHINE_STATE_VALUES, RegisteredMachine } from 'types/feature/menu/machine/machine';

type Props = {
  registeredMachine: RegisteredMachine;
  machineIndex: number;
  floor: Floor | undefined;
  goToMachineDetailPage: (machineId: string) => void;
};

const RegisterRow = ({ registeredMachine, machineIndex, floor, goToMachineDetailPage }: Props) => {
  const { workspaceMachineType } = useWorkspaceStore();

  return (
    <tbody>
      <tr
        className='h-[48px]'
        onClick={() => {
          goToMachineDetailPage(registeredMachine.id);
        }}
      >
        {/* No. */}
        <td className='px-2.5'>{machineIndex}</td>
        {/* 구분 */}
        <td className='px-2.5'>{MACHINE_PLACE_TYPE_VALUES[registeredMachine.placeType]}</td>
        {/* 타입 */}
        <td className='px-2.5'>{workspaceMachineType}</td>
        {/* 유형 */}
        <td className='px-2.5'>{SCREEN_TYPE_VALUES[registeredMachine.screenType]}</td>
        {/* 층 */}
        <td className='px-2.5'>{floor?.name.ko || ''}</td>
        {/* 기기코드 */}
        <td className='px-2.5'>{registeredMachine.idSuffix}</td>
        {/* 상세 위치 */}
        <td className='text-left px-2.5'>{registeredMachine.description}</td>
        {/* 최근 수정일 */}
        <td className='px-2.5'>{dayjs(registeredMachine.updatedDate).format('YYYY.MM.DD')}</td>
        {/* 상태 */}
        <td className='px-2.5'>
          <Badge badgeType={registeredMachine.status} name={MACHINE_STATE_VALUES[registeredMachine.status]} />
        </td>
      </tr>
    </tbody>
  );
};

export default RegisterRow;
