import Radio from 'components/common/radio/Radio';
import { ChangeEvent, useEffect } from 'react';

type AddRadioProps = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
};

const RadioContainer = ({ value, onChange, checked, disabled }: AddRadioProps) => {
  return (
    <div className='flex flex-col items-center justify-center w-full h-full'>
      <Radio value={value} disabled={disabled} checked={checked} onChange={onChange} />
    </div>
  );
};
export default RadioContainer;
