import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { LocationForm } from 'types/feature/menu/machine/machine';

type Props = {
  setMachinePlaceForm: Dispatch<SetStateAction<LocationForm>>;
};

const CenterController = ({ setMachinePlaceForm }: Props) => {
  const handleChangeCenter = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setMachinePlaceForm((prev: LocationForm) => ({
      ...prev,
      mapPosition: {
        ...prev.mapPosition,
        [name]: value,
      },
    }));
  };
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='Center'
        gap='gap-2.5'
        direction='column'
        control={
          <div className='flex gap-5'>
            <FormControl
              name='X'
              gap='gap-2.5'
              labelSize='none'
              labelTextSize='h5'
              control={
                <Input placeholder='center X' width='w-[80px]' name='centerPositionX' onChange={handleChangeCenter} />
              }
            />
            <FormControl
              name='Y'
              gap='gap-2.5'
              labelSize='none'
              labelTextSize='h5'
              control={
                <Input placeholder='center Y' width='w-[80px]' name='centerPositionY' onChange={handleChangeCenter} />
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default CenterController;
