import Title from 'components/common/title/Title';
import { Fragment } from 'react';
import { FIELD_PADDING } from 'styles/field';
import { Language } from 'types/feature/language/language';
import { TenantCustomField } from 'types/feature/tenant/getTenant';
import CustomEditableField from './editable/CustomEditableField';
import NavigateCustomField from './navigate/NavigateCustomField';

type CustomDetailsProps = {
  tenantId: string;
  fields: TenantCustomField[];
  displayLanguageList: Language[];
  fetchTenants: () => Promise<void>;
};

const CustomDetails = ({ tenantId, fields, displayLanguageList, fetchTenants }: CustomDetailsProps) => {
  return (
    <div className={`flex flex-col w-full gap-3 pt-7 pl-10 ${FIELD_PADDING}`}>
      <div className='flex justify-between'>
        <Title text='사용자 정의 항목' titleType='h3_bold' />
        <NavigateCustomField />
      </div>

      <div>
        {fields?.map((field: TenantCustomField) => {
          return (
            <Fragment key={field.id}>
              <CustomEditableField
                fetchTenants={fetchTenants}
                field={field}
                tenantId={tenantId}
                displayLanguageList={displayLanguageList}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default CustomDetails;
