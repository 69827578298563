import { ColorSet } from 'types/feature/setting/theme/theme.type';
import ThemeFloorPreview from './floor/ThemeFloorPreview';
import ThemeMainPreview from './main/ThemeMainPreview';
import ThemeTenantPreview from './tenant/ThemeTenantPreview';

type Props = {
  usedColorSet: ColorSet | undefined;
};

const ThemePreviews = ({ usedColorSet }: Props) => {
  return (
    <div className='flex justify-between w-[919px] p-5 border bg-bg-f9'>
      <ThemeMainPreview usedColorSet={usedColorSet} />
      <ThemeFloorPreview usedColorSet={usedColorSet} />
      <ThemeTenantPreview usedColorSet={usedColorSet} />
    </div>
  );
};
export default ThemePreviews;
