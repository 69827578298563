import { IndexType } from 'types/common';

export enum DisplayType {
  always = 'ALWAYS_ON',
  duration = 'DURATION',
}

export const Display: IndexType = {
  ALWAYS_ON: '상시',
  DURATION: '기간',
};

export const DisplayStatus: IndexType = {
  DISPLAY: '진행',
  WAIT: '예정',
  CLOSE: '종료',
  NONE: '확인필요',
};
