import { ChangeEvent } from 'react';
import { TextType } from 'types/common/text';

export type CommonOptionType = {
  id: string;
  value: string;
  data?: any;
};

type SelectBoxProps = {
  options: CommonOptionType[];
  selected?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  name?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  selectId?: string;
};

const CommonSelectBox = ({ options, selected, width, height, disabled, name, onChange, selectId }: SelectBoxProps) => {
  return (
    <select
      id={selectId}
      name={name}
      disabled={disabled}
      className={`${width} ${height ?? 'h-9'} cursor-pointer 
        ${disabled ? 'border-none text-gray-44' : 'border-gray-99 bg-arrow bg-no-repeat'} 
        p-2 leading-none border appearance-none bg-transparent bg-[96%]
        ${TextType.h5}`}
      onChange={onChange}
      value={selected}
    >
      {options.map(option => (
        <option label={option.value} value={JSON.stringify(option.data) || option.id} key={option.id}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

export default CommonSelectBox;
