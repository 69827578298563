import React from 'react';
import { BorderColor } from './Border.type';

type BorderProps = {
  borderColor?: BorderColor;
};

const Border = ({ borderColor = BorderColor.gray }: BorderProps) => {
  return <div className={`w-full border-b ${borderColor}`} />;
};
export default Border;
