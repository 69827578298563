import React, { useEffect, useState } from 'react';
import useInternalFloor from 'hooks/internal/useInternalFloor';
import useInternalDashboard from 'hooks/internal/useInternalDashboard';
import usePoint from 'hooks/feature/point/usePoint';
import { Floor } from 'types/feature/floor/floor';
import { ConnectedMachine } from 'types/feature/menu/machine/machine';
import { ConnectedStatus, MachineStatus } from 'types/internal/dashboard/internalDashboardMachine';
import useMachineSocket from 'hooks/feature/socket/useMachineSocket';
import { Point as TPoint } from 'types/feature/workspace/workspace';
import InternalDashboardMachines from '../list/InternalDashboardMachines';
import InternalDashboardMachineStatus from '../status/InternalDashboardMachineStatus';

const Point = ({ id }: { id: string }) => {
  // state
  // key : floorId, value: []
  const [floorMachinesMap, setFloorMachinesMap] = useState<Map<string, ConnectedMachine[]>>(new Map());
  const [notEmptyFloors, setNotEmptyFloors] = useState<Floor[]>([]);
  const [floors, setFloors] = useState<Floor[]>([]);
  const [connectedStatus, setConnectedStatus] = useState<ConnectedStatus>({
    connected: 0,
    disconnected: 0,
  });

  // hook
  const { getInternalFloors } = useInternalFloor();
  const { getDashboardMachineStatuses } = useInternalDashboard();
  const { getPoint } = usePoint();

  const [activePoint, setActivePoint] = useState<TPoint>({
    id: '',
    name: {
      ko: '',
      en: '',
      ja: '',
      cn: '',
      tw: '',
    },
    description: '',
    mapZoom: '',
    mapMinZoom: '',
    mapMaxZoom: '',
    operation: {
      hoursDescription: '',
      hoursUsed: true,
      hoursStart: '',
      hoursEnd: '',
      closedDaysUsed: true,
      closedDays: [],
    },
    logo: {
      name: '',
      uri: '',
      originalName: '',
      size: 0,
      width: 0,
      height: 0,
    },
    mobile: {
      id: '',
      machinePositionX: '',
      machinePositionY: '',
      mapPosition: {
        rotation: '',
        centerPositionX: '',
        centerPositionY: '',
        zoom: '',
      },
      mapMinZoom: '',
      mapMaxZoom: '',
      pointId: '',
    },
  });

  /**
   * point를 조회한다
   */
  const fetchPoint = async (pointId: string) => {
    const response = await getPoint(pointId);
    if (response) {
      setActivePoint(response);
    }
  };

  /**
   * 기기 목록을 조회한다
   */
  const fetchMachines = async (floorList: Floor[]) => {
    try {
      const machineStatus: MachineStatus = await getDashboardMachineStatuses(id);

      /**
       * machine의 floorId로 Map을 생성한다
       */
      const newFloorMachinesMap = new Map<string, ConnectedMachine[]>();

      floorList.forEach(item => {
        newFloorMachinesMap.set(item.id, []);
      });

      /**
       * Map 에서 floorId를 찾아서 value에 push한다
       */
      machineStatus.machines.forEach(machine => {
        const findMachines = newFloorMachinesMap.get(machine.floorId);

        findMachines?.push(machine);
      });

      /**
       * 기기가 없는 층은 제거한다
       */
      newFloorMachinesMap.forEach((machineList, floorId) => {
        if (machineList.length < 1) {
          newFloorMachinesMap.delete(floorId);
        }
      });

      /**
       * 기기가 있는 층 목록만 저장한다
       */
      const notEmptyFloorIds: string[] = Array.from(newFloorMachinesMap.keys());
      const filteredFloors: Floor[] = floorList.filter(floor => notEmptyFloorIds.some(floorId => floorId === floor.id));
      setNotEmptyFloors(filteredFloors);

      /**
       * 상태 업데이트
       */
      setFloorMachinesMap(newFloorMachinesMap);

      /**
       * 연결된 기기 수 업데이트
       */
      setConnectedStatus(machineStatus.status);
    } catch (error) {
      console.log('대시보드를 불러올 수 없습니다', error);
    }
  };

  const fetchFloorMachines = async () => {
    const floorList = await getInternalFloors(id);
    setFloors(floorList);
    if (floorList) {
      await fetchMachines(floorList);
    }
  };

  /**
   * socket 연결 custom hook
   * socket 이 연결되었을 때 목록을 다시 fetch 하도록 처리한다
   */
  const { updatedMachine } = useMachineSocket({
    callback: fetchFloorMachines,
    pointId: id || '',
  });

  /**
   * socket 에서 message 를 받으면 기기 목록을 업데이트해준다
   */
  useEffect(() => {
    if (updatedMachine.machineId) {
      if (floors.length > 0) {
        fetchMachines(floors);
      }
    }
  }, [updatedMachine]);

  useEffect(() => {
    fetchPoint(id);
    fetchFloorMachines();
  }, [id]);

  return (
    <div className='flex flex-col pt-0 pr-0 pl-[80px] pb-[30px]'>
      {/* 포인트명, 기기 상태 */}
      <InternalDashboardMachineStatus connectedStatus={connectedStatus} activePoint={activePoint} />
      {/* 층, 기기 */}
      <InternalDashboardMachines
        notEmptyFloors={notEmptyFloors}
        floorMachinesMap={floorMachinesMap}
        fetchMachines={async () => {
          await fetchMachines(floors);
        }}
        point={activePoint}
      />
    </div>
  );
};

export default Point;
