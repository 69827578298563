import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Workspace, WorkspaceForm } from 'types/feature/workspace/workspace';

const useWorkspace = () => {
  const { api } = useAxios();

  const getWorkspaces = async () => {
    const res = await api.get<ApiResponse<Workspace[]>>(Endpoint.workspaces);
    return res.data;
  };

  const getWorkspace = async (id: string) => {
    const result = await api.get<ApiResponse<Workspace>>(Endpoint.workspaceV2.replace(':workspaceId', id));
    return result;
  };

  const saveWorkspace = async (workspace: WorkspaceForm) => {
    const result = await api.post<ApiResponse<{ id: string }>>(Endpoint.workspaces, workspace);
    return result.data.id;
  };

  const deleteWorkspace = async (id: string) => {
    const res = api.delete(`${Endpoint.workspaces}/${id}`);
    return res;
  };

  const putWorkspace = async (id: string, updateForm: WorkspaceForm) => {
    const result = api.put<ApiResponse<WorkspaceForm>>(Endpoint.workspace.replace(':workspaceId', id), updateForm);
    return result;
  };

  return { getWorkspaces, getWorkspace, saveWorkspace, deleteWorkspace, putWorkspace };
};
export default useWorkspace;
