import React from 'react';

const Next = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'>
      <path d='M0.5 1.5L5.5 6L0.5 10.5' stroke='#444' strokeWidth='1.5' />
    </svg>
  );
};
export default Next;
