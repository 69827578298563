import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import {
  ConnectedMachine,
  MachineParams,
  PatchMachine,
  RegisterMachine,
  RegisteredMachine,
} from 'types/feature/menu/machine/machine';
import { MachineType } from 'types/feature/workspace/workspace';

const useMachine = () => {
  const { api } = useAxios();

  // 기기 상태
  const getConnectedMachines = async (pointId: string, placeType: string) => {
    const result = await api.get<ApiResponse<ConnectedMachine[]>>(Endpoint.connectedMachines, {
      params: {
        pointId,
        placeType,
      },
    });
    return result;
  };

  // 재기동
  const resetMachine = async (machineId: string) => {
    await api.post(Endpoint.resetConnectedMachines.replace(':machineId', machineId));
  };

  const getMachine = async (machineType: MachineType, machineId: string) => {
    if (machineType === 'WEB') {
      const result = await api.get<ApiResponse<RegisterMachine>>(Endpoint.machine.replace(':machineId', machineId));

      return result;
    }

    if (machineType === 'DESKTOP') {
      const result = await api.get<ApiResponse<RegisterMachine>>(
        Endpoint.desktopMachine.replace(':machineId', machineId),
      );
      return result;
    }
  };

  // 수정
  const patchMachine = async (machineType: MachineType, machineId: string, data: PatchMachine) => {
    if (machineType === 'WEB') {
      await api.patch(Endpoint.machine.replace(':machineId', machineId), data);
    }

    if (machineType === 'DESKTOP') {
      await api.patch(Endpoint.desktopMachine.replace(':machineId', machineId), data);
    }
  };

  const getRegisteredMachines = async (params: MachineParams) => {
    const result = await api.get<ApiResponse<RegisteredMachine[]>>(Endpoint.machines, {
      params,
    });
    return result;
  };

  const getRegisteredDesktopMachines = async (params: MachineParams) => {
    const result = await api.get<ApiResponse<RegisteredMachine[]>>(Endpoint.desktopMachines, {
      params,
    });
    return result;
  };

  // 계정 중복 검사
  const checkDuplicateUsername = async (username: string) => {
    const result = await api.get<ApiResponse<{ duplicated: boolean }>>(
      `${Endpoint.machines.concat('/duplication-account')}`,
      {
        params: {
          username,
        },
      },
    );
    return result;
  };

  // 폐기
  const discardMachine = async (machineType: MachineType, machineId: string) => {
    if (machineType === 'WEB') {
      await api.patch(Endpoint.machinesDiscard.replace(':machineId', machineId));
    }

    if (machineType === 'DESKTOP') {
      await api.patch(Endpoint.desktopMachinesDiscard.replace(':machineId', machineId));
    }
  };

  // 기기 등록
  const postWebMachine = async (webMachineForm: any) => {
    await api.post(Endpoint.machines, webMachineForm);
  };

  const postDesktopMachine = async (desktopMachineForm: any) => {
    await api.post(Endpoint.desktopMachines, desktopMachineForm);
  };

  return {
    getMachines: getConnectedMachines,
    getMachine,
    resetMachine,

    checkDuplicateUsername,

    discardMachine,

    patchMachine,

    getRegisteredMachines,
    getRegisteredDesktopMachines,
    postWebMachine,
    postDesktopMachine,
  };
};

export default useMachine;
