import React, { useEffect, useState } from 'react';
import { RoutePath } from 'types/common/paths';

type Props = {
  currentMenu: RoutePath;
};

const useScroll = ({ currentMenu }: Props) => {
  const [scrollY, setScrollY] = useState(0);
  const [isMount, setIsMount] = useState(false);
  // 스크롤 시
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  // 스크롤 상단으로 이동
  const handleWindowScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    setIsMount(true);
  }, []);

  useEffect(() => {
    if (isMount) {
      setScrollY(window.scrollY);
    }
  }, [isMount]);

  useEffect(() => {
    if (isMount) {
      if (scrollY > 0) {
        handleWindowScrollToTop();
        setScrollY(0);
      }
      setIsMount(false);
    }
  }, [scrollY]);

  useEffect(() => {
    handleWindowScrollToTop();
  }, [currentMenu]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};
export default useScroll;
