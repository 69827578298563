import { MultiLangs } from '../language/language';

export type FeatureCategory = {
  sortOrder: number;
  code: string;
  title: string;
};

export type Feature = {
  code: string;
  title: string;
  categories: FeatureCategory[];
};

export enum TenantCategoryFeatureCode {
  tenantSearch = 'TENANT_SEARCH',
  mapFacility = 'MAP_FACILITY',
  facility = 'FACILITY',
}

export type TenantCategory = {
  id: string;
  contentCategoryCode: string;
};

export type TenantCategoryGroup = {
  id: string;
  name: MultiLangs;
  feature: TenantCategoryFeatureCode;
  sortOrder: number;
  categories: TenantCategory[] | [];
};

export type PostTenantCategoryGroup = {
  pointId: string;
  feature: TenantCategoryFeatureCode;
  contentCategoryCode: string;
};

export type PatchTenantCategoryGroup = {
  sortOrder?: number;
  name?: MultiLangs;
};
