import { Account } from 'types/common/account';
import { create } from 'zustand';

type AccountProps = {
  account: Account | null;
  setAccount: (accountInfo: Account | null) => void;

  checkDuplicate: boolean;
  setCheckDuplicate: (checkDuplicate: boolean) => void;

  duplicateUsername: boolean;
  setDuplicateUsername: (duplicateUsername: boolean) => void;
};

const useAccountStore = create<AccountProps>((set, get) => ({
  account: null,
  setAccount: (account: Account | null) => set(() => ({ account })),

  duplicateUsername: false,
  setDuplicateUsername: (duplicateUsername: boolean) => set(() => ({ duplicateUsername })),

  checkDuplicate: false,
  setCheckDuplicate: (checkDuplicate: boolean) => set(() => ({ checkDuplicate })),
}));

export default useAccountStore;
