import React from 'react';

type Props = {
  color: string;
};

const InfoSvg = ({ color }: Props) => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='24 / info'>
        <rect x='1' y='0.5' width='15' height='15' rx='7.5' stroke={color} />
        <g id='Group 4'>
          <rect id='Rectangle 86' x='9' y='12' width='1' height='5' transform='rotate(180 9 12)' fill={color} />
          <rect id='Rectangle 87' x='9.25' y='6' width='1.5' height='1.5' transform='rotate(180 9.25 6)' fill={color} />
        </g>
      </g>
    </svg>
  );
};
export default InfoSvg;
