import { IMAGES } from 'constants/images';
import React from 'react';

const MachineCenterSign = () => {
  return (
    <div className='absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full'>
      <img className='w-10 h-10' src={IMAGES.CENTER_SIGN} alt='center' />
    </div>
  );
};

export default MachineCenterSign;
