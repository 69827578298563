import { ICON } from 'constants/icons';
import useQrDownload from 'hooks/feature/tenant/share/useQrDownload';
import { MouseEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tenant } from 'types/feature/tenant/getTenant';
import { validPocWorkspace } from 'utils/poc/validPocWorkspace';
import DisplayBadge from '../../common/DisplayBadge';
import QrDownloadPopup from './qr-popup/QrDownloadPopup';

type TenantRowProps = {
  handleSelectedTenant: (tenant: Tenant) => void;
  tenant: Tenant;
  selectedTenantId: string | null;
  numbering: number;
  pointName: string;
  pointId: string;
};

const TenantRow = ({ handleSelectedTenant, numbering, tenant, selectedTenantId, pointId }: TenantRowProps) => {
  // state
  const [isOpenQrDownloadPopup, setOpenQrDownloadPopup] = useState(false);

  // hook
  const { workspaceId } = useParams();
  const { downQrImage } = useQrDownload();

  const onClickDownload = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (workspaceId) {
      // 시연용 워크스페이스라면 팝업 노출한다

      if (validPocWorkspace(workspaceId)) {
        setOpenQrDownloadPopup(true);
        return;
      }

      // 일반 워크스페이스는 바로 QR 다운로드 받는다
      const url = `${process.env.REACT_APP_MOBILE_DID_BASE_URL}/?type=location&pointId=${pointId}&destTenantId=${tenant.id}`;
      downQrImage(`${tenant.name.ko}-매장 위치 확인`, url);
    }
  };

  return (
    <>
      <div
        onClick={handleSelectedTenant.bind(this, tenant)}
        className={`${
          selectedTenantId === tenant.id ? 'bg-slate-50' : 'bg-white'
        } flex items-center justify-center h-[48px] border-b hover:bg-slate-50 cursor-pointer`}
      >
        {/* No. */}
        <div className='w-[80px] text-xs text-center'>{numbering}</div>

        {/* 노출 타이틀 */}
        <div className='grow px-2.5 overflow-hidden text-xs text-left truncate whitespace-nowrap'>{tenant.name.ko}</div>

        {/* 층 */}
        <div className='w-[140px] px-5 text-xs text-center'>{tenant.content.floor?.name.ko}</div>

        {/* 타입 */}
        <div className='w-[140px] px-5 text-xs text-center'>{tenant.popupType === 'DEFAULT' ? '기본형' : '축약형'}</div>

        {/* 사용 유무 */}
        <div className='w-[140px] px-5 text-center'>
          <DisplayBadge display={tenant.used} />
        </div>

        {/* QR 다운로드 */}
        <div onClick={onClickDownload} className='w-[120px] px-5'>
          <div className='flex items-center justify-center h-5'>
            <img src={ICON.DOWNLOAD_BUTTON} alt='다운로드' className='h-full' />
          </div>
        </div>
      </div>

      {isOpenQrDownloadPopup && (
        <QrDownloadPopup
          tenant={tenant}
          closePopup={() => {
            setOpenQrDownloadPopup(false);
          }}
          pointId={pointId}
        />
      )}
    </>
  );
};
export default TenantRow;
