import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import useWaitingList from 'hooks/feature/screensaver/useWaitingList';
import { ChangeEvent, useEffect, useState } from 'react';
import useWaitingListStore from 'stores/waitingList';
import { DisplayType } from 'types/common/display';
import { ScreenType } from 'types/common/screenType';
import { WaitingList } from 'types/feature/screensaver/waitingList';
import { Content } from 'types/feature/workspace/waitingList';
import { convertDateToString } from 'utils/convert-date/convertDate';
import ScreensaverAddButtons from './buttons/ScreensaverAddButtons';
import ContentContainer from './content/ContentContainer';
import FilterContainer from './filter/FilterContainer';

const ScreensaverAddContainer = () => {
  const [type, setType] = useState(ScreenType.all);
  const [selectedId, setSelectedId] = useState<string>('');
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const { getContentsWithoutPage } = useWaitingList();
  const { setWaitingList } = useWaitingListStore();

  const handleScreenType = (e: ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value as ScreenType);
  };

  const handleSelectedId = (id: string) => {
    setSelectedId(id);
  };

  const filterWaitingListByScreenType = async () => {
    try {
      const result: Content[] = await getContentsWithoutPage();

      if (!result) return;

      const waitingList: WaitingList[] = result.map((content: Content) => {
        const { id, screenType, fileType, title, file } = content;

        return {
          id,
          screenType,
          fileType,
          title,
          displayType: DisplayType.always,
          startDate: convertDateToString(new Date()),
          endDate: '',
          file: { ...file },
        };
      });

      if (waitingList.length < 1) return;

      // 전체
      if (type === ScreenType.all) {
        setWaitingList(waitingList);
        setSelectedId(waitingList[0].id);
      }

      // 가로/세로
      const filteredWaitingList = waitingList.filter(item => item.screenType === type);

      if (filteredWaitingList.length < 1) return;
      setWaitingList(filteredWaitingList);
      setSelectedId(filteredWaitingList[0]?.id);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          setIsOpenAlert(true);
          setAlertMessage('오류가 발생했습니다.');
        }
      }
    }
  };

  useEffect(() => {
    filterWaitingListByScreenType();
  }, [type]);

  return (
    <>
      <div className={`flex flex-col w-full gap-4`}>
        <FilterContainer handleScreenType={handleScreenType} />

        <ContentContainer selectedId={selectedId} handleSelectedId={handleSelectedId} />

        <ScreensaverAddButtons selectedId={selectedId} />
      </div>

      {isOpenAlert && <AlertPopup message={alertMessage} onClick={() => setIsOpenAlert(false)} />}
    </>
  );
};
export default ScreensaverAddContainer;
