import axios, { AxiosError, AxiosInstance } from 'axios';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
  put<T>(...params: Parameters<AxiosInstance['put']>): Promise<T>;
  delete<T>(...params: Parameters<AxiosInstance['delete']>): Promise<T>;
}

export const useAxiosInternal = () => {
  const navigate = useNavigate();

  const api: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });

  api.interceptors.request.use(
    async res => {
      const config = res;
      config.headers['Content-Type'] = 'application/json; charset=utf-8';
      config.headers['API-KEY'] = process.env.REACT_APP_ADMIN_INTERNAL_API_KEY;

      return config;
    },
    error => Promise.reject(error),
  );

  api.interceptors.response.use(
    response => {
      return response.data;
    },
    (error: AxiosError) => {
      navigate(RoutePath.internalError);
      throw error;
    },
  );

  return { api };
};
