/* eslint-disable no-console */
import * as StompJs from '@stomp/stompjs';
import { useEffect, useRef, useState } from 'react';
import SockJS from 'sockjs-client';
import useAccountStore from 'stores/account';
import { UpdatedMachine } from 'types/feature/menu/machine/machine';

type Props = {
  callback: () => Promise<void>;
  pointId: string;
};

const useMachineSocket = (props: Props) => {
  const { account } = useAccountStore();
  const stompClientRef = useRef<StompJs.Client>();

  const [updatedMachine, setUpdatedMachine] = useState<UpdatedMachine>({
    machineId: '',
    connected: false,
  });

  /**
   * socket 이 연결되었을 때 목록을 다시 fetch 하도록 처리한다
   * 연결이 끊어졌다가 다시 연결되는 경우가 있기 때문
   */
  const connectedMachineAndSocket = async () => {
    console.info('socket is connected.');

    const subscribeUrl = `/topic/points/${props.pointId}/machine-connect`;

    if (stompClientRef.current) {
      stompClientRef.current.subscribe(subscribeUrl, message => {
        if (message.body) {
          const connectedMessage = JSON.parse(message.body);
          setUpdatedMachine(connectedMessage);
        }
      });

      await props.callback();
    }
  };

  /**
   * socket 연결이 실패했을 경우 목록을 다시 fetch 한다
   */
  const closeMachineAndSocket = async () => {
    console.warn('socket is closed.');
    await props.callback();
  };

  const makeConnectionMachineAndSocket = () => {
    const socketUrl = process.env.REACT_APP_WS_URL;
    const client = new StompJs.Client({
      webSocketFactory: () => new SockJS(`${socketUrl}/ws-notification`),
      beforeConnect: () => {
        client.connectHeaders = {
          Authorization: process.env.REACT_APP_AUTHORIZATION_KEY || 'no-key',
          userId: account?.id || 'no-account',
          connectType: 'ADMIN',
        };
      },
      reconnectDelay: 1000 * 10, // 10초
      onConnect: connectedMachineAndSocket,
      onWebSocketClose: closeMachineAndSocket,
    });

    client.activate();
    stompClientRef.current = client;
  };

  /**
   * pointId 가 필수값이다
   */
  useEffect(() => {
    if (props.pointId) {
      makeConnectionMachineAndSocket();
    }
  }, [props.pointId]);

  useEffect(() => {
    return () => {
      stompClientRef.current?.deactivate();
    };
  }, []);

  return {
    makeConnectionMachineAndSocket,
    updatedMachine,
  };
};

export default useMachineSocket;
