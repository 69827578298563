import Label from 'components/common/label/Label';
import ScreensaverAddTable from './table/ScreensaverAddTable';
import ThumbnailContainer from './thumbnail/ThumbnailContainer';

type ContentContainerProps = {
  selectedId: string;
  handleSelectedId: (id: string) => void;
};

const ContentContainer = ({ selectedId, handleSelectedId }: ContentContainerProps) => {
  return (
    <div className='flex flex-col gap-4'>
      <div className='w-52'>
        <Label labelTextSize='h4_bold' name='콘텐츠' required />
      </div>

      <div className='flex h-[420px] gap-4'>
        <ScreensaverAddTable selectedId={selectedId} handleSelectedId={handleSelectedId} />

        <ThumbnailContainer selectedId={selectedId} />
      </div>
    </div>
  );
};
export default ContentContainer;
