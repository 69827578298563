import useKeyboard from 'hooks/common/useKeyboard';
import { ModalType } from 'types/common/modal';
import React from 'react';
import ModalContainer from 'components/common/modal/ModalContainer';
import { TextType } from 'types/common/text';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';

type Props = {
  message: string | undefined;
  onClickCancel: (e?: any) => void;
  onRetry: () => Promise<void>;
  disabled?: boolean;
};

const RetryPopup = ({ message, onClickCancel, onRetry, disabled }: Props) => {
  useKeyboard({ modalType: ModalType.confirm, closeModal: onClickCancel, callback: onRetry });

  return (
    <ModalContainer>
      <div className='z-50 flex flex-col items-center justify-center gap-8 py-20 bg-primary-White px-28'>
        <pre className={`${TextType.body1} font-pretendard break-all max-w-sm whitespace-pre-wrap text-center`}>
          {message}
        </pre>
        <div className='flex items-center justify-center w-full gap-2'>
          <Button text='취소' size={120} color={ButtonColor.secondary} onClick={onClickCancel} />
          <Button
            text='재시도'
            disabled={disabled}
            size={120}
            color={disabled ? ButtonColor.primary_disable : ButtonColor.primary}
            onClick={onRetry}
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default RetryPopup;
