import { MenuType } from 'types/common/menus';
import { RoutePath } from 'types/common/paths';

export const GLOBAL_MENUS: MenuType[] = [
  {
    id: '콘텐츠 관리',
    path: RoutePath.content,
  },
  {
    id: '광고 관리',
    path: RoutePath.screensaver,
  },
  {
    id: '매장 관리',
    path: RoutePath.tenantManage,
  },
  {
    id: '카테고리 관리',
    path: RoutePath.floorCategoryManage,
    subMenus: [
      {
        id: '층별안내',
        path: RoutePath.floorCategoryManage,
      },
      {
        id: '매장검색',
        path: RoutePath.tenantCategoryManage,
      },
      {
        id: '편의시설',
        path: RoutePath.facilityCategoryManage,
      },
    ],
  },
  {
    id: '메뉴 관리',
    path: RoutePath.menuSetting,
  },
  {
    id: '기기 관리',
    path: RoutePath.machine,
  },
  {
    id: '기기 상태',
    path: RoutePath.monitoring,
  },
];
