import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FileInput from 'components/common/file/input/FileInput';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import AlertPopup from 'components/common/popup/AlertPopup';
import SelectBox from 'components/common/select/SelectBox';
import TenantDetail from 'components/feature/tenant/detail-container/TenantDetails';
import TenantsTable from 'components/feature/tenant/table/TenantsTable';
import useTenant from 'hooks/feature/tenant/useTenant';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tenant } from 'types/feature/tenant/getTenant';
import { Point } from 'types/feature/workspace/workspace';

const TenantPage = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [isClickedRow, setClickedRow] = useState<boolean>(false);
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [visibleFoldIcon, setVisibleFoldIcon] = useState<boolean>(false);

  const [pointList, setPointList] = useState<Point[]>([]);
  const [currentPointName, setCurrentPointName] = useState<string>('');
  const [currentPointId, setCurrentPointId] = useState<string>();

  const { getTenants, downloadTenantsCsv, uploadTenantsCsv } = useTenant();
  const { getWorkspace } = useWorkspace();
  const { workspaceId } = useParams();

  const fileRef = useRef<HTMLInputElement>(null);

  const fetchTenants = async () => {
    try {
      if (workspaceId) {
        const result = await getWorkspace(workspaceId);

        if (result) {
          const pointId = currentPointId || result.data.points[0].id;
          const pointName = currentPointName || result.data.points[0].name.ko;

          const resultOfTenants: Tenant[] | undefined = await getTenants(pointId);
          if (resultOfTenants) {
            const currentTenant: Tenant | null = resultOfTenants.find(item => item.id === selectedTenant?.id) ?? null;
            setTenants(resultOfTenants);
            setCurrentPointId(pointId);
            setCurrentPointName(pointName);
            setPointList(result.data.points);
            setSelectedTenant(currentTenant);
          }
        }
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response?.status === 400) {
        setIsOpenAlert(true);
        setAlertMessage('오류가 발생하였습니다.');
      }
    }
  };

  const handleSelectedTenant = (tenant: Tenant) => {
    handleClickedRow();
    setSelectedTenant(tenant);
  };

  const handleClickedRow = async () => {
    setClickedRow(true);
    openDetail();
  };

  const openDetail = () => {
    setVisibleFoldIcon(true);
  };

  const closeDetail = () => {
    setClickedRow(false);
  };

  const handleChangePoint = async (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const selectedOption = e.target.selectedOptions[0];
    const { label } = selectedOption;

    setCurrentPointName(label);
    setCurrentPointId(value);

    const resultOfTenants: Tenant[] | undefined = await getTenants(value);
    if (resultOfTenants) {
      setTenants(resultOfTenants);
      setSelectedTenant(null);
    }
  };

  const handleCsvDownload = async () => {
    if (currentPointId) {
      const result = await downloadTenantsCsv(currentPointId);
      if (result) {
        const url = URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.download = `tenants-${new Date().getTime()}.csv`;
        link.click();
        URL.revokeObjectURL(url);
      }
    }
  };

  const handleCsvUpload = async () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleChangeFile = async (file: File) => {
    if (currentPointId) {
      await uploadTenantsCsv(file, currentPointId);
      setIsOpenAlert(true);
      setAlertMessage('업로드가 완료 되었습니다.');
      fetchTenants();
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  return (
    <>
      <div className='flex flex-col justify-between w-full gap-10'>
        <div className='flex justify-between w-full'>
          <FlexFormControl
            name='빌딩'
            labelSize='sm'
            control={
              <SelectBox
                width='w-40'
                options={pointList.map(({ id, name }) => {
                  return {
                    id,
                    value: name.ko,
                  };
                })}
                onChange={handleChangePoint}
              />
            }
          />

          <div className='flex gap-2.5'>
            {/* 엑셀 다운로드 */}
            <Button text='CSV 다운로드' color={ButtonColor.secondary} onClick={handleCsvDownload} size='add' />

            <div className='hidden'>
              <FileInput acceptType='.csv' name='CSV Upload' onChangeFile={handleChangeFile} fileRef={fileRef} />
            </div>

            {/* 엑셀 업로드 */}
            <Button text='CSV 업로드' color={ButtonColor.secondary} onClick={handleCsvUpload} size='add' />
          </div>
        </div>

        <div className='flex justify-between w-full gap-6 h-[640px]'>
          {/* 테이블 */}
          <TenantsTable
            pointName={currentPointName}
            pointId={currentPointId || ''}
            tenants={tenants}
            selectedTenantId={selectedTenant?.id ?? ''}
            handleSelectedTenant={handleSelectedTenant}
          />

          {/* 테넌트 상세 */}
          {isClickedRow && (
            <TenantDetail
              fetchTenants={fetchTenants}
              tenant={selectedTenant}
              closeDetail={closeDetail}
              visibleFoldIcon={visibleFoldIcon}
            />
          )}
        </div>
      </div>

      {isOpenAlert && <AlertPopup message={alertMessage} onClick={() => setIsOpenAlert(false)} />}
    </>
  );
};
export default TenantPage;
