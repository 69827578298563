export enum FileUploadType {
  'COMMON' = 'COMMON',
  'CONTENT_IMAGE' = 'CONTENT_IMAGE',
  'TENANT_IMAGE' = 'TENANT_IMAGE',
  'CONTENT_VIDEO' = 'CONTENT_VIDEO',
  'CUSTOM_BOARD_IMAGE' = 'CUSTOM_BOARD_IMAGE',
  'CUSTOM_BOARD_CONTENT_IMAGE' = 'CUSTOM_BOARD_CONTENT_IMAGE',
  'LOGO_IMAGE' = 'LOGO_IMAGE',
}

export type PatchFile = {
  name: string;
  originalName: string;
  delete: boolean;
};

export const UploadFileType = {
  COMMON: 'COMMON',
  CONTENT_IMAGE: 'IMAGE',
  CONTENT_VIDEO: 'VIDEO',
  TENANT_IMAGE: 'IMAGE',
  CUSTOM_BOARD_IMAGE: 'CUSTOM_BOARD_IMAGE',
  CUSTOM_BOARD_CONTENT_IMAGE: 'CUSTOM_BOARD_CONTENT_IMAGE',
  LOGO_IMAGE: 'LOGO_IMAGE',
};
