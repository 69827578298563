import CommonFileInput from 'components/common/file/CommonFileInput';
import LoadingBackground from 'components/common/loading/LoadingBackground';
import AlertPopup from 'components/common/popup/AlertPopup';
import { IMAGES } from 'constants/images';
import useFileUpload from 'hooks/common/useFileUpload';
import useCustomPage from 'hooks/feature/custom-page/useCustomPage';
import { ChangeEvent, useState } from 'react';
import { FileUploadType } from 'types/common/file/fileUpload';
import { CustomBoardContent, CustomUploadFileType, IMAGE } from 'types/feature/custom-page/customPage';
import { validateFileType, validateFileVolume } from 'utils/file/validate-type/validateFileType';

type Props = {
  customBoard: CustomBoardContent;
  uploadName: string;
  getCustomBoards: () => Promise<void>;
};

const UpdateCustomFile = ({ customBoard, uploadName, getCustomBoards }: Props) => {
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [mainFileName, setMainFileName] = useState(customBoard.mainImage.originalName);
  const [contentFileName, setContentFileName] = useState(customBoard.contentImage.originalName);
  const [fileLoading, setFileLoading] = useState(false);
  const { updateCustomBoard } = useCustomPage();
  const { uploadFile } = useFileUpload();

  const resetFileName = () => {
    setIsEdit(false);
    if (uploadName === IMAGE.MAIN_IMAGE) {
      return setMainFileName(customBoard.mainImage.originalName);
    }
    setContentFileName(customBoard.contentImage.originalName);
  };

  const handleChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { files, name } = e.target;

      setFileLoading(true);

      if (files) {
        if (!validateFileType(files[0], CustomUploadFileType[name] as FileUploadType)) {
          setAlertMessage('파일 확장자를 확인해주세요.');
          setOpenAlertPopup(true);
          setFileLoading(false);
          return;
        }

        const uploadFileVolumeChecked = validateFileVolume(files[0].size, CustomUploadFileType[name] as FileUploadType);
        if (uploadFileVolumeChecked) {
          if (!uploadFileVolumeChecked.enableUpload) {
            setOpenAlertPopup(true);
            setAlertMessage(uploadFileVolumeChecked.message);
            setFileLoading(false);
            resetFileName();
            return;
          }
        }
        // 파일 업로드
        const result = await uploadFile(files[0], CustomUploadFileType[uploadName] as FileUploadType);
        if (result) {
          setFileLoading(false);
          // 업데이트

          await updateCustomBoard(customBoard.id, {
            [name]: {
              name: result.fileName,
              originalName: files[0].name,
            },
          });
          await getCustomBoards();
          setIsEdit(false);
        }
      }
    } catch (error) {
      setFileLoading(false);
    }
  };

  const handleChangeFileName = (file: File) => {
    if (uploadName === IMAGE.MAIN_IMAGE) {
      return setMainFileName(file.name);
    }
    setContentFileName(file.name);
  };

  const deleteFileName = () => {
    if (uploadName === IMAGE.MAIN_IMAGE) {
      return setMainFileName('');
    }
    setContentFileName('');
  };

  return (
    <>
      <div className={`w-96`}>
        {isEdit ? (
          <div className={`w-full flex gap-3 items-center justify-between h-9`}>
            <CommonFileInput
              id={uploadName}
              name={uploadName}
              fileName={uploadName === IMAGE.MAIN_IMAGE ? mainFileName : contentFileName}
              onChangeFile={e => {
                const { files } = e.target;
                if (files) {
                  handleChangeFileName(files[0]);
                  handleChangeFile(e);
                }
              }}
              onDeleteFile={deleteFileName}
              border={isEdit}
            />

            <div onClick={resetFileName} className='w-[20px] cursor-pointer'>
              <img src={IMAGES.CANCEL} alt='취소' />
            </div>
          </div>
        ) : (
          <div className={`w-full flex items-center gap-3 h-9 px-2`}>
            <div className={`w-full text-gray-99 text-sm`}>
              {uploadName === IMAGE.MAIN_IMAGE ? mainFileName : contentFileName}
            </div>

            <div onClick={() => setIsEdit(!isEdit)} className='cursor-pointer'>
              <img src={IMAGES.PENCIL} alt='수정' />
            </div>
          </div>
        )}
      </div>
      {fileLoading && <LoadingBackground message='파일을 업로드 중입니다.' />}
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default UpdateCustomFile;
