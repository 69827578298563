import { Badges } from 'components/common/badge/badge.type';
import useTenant from 'hooks/feature/tenant/useTenant';
import { useEffect, useState } from 'react';
import { TextType } from 'types/common/text';

type DisplayToggleButtonProps = {
  toggleOn: boolean;
  tenantId: string;
  fetchTenants: () => Promise<void>;
};

const DisplayToggleButton = ({ toggleOn, tenantId, fetchTenants }: DisplayToggleButtonProps) => {
  const [toggleText, setToggleText] = useState<string>(toggleOn ? '사용' : '미사용');
  const [toggle, setToggle] = useState<boolean>(toggleOn);

  const { updateTenantDetail } = useTenant();

  const onToggle = async () => {
    if (toggle) {
      setToggle(false);
      setToggleText('미사용');
      await updateAndRefetchTenantDetail(false);
    } else {
      setToggle(true);
      setToggleText('사용');
      await updateAndRefetchTenantDetail(true);
    }
  };

  const updateAndRefetchTenantDetail = async (used: boolean) => {
    await updateTenantDetail(tenantId, {
      used,
    });

    await fetchTenants();
  };

  useEffect(() => {
    setToggle(toggleOn);
    setToggleText(toggleOn ? '사용' : '미사용');
  }, [tenantId]);

  return (
    <div
      onClick={onToggle}
      className={`flex items-center justify-center h-[32px] px-5 rounded-full cursor-pointer 
        ${TextType.h5}
        ${toggle ? Badges.SUCCESS : Badges.DISABLED}`}
    >
      {toggleText}
    </div>
  );
};
export default DisplayToggleButton;
