import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import Calendar from 'components/common/calendar/Calendar';
import CommonFileInput from 'components/common/file/CommonFileInput';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Label from 'components/common/label/Label';
import AlertPopup from 'components/common/popup/AlertPopup';
import DisplaySelectBox from 'components/feature/ad/screensaver/add/content/table/display/select/DisplaySelectBox';
import dayjs from 'dayjs';
import useFileUpload from 'hooks/common/useFileUpload';
import { ChangeEvent, useState } from 'react';
import { DisplayType } from 'types/common/display';
import { FileUploadType } from 'types/common/file/fileUpload';
import { CustomUploadFileType, PostCustomPage } from 'types/feature/custom-page/customPage';
import { MultiLangs } from 'types/feature/language/language';
import { validateFileType, validateFileVolume } from 'utils/file/validate-type/validateFileType';
import CustomContentMultiLangInputs from './multi-lang/CustomContentMultiLangInputs';

type Props = {
  setCustomPageForm: (form: any) => void;
  title: MultiLangs;
};
const CustomPageAddForm = ({ setCustomPageForm, title }: Props) => {
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [mainFileName, setMainFileName] = useState('');
  const [subFileName, setSubFileName] = useState('');
  const [displayRangeType, setDisplayRangeType] = useState(DisplayType.always);

  const { uploadFile } = useFileUpload();

  const handleChangeTitles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomPageForm((prev: PostCustomPage) => ({
      ...prev,
      title: {
        ...prev.title,
        [e.target.id as string]: e.target.value,
      },
    }));
  };

  const resetFileName = (uploadFileType: FileUploadType) => {
    if (uploadFileType === FileUploadType.CUSTOM_BOARD_IMAGE) {
      setMainFileName('');
      return;
    }
    setSubFileName('');
  };

  // 업로드
  const handleChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { files, name } = e.target;

      if (files) {
        if (!validateFileType(files[0], CustomUploadFileType[name] as FileUploadType)) {
          setAlertMessage('파일 확장자를 확인해주세요.');
          setOpenAlertPopup(true);
          resetFileName(CustomUploadFileType[name] as FileUploadType);
          return;
        }

        const uploadFileVolumeChecked = validateFileVolume(files[0].size, CustomUploadFileType[name] as FileUploadType);
        if (uploadFileVolumeChecked) {
          if (!uploadFileVolumeChecked.enableUpload) {
            setOpenAlertPopup(true);
            setAlertMessage(uploadFileVolumeChecked.message);
            resetFileName(CustomUploadFileType[name] as FileUploadType);
            return;
          }
        }

        const result = await uploadFile(files[0], CustomUploadFileType[name] as FileUploadType);

        if (result) {
          setCustomPageForm((prev: any) => ({
            ...prev,
            [name]: {
              name: result.fileName,
              originalName: files[0].name,
            },
          }));
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setOpenAlertPopup(true);
        setAlertMessage(error.message);
      }
    }
  };

  const handleDeleteMainFile = () => {
    setMainFileName('');
  };

  const handleDeleteSubFile = () => {
    setSubFileName('');
  };

  // 노출 유형 기간 타입 변경
  const handleChangeDisplayRangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    const displayType = e.target.value as DisplayType;

    setEndDate(null);
    setCustomPageForm((prev: any) => ({
      ...prev,
      startDate: null,
      endDate: null,
    }));

    setDisplayRangeType(displayType);
    setCustomPageForm((prev: any) => ({ ...prev, displayType: e.target.value }));
  };

  const handleDate = (date: Date | null) => {
    setStartDate(date);
    setCustomPageForm((prev: any) => ({
      ...prev,
      startDate: dayjs(date).format('YYYY-MM-DD'),
      endDate: dayjs(date).format('YYYY-MM-DD'),
    }));
  };

  const handleRangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
    setCustomPageForm((prev: any) => ({
      ...prev,
      startDate: dayjs(start).format('YYYY-MM-DD'),
      endDate: dayjs(end).format('YYYY-MM-DD'),
    }));
  };

  return (
    <>
      <div className='w-full'>
        <div className='flex flex-col items-start gap-10'>
          <Border />

          <div className='grid items-start w-full grid-cols-2 gap-3'>
            <div className='flex items-start justify-start flex-1'>
              <div className='flex items-center h-9'>
                <Label labelSize='md' labelTextSize='h4_bold' name='타이틀' />
              </div>
              <CustomContentMultiLangInputs handleChangeTitles={handleChangeTitles} titles={title} />
            </div>

            <div className='flex flex-1'>
              <FlexFormControl
                name='노출 타입'
                required
                labelTextSize='h4_bold'
                control={
                  <div className='flex gap-2'>
                    <DisplaySelectBox
                      selected={displayRangeType}
                      handleChange={handleChangeDisplayRangeType}
                      disabled={false}
                    />
                    <Calendar
                      displayType={displayRangeType}
                      disabled={false}
                      handleDate={handleDate}
                      handleRange={handleRangeDate}
                      startDate={startDate}
                      endDate={endDate}
                      hasIcon={false}
                    />
                  </div>
                }
              />
            </div>
          </div>

          <Border />

          <div className='grid items-start w-full grid-cols-2 gap-3'>
            <FlexFormControl
              name='목록 이미지'
              required
              labelTextSize='h4_bold'
              labelSize='md3'
              control={
                <CommonFileInput
                  id='mainImage'
                  name='mainImage'
                  fileName={mainFileName}
                  border
                  onChangeFile={e => {
                    const { files } = e.target;
                    if (files) {
                      setMainFileName(files[0].name);
                      handleChangeFile(e);
                    }
                  }}
                  onDeleteFile={handleDeleteMainFile}
                />
              }
            />

            <FlexFormControl
              name='상세 이미지'
              required
              labelTextSize='h4_bold'
              control={
                <CommonFileInput
                  id='contentImage'
                  name='contentImage'
                  fileName={subFileName}
                  border
                  onChangeFile={e => {
                    const { files } = e.target;
                    if (files) {
                      setSubFileName(files[0].name);
                      handleChangeFile(e);
                    }
                  }}
                  onDeleteFile={handleDeleteSubFile}
                />
              }
            />
          </div>

          <Border />
        </div>
      </div>
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default CustomPageAddForm;
