import FormControl from 'components/common/form-control/FormControl';
import SelectBox from 'components/common/select/SelectBox';
import { SCREEN_FILTER } from 'data/filter';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { LocationForm } from 'types/feature/menu/machine/machine';

type Props = {
  handleChangeScreenType: (e: ChangeEvent<HTMLSelectElement>) => void;
  setMachinePlaceForm: Dispatch<SetStateAction<LocationForm>>;
};

const MachineTypeController = ({ handleChangeScreenType, setMachinePlaceForm }: Props) => {
  const handleChangeMachineScreenType = (e: ChangeEvent<HTMLSelectElement>) => {
    handleChangeScreenType(e);
    setMachinePlaceForm((prev: LocationForm) => ({
      ...prev,
      screenType: e.target.value,
    }));
  };
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='기기 타입'
        control={
          <SelectBox
            width='w-[100px]'
            height='h-[30px]'
            options={SCREEN_FILTER}
            onChange={handleChangeMachineScreenType}
          />
        }
      />
    </div>
  );
};

export default MachineTypeController;
