import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import AlertPopup from 'components/common/popup/AlertPopup';
import Title from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWorkspaceStore from 'stores/workspace';
import { RegisterMachine } from 'types/feature/menu/machine/machine';
import { handle400Error } from 'utils/error/handle400Error';
import { changeCameraFocus } from 'utils/map/mapControl';

type Props = {
  registeredMachine?: RegisterMachine;
  fetchWorkspaceAndMachine?: () => Promise<void>;
};

// center
const CenterEditController = ({ registeredMachine, fetchWorkspaceAndMachine }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const { patchMachine } = useMachine();
  const { machineId } = useParams();
  const { workspaceMachineType } = useWorkspaceStore();
  const [position, setPosition] = useState({
    centerPositionX: registeredMachine?.mapPosition.centerPositionX,
    centerPositionY: registeredMachine?.mapPosition.centerPositionY,
  });
  const centerXRef = useRef<HTMLInputElement>(null);
  const centerYRef = useRef<HTMLInputElement>(null);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleChangeCenter = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setPosition((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const updateCenter = async () => {
    try {
      if (machineId) {
        if (isEmpty(centerXRef.current?.value) || isEmpty(centerYRef.current?.value)) {
          setOpenAlertPopup(true);
          setAlertMessage('입력된 값이 없습니다.');
          return;
        }
        await patchMachine(workspaceMachineType, machineId, {
          mapPosition: {
            centerPositionX: position.centerPositionX,
            centerPositionY: position.centerPositionY,
          },
        });

        if (fetchWorkspaceAndMachine) {
          await fetchWorkspaceAndMachine();
          setEditMode(false);
        }
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
      setAlertMessage('오류가 발생했습니다.');
    }
  };

  useEffect(() => {
    changeCameraFocus({ x: Number(position.centerPositionX), y: Number(position.centerPositionY) });
  }, [position.centerPositionX, position.centerPositionY]);

  return (
    <div className='flex flex-col gap-2.5 p-5 border-b'>
      <div className='flex justify-between h-6'>
        <Title text='Center' titleType='h4_bold' textColor='text-gray-77' />
        {editMode ? (
          <div className='flex gap-2.5'>
            <div className='h-6' onClick={updateCenter}>
              <img className='h-full' src={ICON.Success} alt='edit' />
            </div>
            <div className='h-6' onClick={() => setEditMode(false)}>
              <img className='h-full' src={ICON.Cancel} alt='edit' />
            </div>
          </div>
        ) : (
          <div className='h-6' onClick={() => setEditMode(!editMode)}>
            <img className='h-full' src={ICON.Pencil} alt='edit' />
          </div>
        )}
      </div>
      <div className='flex gap-5'>
        <FormControl
          labelTextSize='h5'
          labelSize={50}
          name='X'
          control={
            editMode ? (
              <Input
                inputRef={centerXRef}
                width='w-[60px]'
                height='h-[30px]'
                name='centerPositionX'
                defaultValue={registeredMachine?.mapPosition.centerPositionX}
                onChange={handleChangeCenter}
              />
            ) : (
              <div className='flex items-center bg-bg-f9 w-[60px] h-[30px] px-2 text-h5 text-gray-99 '>
                {registeredMachine?.mapPosition.centerPositionX}
              </div>
            )
          }
        />
        <FormControl
          labelTextSize='h5'
          labelSize={50}
          name='Y'
          control={
            editMode ? (
              <Input
                inputRef={centerYRef}
                width='w-[60px]'
                height='h-[30px]'
                name='centerPositionY'
                defaultValue={registeredMachine?.mapPosition.centerPositionY}
                onChange={handleChangeCenter}
              />
            ) : (
              <div className='flex items-center bg-bg-f9 w-[60px] h-[30px] px-2 text-h5 text-gray-99 '>
                {registeredMachine?.mapPosition.centerPositionY}
              </div>
            )
          }
        />
      </div>
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </div>
  );
};

export default CenterEditController;
