import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import AlertPopup from 'components/common/popup/AlertPopup';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import { MENU_SETTING_GUIDE } from 'data/guide/menu';
import useMenu from 'hooks/feature/menu/useMenu';
import usePoint from 'hooks/feature/point/usePoint';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePointStore from 'stores/point';
import { MainMenu, MainMenuIcon, PostMainMenu } from 'types/feature/menu/menu';
import { Point } from 'types/feature/workspace/workspace';
import MenuList from './list/MenuList';
import CreateMenuPopup from './popup/CreateMenuPopup';

const MenuSettingContainer = () => {
  const [openMenuPopup, setOpenMenuPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [mainMenus, setMainMenuList] = useState<MainMenu[]>([]);
  const [menuIcons, setMainMenuIconList] = useState<MainMenuIcon[]>([]);

  const { workspaceId } = useParams();

  const { getPoints } = usePoint();
  const { getMainMenus, getMainMenuIconTypes, postMainMenu } = useMenu();

  const pointStore = usePointStore();

  // 메뉴 생성 갯수 제한
  const MENU_MAX = 6;

  // 포인트 목록 조회
  const fetchPoints = async () => {
    try {
      if (workspaceId) {
        const pointList = await getPoints(workspaceId);

        if (pointList && pointList.length > 0) {
          // 포인트 목록 조회
          pointStore.setPoints(pointList);

          // 현재 포인트 설정
          pointStore.setCurrentPoint(pointList[0]);

          // 메인 메뉴 목록 조회
          await fetchMainMenus(pointList[0].id);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage('포인트를 불러올 수 없습니다.');
        }
      }
    }
  };

  // 포인트 선택
  const onChangePoint = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const point = JSON.parse(e.target.value) as Point;
    pointStore.setCurrentPoint(point);

    await fetchMainMenus(point.id);
  };

  // 메뉴 생성 버튼 클릭 시
  const onClickAddMenu = () => {
    setOpenMenuPopup(true);
  };

  // 메뉴 등록
  const createMainMenu = async (pointId: string, menu: PostMainMenu) => {
    try {
      await postMainMenu(pointId, menu);
      await fetchMainMenus(pointId);
      setOpenMenuPopup(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setAlertMessage('메뉴를 추가하는 중에 문제가 발생하였습니다. \n다시 시도해주세요.');
          setOpenAlertPopup(true);
        }
      }
    }
  };

  // 메인 메뉴 목록 조회
  const fetchMainMenus = async (pointId: string) => {
    try {
      const result = await getMainMenus(pointId || '');
      setMainMenuList(result.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setAlertMessage('메뉴를 조회하는 중에 문제가 발생하였습니다. \n다시 시도해주세요.');
          setOpenAlertPopup(true);
        }
      }
    }
  };

  // 메인 메뉴 아이콘 목록 조회
  const fetchMainMenuIcons = async () => {
    const mainMenuIcons = await getMainMenuIconTypes();

    if (mainMenuIcons) {
      setMainMenuIconList(mainMenuIcons);
    }
  };

  useEffect(() => {
    fetchMainMenuIcons();
  }, []);

  useEffect(() => {
    if (workspaceId) {
      fetchPoints();
    }
  }, [workspaceId]);

  return (
    <div className='flex flex-col items-start justify-start w-full gap-10'>
      <div className='flex items-center justify-between w-full'>
        <GuideDescription guides={MENU_SETTING_GUIDE} />

        {/* 메뉴는 6개까지 생성 가능 */}
        {mainMenus.length < MENU_MAX && (
          <Button text='메뉴 생성' color={ButtonColor.secondary} size='add' onClick={onClickAddMenu} />
        )}
      </div>

      <div>
        <FlexFormControl
          name='빌딩'
          labelSize='md'
          control={
            <CommonSelectBox
              width='w-44'
              options={pointStore.points.map(point => ({
                id: point.id,
                value: point.name.ko,
                data: point,
              }))}
              onChange={onChangePoint}
            />
          }
        />
      </div>

      {/* 메뉴 리스트 */}
      <MenuList menuIcons={menuIcons} mainMenus={mainMenus} fetchMainMenus={fetchMainMenus} />

      {/* 메뉴 등록 팝업 */}
      {openMenuPopup && (
        <CreateMenuPopup
          createMainMenu={createMainMenu}
          menuIcons={menuIcons}
          closePopup={() => setOpenMenuPopup(false)}
          mainMenus={mainMenus}
        />
      )}

      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </div>
  );
};

export default MenuSettingContainer;
