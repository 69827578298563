import React from 'react';
import { FontType } from 'types/common/text';
import Label, { LabelSizeConstant } from '../label/Label';

type FormControlProps = {
  name: string;
  disabled?: boolean;
  required?: boolean;
  control: React.ReactElement;
  direction?: 'column' | 'row';
  gap?: string;
  labelTextSize?: FontType;
  labelSize?: keyof typeof LabelSizeConstant;
};

const FormControl = ({
  name,
  required,
  control,
  disabled,
  direction = 'row',
  gap,
  labelSize = 'md2',
  labelTextSize = 'h4_bold',
}: FormControlProps) => {
  return (
    <div
      className={`flex ${gap} ${disabled && 'opacity-50'}
      ${direction === 'row' ? 'flex items-center' : 'flex flex-col'} 
      `}
    >
      <Label labelTextSize={labelTextSize} required={required} name={name} labelSize={labelSize} />
      {control}
    </div>
  );
};

export default FormControl;
