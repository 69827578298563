import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import ValidateInput from 'components/feature/machine/manage/add/input/ValidateInput';
import { MAKE_ACCOUNT_GUIDE, MAKE_ACCOUNT_HELPER } from 'data/guide/makeAccount';
import { PASSWORD_REGEX } from 'data/regex';
import useMachine from 'hooks/feature/machine/useMachine';
import { ChangeEvent, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWorkspaceStore from 'stores/workspace';
import Button from '../../../../../common/button/Button';
import { ButtonColor } from '../../../../../common/button/Button.type';
import FormControl from '../../../../../common/form-control/FormControl';
import Input from '../../../../../common/input/Input';
import ModalContainer from '../../../../../common/modal/ModalContainer';

type Props = {
  onClickCancel: (e?: any) => void;
};

const ChangePasswordPopup = ({ onClickCancel }: Props) => {
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const { workspaceMachineType } = useWorkspaceStore();
  const { machineId } = useParams();
  const { patchMachine } = useMachine();
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [isSamePassword, setIsSamePassWord] = useState(true);

  const validateSamePassword = (e: ChangeEvent<HTMLInputElement>) => {
    if (passwordRef.current) {
      const originPassword = passwordRef.current.value;
      const { value } = e.target;
      if (value === '') {
        return setIsSamePassWord(true);
      }
      if (originPassword !== value) {
        return setIsSamePassWord(false);
      }

      if (originPassword === value) {
        return setIsSamePassWord(true);
      }
    }
  };

  const handleChangePassword = () => {
    if (machineId) {
      if (!confirmPasswordRef.current) return;
      const password = window.btoa(confirmPasswordRef.current.value);

      patchMachine(workspaceMachineType, machineId, {
        account: {
          password,
        },
      });

      onClickCancel();
    }
  };
  return (
    <ModalContainer>
      <>
        <div className='flex flex-col items-center justify-center bg-white'>
          <div className='w-full px-10 pt-8 pb-5 border-b font-h4_bold'>비밀번호 변경</div>

          <div className='px-10 py-5 flex flex-col gap-2.5 border-b'>
            <FormControl
              name='새 비밀번호'
              control={
                <ValidateInput
                  inputRef={passwordRef}
                  name='username'
                  placeholder='비밀번호 입력'
                  regexCheck={PASSWORD_REGEX}
                  guide={MAKE_ACCOUNT_GUIDE.password}
                  type='password'
                  onChange={() => {}}
                  regexGuide={MAKE_ACCOUNT_HELPER.password}
                />
              }
            />
            <FormControl
              name='비밀번호 확인'
              control={
                <div className='flex flex-col gap-[6px]'>
                  <Input
                    inputRef={confirmPasswordRef}
                    width='w-[330px]'
                    height='h-10'
                    type='password'
                    placeholder='비밀번호 재입력'
                    showWarning={!isSamePassword}
                    onChange={validateSamePassword}
                  />
                  {!isSamePassword && (
                    <div className='text-[11px] text-primary-DA_Red'>비밀번호가 일치하지 않습니다.</div>
                  )}
                </div>
              }
            />
          </div>
          <div className='flex gap-2.5 pb-8 pt-5 px-10'>
            <Button text='취소' color={ButtonColor.secondary} onClick={onClickCancel} />
            <Button
              disabled={!passwordRef.current?.value || !confirmPasswordRef.current?.value || !isSamePassword}
              text='저장'
              color={ButtonColor.primary}
              onClick={() => setOpenConfirmPopup(true)}
            />
          </div>
        </div>
        {openConfirmPopup && (
          <ConfirmPopup
            message='비밀번호를 변경하시겠습니까?'
            onClickCancel={() => setOpenConfirmPopup(false)}
            onClickOk={handleChangePassword}
          />
        )}
      </>
    </ModalContainer>
  );
};

export default ChangePasswordPopup;
