import FormControl from 'components/common/form-control/FormControl';
import AlertPopup from 'components/common/popup/AlertPopup';
import SelectBox from 'components/common/select/SelectBox';
import { ICON } from 'constants/icons';
import { SCREEN_FILTER } from 'data/filter';
import useMachine from 'hooks/feature/machine/useMachine';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWorkspaceStore from 'stores/workspace';
import { SCREEN_TYPE_VALUES } from 'types/common/screenType';
import { RegisterMachine } from 'types/feature/menu/machine/machine';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  registeredMachine?: RegisterMachine;
  setScreenType?: Dispatch<SetStateAction<string>>;
  fetchWorkspaceAndMachine?: () => Promise<void>;
};

const MachineTypeEditController = ({ registeredMachine, fetchWorkspaceAndMachine, setScreenType }: Props) => {
  const { workspaceMachineType } = useWorkspaceStore();
  const { machineId } = useParams();
  const { patchMachine, getMachine } = useMachine();
  const [machineScreenType, setMachineScreenType] = useState(registeredMachine?.screenType);
  const [editMode, setEditMode] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);

  const handleChangeMachineScreenType = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setMachineScreenType(value);
    if (setScreenType) {
      setScreenType(value);
    }
  };

  const updateScreenType = async () => {
    try {
      if (machineId) {
        await patchMachine(workspaceMachineType, machineId, {
          screenType: machineScreenType,
        });
        await getMachine(workspaceMachineType, machineId);
        if (fetchWorkspaceAndMachine) {
          fetchWorkspaceAndMachine();
        }
        setEditMode(false);
      }
    } catch (error) {
      handle400Error(error, () => setOpenAlertPopup(true));
    }
  };

  return (
    <div className='p-5 border-b'>
      <FormControl
        name='기기 타입'
        control={
          editMode ? (
            <div className='flex gap-2.5 items-center'>
              <SelectBox
                width='w-[100px]'
                height='h-[30px]'
                selectedId={registeredMachine?.screenType}
                options={SCREEN_FILTER}
                onChange={handleChangeMachineScreenType}
              />
              <div className='w-6 h-6' onClick={updateScreenType}>
                <img className='h-full' src={ICON.Success} alt='edit' />
              </div>
              <div className='w-6 h-6' onClick={() => setEditMode(false)}>
                <img className='h-full' src={ICON.Cancel} alt='edit' />
              </div>
            </div>
          ) : (
            <div className='flex gap-2.5 items-center'>
              <div className='px-2 w-[100px] h-[30px] flex items-center justify-end bg-bg-f9 text-h5 text-gray-99'>
                {SCREEN_TYPE_VALUES[registeredMachine?.screenType || '']}
              </div>
              <div className='h-6' onClick={() => setEditMode(!editMode)}>
                <img className='h-full' src={ICON.Pencil} alt='edit' />
              </div>
            </div>
          )
        }
      />
      {openAlertPopup && (
        <AlertPopup message='기기 정보를 수정하던 중 에러가 발생했습니다.' onClick={() => setOpenAlertPopup(false)} />
      )}
    </div>
  );
};

export default MachineTypeEditController;
