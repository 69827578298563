import Layout from 'components/common/layout/Layout';
import FailPopup from 'components/common/popup/FailPopup';
import ToastPopup from 'components/common/popup/ToastPopup';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLoginModalStore from 'stores/loginModal';
import useToastStore from 'stores/toast';
import { RoutePath } from 'types/common/paths';

function App() {
  // store
  const toastStore = useToastStore();
  const { isLoginModalOpen, loginModalText, setLoginModalOpen } = useLoginModalStore();

  // var
  const isLogin = localStorage.getItem('KIOSK_ADMIN_LOGIN_STATUS') === 'true';

  const navigate = useNavigate();

  const navigatePages = () => {
    if (!isLogin) {
      navigate(RoutePath.login);
    }
  };

  useEffect(() => {
    navigatePages();
  }, [isLogin]);

  useEffect(() => {
    console.log('ADMIN APP MODE =>', process.env.REACT_APP_ENVIRONMENT);
  }, []);

  return (
    <div>
      {/* <ErrorBoundary fallback={<ServerErrorPage />}> */}
      <Layout />

      {isLoginModalOpen && <FailPopup text={loginModalText} closeModal={() => setLoginModalOpen(false)} />}
      {/* 토스트 팝업 */}
      {toastStore.isToastOpen && (
        <ToastPopup
          message={toastStore.toastMessage}
          closeToastAfterSeconds={() => {
            toastStore.closeToastAfterSeconds(() => {
              toastStore.setIsToastOpen(false);
            });
          }}
        />
      )}
      {/* </ErrorBoundary> */}
    </div>
  );
}
export default App;
