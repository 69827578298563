import React from 'react';

const ScreensaverListTableHeader = () => {
  return (
    <>
      <colgroup>
        <col width='5%' />
        <col width='7%' />
        <col width='8%' />
        <col width='28%' />
        <col width='8%' />
        <col width='12%' />
        <col width='12%' />
        <col width='8%' />
        <col width='12%' />
      </colgroup>

      <thead>
        <tr className='whitespace-nowrap'>
          <th />
          <th>유형</th>
          <th>구분</th>
          <th className='px-2 text-left'>타이틀</th>
          <th>타입</th>
          <th>노출시작일</th>
          <th>노출종료일</th>
          <th>상태</th>
          <th />
        </tr>
      </thead>
    </>
  );
};
export default ScreensaverListTableHeader;
