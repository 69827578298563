import ModalContainer from 'components/common/modal/ModalContainer';
import { Point } from 'types/feature/workspace/workspace';

type ConfirmPopupProps = {
  floor: string;
  description: string;
  idSuffix: string;
  point: Point;
  onClickCancel: (e?: any) => void;
  onClickOk: ((e: React.MouseEvent<HTMLElement>) => void) | (() => void);
};

const ReStartMachinePopup = ({ description, idSuffix, floor, point, onClickCancel, onClickOk }: ConfirmPopupProps) => {
  return (
    <ModalContainer>
      <div className='flex w-[480px] py-[36px] flex-col items-start  rounded-[20px] bg-[#F1F1F1]'>
        <div className='flex px-[40px] gap-[30px] flex-col items-center self-stretch'>
          <div className='text-[#444] text-center font-pretendard text-[16px] font-medium leading-[20px] tracking-[-0.32px]'>
            해당 기기를 재시작하시겠습니까?
          </div>
          <div className='flex w-[280px] py-[12px] flex-col justify-center items-center rounded-[12px] bg-[#EAEAEA]'>
            <div className='text-[#444] font-pretendard text-[14px] font-bold leading-[24px] tracking-[-0.28px]'>
              {point.name.ko} {floor}
            </div>
            <div className='text-[#444] font-pretendard text-[14px] font-bold leading-[24px] tracking-[-0.28px]'>
              {description}
            </div>
            <div className='text-[#777] text-center font-pretendard text-[14px] font-normal leading-[24px] tracking-[-0.28px]'>
              {idSuffix}
            </div>
          </div>
          <div className='flex justify-center items-center gap-[10px] self-stretch'>
            <div
              onClick={onClickCancel}
              className='flex justify-center items-center w-[120px] h-[52px] rounded-[26px] border border-[#444] cursor-pointer'
            >
              <div className='text-[#444] text-center font-pretendard text-[14px] font-medium leading-[18px] tracking-[-0.28px]'>
                취소
              </div>
            </div>
            <div
              onClick={onClickOk}
              className='flex justify-center items-center w-[120px] h-[52px] rounded-[26px] bg-[#222] cursor-pointer'
            >
              <div className='text-[#EAEAEA] text-center font-pretendard text-[14px] font-medium leading-[18px] tracking-[-0.28px]'>
                재시작
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ReStartMachinePopup;
