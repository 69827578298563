import SelectBox from 'components/common/select/SelectBox';
import { ChangeEvent } from 'react';
import { Floor } from 'types/feature/floor/floor';
import { changeFloor } from 'utils/map/mapControl';

type Props = {
  currentFloorId?: string;
  floors: Floor[];
};

const FloorList = ({ currentFloorId, floors }: Props) => {
  const handleChangeFloor = async (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    await changeFloor(value);
  };

  return (
    <div className='absolute z-10 bg-white bottom-6 right-5'>
      <SelectBox
        width='w-[120px]'
        options={floors.map(floor => ({
          id: floor.id,
          value: floor.name.ko,
        }))}
        onChange={handleChangeFloor}
        selectedId={currentFloorId}
      />
    </div>
  );
};

export default FloorList;
