import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import useAccountStore from 'stores/account';
import useLoginModalStore from 'stores/loginModal';
import { ApiResponse } from 'types/api/response';
import { RoutePath } from 'types/common/paths';

const useHandleError = () => {
  const loginModalStore = useLoginModalStore();
  const accountStore = useAccountStore();

  const navigate = useNavigate();

  // ! Response ERROR: 서버로부터 응답이 온 경우 (예: 404, 500 등)
  const handleResponseError = (error: AxiosError) => {
    // 토큰 만료일 경우 에러처리 (401 INVALID_CREDENTIALS)
    handleInvalidError(error);

    // 그 외 에러처리 (400 ~ 500)
    handleOtherErrors(error);
  };

  // 토큰 만료일 경우 에러처리 (INVALID_CREDENTIALS)
  const handleInvalidError = (error: AxiosError) => {
    if (!error.response) return;

    const errorResponse = error.response;
    const errorData = errorResponse.data as ApiResponse<any>;

    if (errorResponse.status === 401 && errorData.error?.data === 'INVALID_CREDENTIALS') {
      localStorage.removeItem('KIOSK_ADMIN_LOGIN_STATUS');

      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        localStorage.removeItem('KIOSK_ADMIN_ACCESS');
      }

      loginModalStore.setLoginModalOpen(true);
      loginModalStore.setLoginModalText('로그인 페이지로 이동합니다.');

      accountStore.setAccount(null);

      navigate(RoutePath.login);
      throw error;
    }
  };

  // ! 나머지 axios response 에러처리
  const handleOtherErrors = (error: AxiosError) => {
    const { response } = error;

    if (!response) {
      throw error;
    }

    switch (response.status) {
      case 404:
        navigate(RoutePath.notFound);
        throw error;
      case 500:
        navigate(RoutePath.serverError);
        throw error;
      case 400:
        throw error;
      case 401:
        throw error;
      case 403:
        navigate(RoutePath.workspaces);
        throw error;
      default:
        throw error;
    }
  };

  return { handleResponseError };
};
export default useHandleError;
