import Input from 'components/common/input/Input';
import Label from 'components/common/label/Label';
import { CustomContentTitleWordLimit } from 'data/length';
import useLanguage from 'hooks/feature/language/useLanguage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Language, LanguageName, MultiLangs } from 'types/feature/language/language';

type Props = {
  handleChangeTitles: (e: React.ChangeEvent<HTMLInputElement>) => void;
  titles: MultiLangs;
};

const CustomContentMultiLangInputs = ({ handleChangeTitles, titles }: Props) => {
  const { workspaceId } = useParams();
  const { getLanguages } = useLanguage();
  const [languages, setLanguages] = useState<Language[]>([]);

  const fetchLanguages = async () => {
    if (workspaceId) {
      const result = await getLanguages(workspaceId);
      const displayLanguages: Language[] = result.filter(lang => lang.used);
      setLanguages(displayLanguages);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  return (
    <div className='flex flex-col gap-2.5 w-full'>
      {languages.map(({ code }) => (
        <div key={code} className='flex items-center gap-2.5'>
          <Label
            required={false}
            name={LanguageName[code]}
            flexStyle='justify-end'
            labelTextSize='h5_bold'
            labelSize='sm'
          />

          <Input
            width='w-[320px]'
            textStyle='h3'
            maxLength={CustomContentTitleWordLimit[code.toLowerCase()]}
            placeholder={`${LanguageName[code]} 타이틀을 입력해주세요.`}
            name='title'
            onChange={e => {
              const { length } = e.target.value;
              if (length > CustomContentTitleWordLimit[code.toLowerCase()]) return;
              handleChangeTitles(e);
            }}
            defaultValue={titles[code]}
            id={code.toLowerCase()}
          />
        </div>
      ))}
    </div>
  );
};
export default CustomContentMultiLangInputs;
