import { IMAGES } from 'constants/images';
import React, { useEffect, useRef, useState } from 'react';
import { TextType } from 'types/common/text';

type Props<T> = {
  refreshKey?: string;
  label: string | undefined;
  disabled?: boolean;
  options: T[];
  handleChangeOption: (value: string) => void;
};

type Options = {
  label: string;
  value: string;
};

const Switch = <T extends Options>({ label, disabled, options, refreshKey, handleChangeOption }: Props<T>) => {
  const [toggleOpen, setToggleOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(label);
  const switchRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideElement = (event: MouseEvent) => {
    if (switchRef.current && !switchRef.current.contains(event.target as HTMLElement)) {
      setToggleOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideElement);

    return () => {
      document.removeEventListener('click', handleClickOutsideElement);
    };
  }, []);

  const handleToggleOption = () => {
    if (disabled) return;
    setToggleOpen(!toggleOpen);
  };

  const handleClickOption = (option: Options) => {
    if (disabled) return;

    setCurrentLabel(option.label);
    handleChangeOption(option.value);

    setToggleOpen(!toggleOpen);
  };

  useEffect(() => {
    setCurrentLabel(label);
  }, [label, refreshKey]);

  return (
    <div className='relative z-10' ref={switchRef}>
      <div
        className='flex items-center justify-between h-8 gap-2 pl-3 pr-2 bg-white border rounded-full '
        onClick={handleToggleOption}
      >
        <div className={`${disabled && 'text-gray-cc'} ${TextType.h5}`}>{currentLabel}</div>
        <div className='w-[18px] h-[18px] flex items-center'>
          <img src={disabled ? IMAGES.ARROW_DOWN : IMAGES.ARROW_DOWN} alt='arrow' />
        </div>
      </div>

      {toggleOpen && (
        <div className={`gap-2 absolute top-0 left-0 rounded-2xl shadow-mdDrop border cursor-pointer bg-white`}>
          {options.map((option, optionIndex) => (
            <div id={option.label} key={optionIndex}>
              {optionIndex === 0 ? (
                <div className='flex items-center justify-between h-8 gap-2 pl-3 pr-2'>
                  <div className={`${TextType.h5}`} onClick={handleClickOption.bind(this, option)} id={option.label}>
                    {option.label}
                  </div>
                  <div className='w-[18px] h-[18px] flex items-center' onClick={handleToggleOption}>
                    <img id={option.label} src={IMAGES.ARROW_DOWN} alt='arrow-up' />
                  </div>
                </div>
              ) : (
                <div
                  onClick={handleClickOption.bind(this, option)}
                  id={option.label}
                  className={`h-8  ${TextType.h5} flex justify-start items-center pl-3 pr-2`}
                >
                  {option.label}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Switch;
