/* eslint-disable react/jsx-no-useless-fragment */

type NoContentProps = {
  visible: boolean;
  text: string;
};

const NoContent = ({ visible, text }: NoContentProps) => {
  return (
    <>
      {visible && (
        <div className='flex flex-col items-center justify-center h-[60%] gap-2 py-20'>
          <span className='text-xs text-gray-99'>{text}</span>
        </div>
      )}
    </>
  );
};
export default NoContent;
