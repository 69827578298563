import { ChangeEvent } from 'react';
import { TextType } from 'types/common/text';

export type OptionType = {
  id: string;
  value: string;
};

type Props = {
  placeholder?: string;
  selectId?: string;
  options: OptionType[];
  selected?: string;
  selectedId?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  name?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

// TODO: CommonSelectBox 로 대체
const SelectBox = ({
  selectId,
  placeholder,
  options,
  selected,
  selectedId,
  width,
  height,
  disabled,
  name,
  onChange,
}: Props) => {
  const OPTIONS = placeholder ? [{ id: '', value: placeholder }, ...options] : options;

  return (
    <select
      id={selectId}
      key={selectedId}
      name={name}
      disabled={disabled}
      className={`${width} ${height ?? 'h-9'} cursor-pointer 
        ${disabled ? 'border-none text-gray-44' : 'border-gray-99 bg-arrow bg-no-repeat'} 
        p-2 leading-none border appearance-none bg-transparent bg-[94%]
        ${TextType.h5}`}
      onChange={onChange}
      value={selected}
    >
      {OPTIONS?.map(({ id, value }) => (
        <option selected={selectedId === id} label={value} value={id} key={id}>
          {value}
        </option>
      ))}
    </select>
  );
};

export default SelectBox;
