/* eslint-disable no-nested-ternary */
import OffDark from 'components/common/badge/status/OffDark';
import On from 'components/common/badge/status/On';
import OnBlue from 'components/common/badge/status/OnBlue';
import ToastPopup from 'components/common/popup/ToastPopup';
import ReStartMachinePopup from 'components/internal/dashboard/popup/ReStartMachinePopup';
import useInternalDashboard from 'hooks/internal/useInternalDashboard';
import { useEffect, useRef, useState } from 'react';
import { ConnectedMachine } from 'types/feature/menu/machine/machine';
import { Point } from 'types/feature/workspace/workspace';
import { MachineCommand } from 'types/internal/dashboard/internalDashboardMachine';

type Props = {
  machine: ConnectedMachine;
  fetchMachines: () => Promise<void>;
  floor: string;
  point: Point;
};

const DashboardMachineItem = (props: Props) => {
  // state
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastPopupMessage] = useState('');

  // hook
  const { postInternalFileManagerMachinesCommand } = useInternalDashboard();

  // ref
  const toastTimerRef = useRef<NodeJS.Timer | null>(null);

  /**
   * 기기를 재시작한다
   */
  const onResetMachine = async () => {
    try {
      await postInternalFileManagerMachinesCommand(props.machine.id, MachineCommand.RESET);
      await props.fetchMachines();

      setOpenConfirmPopup(false);

      setIsToastOpen(true);
      setToastPopupMessage('기기 재시작을 성공했습니다.');
    } catch (error) {
      setOpenConfirmPopup(false);

      setIsToastOpen(true);
      setToastPopupMessage('기기 재시작을 실패했습니다!');
    }
  };

  /**
   * 토스트 팝업을 1.5초 뒤에 닫는다
   */
  function closeToastAfterSeconds() {
    if (toastTimerRef) {
      toastTimerRef.current = setTimeout(() => {
        setIsToastOpen(false);
        clearToastTimer();
      }, 1500);
    }
  }

  /**
   * 토스트 팝업 타이머를 제거한다
   */
  function clearToastTimer() {
    if (toastTimerRef && toastTimerRef.current) {
      clearTimeout(toastTimerRef.current);
    }
  }

  const [isActive, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (props.machine.connected && props.machine.fileManagerConnected) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [props.machine.connected, props.machine.fileManagerConnected]);

  return (
    <>
      <div
        onClick={() => {
          /**
           * 꺼져있는 기기는 제외한다
           */
          if (!props.machine.fileManagerConnected) return;
          setOpenConfirmPopup(true);
        }}
        className={`flex w-[17.5rem] h-[3.75rem] rounded-[0.75rem] items-center 
        ${props.machine.fileManagerConnected ? `cursor-pointer bg-[#43475B]` : `cursor-default bg-[#3B3E4E]`}`}
      >
        <div className='flex w-[4.5rem] h-[3.75rem] p-[0rem_0.625rem] justify-center items-center gap-2 flex-shrink-0'>
          {/* 상태 */}
          {props.machine.fileManagerConnected ? <OnBlue /> : <OffDark />}
          {props.machine.connected ? <On /> : <OffDark />}
        </div>
        <div
          className={`flex-1 ${
            isActive ? `text-[#CCCCCC]` : `text-[#828799]`
          } font-pretendard text-[0.875rem] font-bold leading-[1.5rem] tracking-[-0.0175rem]`}
        >
          {/* 상세 위치 */}
          {props.machine.description}
        </div>
        <div
          className={`w-[5rem] ${
            isActive ? `text-[#CCCCCC]` : `text-[#828799]`
          } text-center font-pretendard text-[0.875rem] font-normal leading-[1.5rem] tracking-[-0.0175rem]`}
        >
          {/* 기기 코드 */}
          {props.machine.idSuffix}
        </div>
      </div>

      {/* 기기 재시작 확인창 */}
      {openConfirmPopup && (
        <ReStartMachinePopup
          // props={props.machine}
          point={props.point}
          floor={props.floor}
          description={props.machine.description}
          idSuffix={props.machine.idSuffix}
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={onResetMachine}
        />
      )}
      {/* 성공/실패 토스트 팝업 */}
      {isToastOpen && (
        <ToastPopup message={toastMessage} closeToastAfterSeconds={closeToastAfterSeconds} border='border-none' />
      )}
    </>
  );
};
export default DashboardMachineItem;
