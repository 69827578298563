import _ from 'lodash';
import { TenantCategoryGroup } from 'types/feature/category/tenantCategory';
import { create } from 'zustand';

type CategoryStoreProps = {
  tenantCategoryGroups: TenantCategoryGroup[];
  setTenantCategoryGroups: (tenantCategoryGroups: TenantCategoryGroup[]) => void;
};

// 카테고리 refetch 함수 재사용을 위해서 store로 state 관리
const useCategoryStore = create<CategoryStoreProps>((set, get) => ({
  tenantCategoryGroups: [],
  setTenantCategoryGroups: (tenantCategoryGroups: TenantCategoryGroup[]) =>
    set(() => ({ tenantCategoryGroups: _.cloneDeep(tenantCategoryGroups) })),
}));

export default useCategoryStore;
