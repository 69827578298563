import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ModalContainer from 'components/common/modal/ModalContainer';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import useCustomFields from 'hooks/feature/custom-field/useCustomFields';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MultiLangs } from 'types/feature/language/language';
import CustomFieldMultiLangInputs from '../multi-language/CustomFieldMultiLangInputs';

type Props = {
  closePopup: () => void;
  getCustomFieldList: () => Promise<void>;
};

const AddCustomFieldPopup = ({ closePopup, getCustomFieldList }: Props) => {
  const [customFieldName, setCustomFieldName] = useState<MultiLangs>({
    ko: '',
    en: '',
    ja: '',
    cn: '',
    tw: '',
  });
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { postCustomFields } = useCustomFields();
  const { workspaceId } = useParams();

  const handleChangeCustomFieldName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCustomFieldName((prev: MultiLangs) => ({
      ...prev,
      [id as string]: value,
    }));
  };

  const onClickAddButton = () => {
    setOpenConfirmPopup(true);
  };

  // 커스텀 필드 저장
  const handleAddCustomField = async () => {
    try {
      if (workspaceId) {
        await postCustomFields(workspaceId, customFieldName);
        await getCustomFieldList();
        setOpenConfirmPopup(false);
        closePopup();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage(error.message);
        }
      }
    }
  };

  return (
    <>
      <ModalContainer>
        <div className='w-[480px]  bg-white flex flex-col'>
          <div className='w-full px-10 border-b py-7'>
            <Title text='상세항목 추가' titleType='h3_bold' />
          </div>
          <div className='px-10 border-b py-7'>
            <CustomFieldMultiLangInputs
              handleChangeMenuName={handleChangeCustomFieldName}
              customFieldName={customFieldName}
            />
          </div>
          <div className='flex justify-center gap-3 px-10 py-7 '>
            <Button text='취소' size={120} color={ButtonColor.secondary} onClick={closePopup} />
            <Button text='항목 추가' size={120} color={ButtonColor.primary} onClick={onClickAddButton} />
          </div>
        </div>
      </ModalContainer>
      {openConfirmPopup && (
        <ConfirmPopup
          message='새로운 커스텀 필드를 등록하시겠습니까?'
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={handleAddCustomField}
        />
      )}
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default AddCustomFieldPopup;
