import { create } from 'zustand';

type LoginModalStoreProps = {
  isLoginModalOpen: boolean;
  setLoginModalOpen: (isOpen: boolean) => void;

  loginModalText: string;
  setLoginModalText: (text: string) => void;
};

const useLoginModalStore = create<LoginModalStoreProps>(set => ({
  isLoginModalOpen: false,
  setLoginModalOpen: (isLoginModalOpen: boolean) => set({ isLoginModalOpen }),

  loginModalText: '',
  setLoginModalText: (loginModalText: string) => set({ loginModalText }),
}));

export default useLoginModalStore;
