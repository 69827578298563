/* eslint-disable no-console */
import { useAxios } from 'api/useAxios';
import { AxiosError } from 'axios';
import { Endpoint } from 'data/endPoint';
import { useState } from 'react';
import { ApiResponse } from 'types/api/response';
import { Floor } from 'types/feature/floor/floor';

const useFloor = () => {
  // hook
  const { api } = useAxios();

  // state
  const [floorList, setFloorList] = useState<Floor[]>([]);

  /**
   *
   * @param pointId 검색할 포인트 아이디
   * @returns {Floor[]} 층 목록
   */

  const fetchFloors = async (pointId: string): Promise<Floor[] | undefined> => {
    try {
      const result = await api.get<ApiResponse<Floor[]>>(Endpoint.floor, {
        params: {
          pointId,
        },
      });

      setFloorList(result.data);
      return result.data;
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 400) {
          console.log('층 목록을 불러올 수 없습니다', e);
        }
      }
    }
  };

  return {
    fetchFloors,
    floorList,
  };
};

export default useFloor;
