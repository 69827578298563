import AlertPopup from 'components/common/popup/AlertPopup';
import useCustomFields from 'hooks/feature/custom-field/useCustomFields';
import { useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { TenantField } from 'types/feature/setting/tenant/tenantField';
import { handleSortOrder } from 'utils/sort-order/handle/handleSortOrder';
import CustomFieldTableHeader from './header/CustomFieldTableHeader';
import CustomFieldTableRow from './row/CustomFieldTableRow';

type Props = {
  customFieldList: TenantField[];
  getCustomFieldList: () => Promise<void>;
};

const CustomFieldTable = ({ customFieldList, getCustomFieldList }: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const { patchCustomField } = useCustomFields();
  const handleDragEnd = async (dropResult: DropResult) => {
    try {
      const sourceIndex = dropResult.source.index;
      const currentItemId = customFieldList[sourceIndex].id;

      const sortOrder = handleSortOrder(dropResult, customFieldList);

      if (sortOrder) {
        await patchCustomField(currentItemId, { sortOrder });
        await getCustomFieldList();
      }
    } catch (e) {
      if (e instanceof Error) {
        setAlertMessage(e.message);
        setOpenAlertMessage(true);
      }
    }
  };
  return (
    <>
      <table>
        <CustomFieldTableHeader />

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='table'>
            {provided => {
              return (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {customFieldList
                    .sort((a, b) => a.sortOrder - b.sortOrder)
                    .map((field, index) => {
                      return (
                        <Draggable draggableId={`drag-${index}`} index={index}>
                          {draggableProvided => (
                            <CustomFieldTableRow
                              provided={draggableProvided}
                              field={field}
                              getCustomFieldList={getCustomFieldList}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </tbody>
              );
            }}
          </Droppable>
        </DragDropContext>
      </table>
      {customFieldList.length === 0 && (
        <div className='flex justify-center w-full text-sm text-gray-99'>등록된 사용자 정의 항목이 없습니다.</div>
      )}
      {openAlertMessage && <AlertPopup message={alertMessage} onClick={() => setOpenAlertMessage(false)} />}
    </>
  );
};

export default CustomFieldTable;
