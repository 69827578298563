import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import { MACHINE_PLACE_TYPE, MACHINE_STATE_TYPE } from 'data/filter';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useFloor from 'hooks/feature/floor/useFloor';
import useMachine from 'hooks/feature/machine/useMachine';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useWorkspaceStore from 'stores/workspace';
import { RoutePath } from 'types/common/paths';
import { MachineParams, RegisteredMachine } from 'types/feature/menu/machine/machine';
import { MachineType, Point } from 'types/feature/workspace/workspace';
import RegisteredMachineTable from './table/RegisteredMachineTable';

const MachineManageContainer = () => {
  // hook
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();
  const { setWorkspace } = useWorkspaceStore();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { getRegisteredMachines, getRegisteredDesktopMachines } = useMachine();
  const { fetchFloors, floorList } = useFloor();
  const navigate = useNavigate();

  // state
  const [registeredMachineList, setRegisteredMachineList] = useState<RegisteredMachine[]>([]);
  const [pointList, setPointList] = useState<Point[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<Point>();
  const [selectedPlaceType, setSelectedPlaceType] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');

  // store
  const workspaceStore = useWorkspaceStore();

  /**
   * 워크스페이스 목록을 조회한다
   */
  const fetchWorkspace = async (placeType: string, status: string) => {
    if (workspaceId) {
      const workspace = await getWorkspace(workspaceId);

      if (workspace.data) {
        setWorkspace(workspace.data);
        setPointList(workspace.data.points);

        const point = selectedPoint || workspace.data.points[0];

        setSelectedPoint(point);

        await fetchFloors(point.id);
        await fetchRegisteredMachines(
          {
            pointId: point.id,
            placeType,
            status,
          },
          workspace.data.machineType as MachineType,
        );
      }
    }
  };

  // 등록 기기 목록 조회
  const fetchRegisteredMachines = async (params: MachineParams, machineType: MachineType) => {
    if (machineType === 'WEB') {
      const result = await getRegisteredMachines(params);
      setRegisteredMachineList(result.data);
    }

    if (machineType === 'DESKTOP') {
      const result = await getRegisteredDesktopMachines(params);
      setRegisteredMachineList(result.data);
    }

    workspaceStore.setWorkspaceMachineType(machineType);
  };

  /**
   * 포인트 필터를 변경한다
   */
  const onChangePoint = async (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    const currentPoint: Point | undefined = pointList.find(point => point.id === value);

    if (currentPoint) {
      setSelectedPoint(currentPoint);
    }
  };

  /**
   * 구분 필터를 변경한다
   */
  const onChangePlaceType = async (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlaceType(e.target.value);
  };

  /**
   * 상태 필터를 변경한다
   */
  const onChangeStatus = async (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(e.target.value);
  };

  /**
   * 기기 상세 페이지로 이동한다
   */
  const moveToDetailPage = (machineId: string) => {
    if (workspaceId) {
      const path = RoutePath.machineDetail.replace(':workspaceId', workspaceId).replace(':machineId', machineId);
      navigate(path, {
        state: {
          point: selectedPoint,
        },
      });
    }
  };

  /**
   * 기기 등록 페이지로 이동한다
   */
  const moveToAddPage = () => {
    if (workspaceId) {
      const path = RoutePath.machineAdd.replace(':workspaceId', workspaceId);
      navigate(path, {
        state: {
          point: selectedPoint,
        },
      });
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspace(selectedPlaceType, selectedStatus);
    }
  }, [workspaceId, selectedPoint, selectedPlaceType, selectedStatus]);

  return (
    <div className='flex flex-col w-full gap-10'>
      <div className='flex items-center justify-between w-full'>
        <div className='flex gap-2.5'>
          <CommonSelectBox
            selected={selectedPoint?.id || ''}
            onChange={onChangePoint}
            options={pointList.map(({ id, name }) => {
              return {
                id,
                value: name.ko,
              };
            })}
            width='w-[140px]'
          />
          <CommonSelectBox
            selected={selectedPlaceType}
            onChange={onChangePlaceType}
            options={[{ id: '', value: '구분 전체' }, ...MACHINE_PLACE_TYPE]}
            width='w-[140px]'
          />

          <CommonSelectBox
            selected={selectedStatus}
            onChange={onChangeStatus}
            options={[{ id: '', value: '상태 전체' }, ...MACHINE_STATE_TYPE]}
            width='w-[140px]'
          />
        </div>
        <Button color={ButtonColor.secondary} size='add' text='기기등록' onClick={moveToAddPage} />
      </div>

      {/* 테이블 */}
      <RegisteredMachineTable
        goToMachineDetailPage={moveToDetailPage}
        floorList={floorList}
        registeredMachineList={registeredMachineList}
      />
    </div>
  );
};

export default MachineManageContainer;
