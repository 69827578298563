import { Point } from 'types/feature/workspace/workspace';
import { create } from 'zustand';

type PointProps = {
  currentPoint: Point | null;
  setCurrentPoint: (point: Point) => void;
  resetCurrentPoint: () => void;

  points: Point[];
  setPoints: (points: Point[]) => void;
  resetPoints: () => void;
};

const usePointStore = create<PointProps>((set, get) => ({
  currentPoint: null,
  setCurrentPoint: (point: Point) => set(() => ({ currentPoint: point })),
  resetCurrentPoint: () => set(() => ({ currentPoint: null })),

  points: [],
  setPoints: (points: Point[]) => set(() => ({ points })),
  resetPoints: () => set(() => ({ points: [] })),
}));

export default usePointStore;
