import { useAxios } from 'api/useAxios';
import { AxiosResponse } from 'axios';
import { Endpoint } from 'data/endPoint';
import { useParams } from 'react-router-dom';
import useCategoryStore from 'stores/category';
import { PoiCategory } from 'types/feature/category/poiCategory';
import {
  PatchTenantCategoryGroup,
  PostTenantCategoryGroup,
  TenantCategoryFeatureCode,
  TenantCategoryGroup,
} from 'types/feature/category/tenantCategory';

const useCategory = () => {
  const { api } = useAxios();

  const { setTenantCategoryGroups } = useCategoryStore();

  // 기능별 테넌트 카테고리 그룹 조회 (기능코드 보내지 않으면 전체조회)
  const getFeatureCategories = async (pointId: string, feature: TenantCategoryFeatureCode) => {
    const res = await api.get<AxiosResponse<TenantCategoryGroup[]>>(Endpoint.tenantCategoryGroups, {
      params: {
        pointId,
        feature,
      },
    });
    if (!res.data) return;
    return res.data;
  };

  // 기능별 테넌트 카테고리 그룹 등록
  const postTenantCategoryGroup = async (data: PostTenantCategoryGroup) => {
    const res = await api.post<AxiosResponse<{ id: string }>>(Endpoint.tenantCategoryGroups, data);
    if (!res.data) return;
    return res.data;
  };

  // 테넌트 카테고리 그룹 삭제
  const deleteTenantCategoryGroup = async (categoryGroupId: string) => {
    const res = await api.delete(Endpoint.tenantCategoryGroup.replace(':tenantCategoryGroupId', categoryGroupId));

    if (!res) return;
    return res;
  };

  // 테넌트 카테고리 수정
  const updateTenantCategory = async (categoryGroupId: string, data: PatchTenantCategoryGroup) => {
    const res = await api.patch(Endpoint.tenantCategoryGroup.replace(':tenantCategoryGroupId', categoryGroupId), data);
    if (!res) return;
    return res;
  };

  // 기능별 테넌트 카테고리 조회 및 전역 store 업데이트
  const refetchFeatureCategories = async (pointId: string, feature: TenantCategoryFeatureCode) => {
    const res = await getFeatureCategories(pointId, feature);
    if (res) {
      setTenantCategoryGroups(res);
    }
  };

  // poi 카테고리 전체 조회
  const getUserCategories = async (pointId: string) => {
    const res = await api.get<AxiosResponse<PoiCategory[]>>(Endpoint.poiCategories, {
      params: {
        pointId,
      },
    });

    if (!res.data) return;
    return res.data;
  };

  return {
    getUserCategories,
    postTenantCategoryGroup,
    deleteTenantCategoryGroup,
    updateTenantCategory,
    refetchFeatureCategories,
    getFeatureCategories,
  };
};
export default useCategory;
