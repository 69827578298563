import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import Input from 'components/common/input/Input';
import { ICON } from 'constants/icons';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useContents from 'hooks/feature/content/useContents';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Content, ContentItem } from 'types/feature/content/contents';
import ContentTable from './table/ContentTable';

const ContentContainer = () => {
  // 여기에서 부터 content 데이터 내려보내야함.
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();

  const [currentPage, setCurrentPage] = useState(0);
  const [contentsList, setContentsList] = useState<ContentItem[]>([]);
  const [content, setContent] = useState<Content>();
  const [searchWord, setSearchWord] = useState('');
  const { getContents } = useContents();

  const inputRef = useRef<HTMLInputElement>(null);

  const fetchContents = async (title?: string) => {
    const result = await getContents(currentPage, title);

    if (result) {
      setContent(result);
      setContentsList(result.content);
    }
  };

  // 페이지 클릭시 동작
  const handleChangePage = (pageNum: number) => {
    if (contentsList.length > 0) {
      setCurrentPage(pageNum);
    }
  };

  // 타이틀 검색
  const handleFilterTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const word = e.target.value;
    setSearchWord(word);
  };

  const handleClickFilter = async () => {
    if (searchWord) {
      await fetchContents(searchWord);
    }
  };

  // 타이틀 검색 새로고침
  const resetFilter = async () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setSearchWord('');
      await fetchContents('');
    }
  };

  // 콘텐츠 등록
  const moveToAddPage = () => {
    if (workspaceId) {
      navigateToReplacedPath(RoutePath.contentAdd, workspaceId);
    }
  };

  useEffect(() => {
    fetchContents();
  }, [currentPage]);

  return (
    <div className='flex flex-col w-full gap-10'>
      <div className='flex justify-between'>
        <div className='flex h-10 gap-2'>
          <Input
            width='w-60'
            placeholder='타이틀 검색'
            icon={
              <svg xmlns='http://www.w3.org/2000/svg' width='13' height='14' viewBox='0 0 13 14' fill='none'>
                <path
                  d='M1.14711 6.31806C1.14711 3.99166 3.03304 2.10573 5.35945 2.10573C7.68585 2.10573 9.57178 3.99166 9.57178 6.31806C9.57178 6.85517 9.47153 7.36767 9.2891 7.83872L10.1579 8.70755C10.1945 8.63423 10.2294 8.55995 10.2627 8.48477C10.5562 7.82151 10.7189 7.08803 10.7189 6.31806C10.7189 3.35812 8.31939 0.958618 5.35945 0.958618C2.39951 0.958618 0 3.35812 0 6.31806C0 9.278 2.39951 11.6775 5.35945 11.6775C6.13794 11.6775 6.87912 11.5112 7.54813 11.2115C7.60359 11.1867 7.65856 11.1609 7.713 11.1343L6.84127 10.2625C6.38092 10.4356 5.88181 10.5304 5.35945 10.5304C3.03304 10.5304 1.14711 8.64447 1.14711 6.31806Z'
                  fill='#444444'
                />
                <path d='M8.66686 9.6945L11.9857 13.0413' stroke='#444444' />
              </svg>
            }
            onChange={handleFilterTitle}
            inputRef={inputRef}
          />
          <Button text='검색' color={ButtonColor.primary} size='filter' onClick={handleClickFilter} />
          <Button text='초기화' color={ButtonColor.delete} size='filter' onClick={resetFilter} />
        </div>
        <Button size='add' text='콘텐츠 등록' color={ButtonColor.primary} onClick={moveToAddPage} />
      </div>

      <ContentTable
        contentItems={contentsList}
        currentPage={currentPage}
        totalElements={content && content.totalElements}
        refreshContents={fetchContents}
        onChangePage={handleChangePage}
      />
    </div>
  );
};

export default ContentContainer;
