import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { LocationForm } from 'types/feature/menu/machine/machine';

type Props = {
  setMachinePlaceForm: Dispatch<SetStateAction<LocationForm>>;
};

const DescriptionController = ({ setMachinePlaceForm }: Props) => {
  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setMachinePlaceForm((prev: LocationForm) => ({
      ...prev,
      description: value,
    }));
  };
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='상세위치'
        gap='gap-2.5'
        direction='column'
        control={
          <Input
            width='w-[260px]'
            textStyle='h5'
            placeholder='상세위치를 입력해주세요'
            onChange={handleChangeDescription}
          />
        }
      />
    </div>
  );
};

export default DescriptionController;
