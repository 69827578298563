import { MainMenuIcon } from 'types/feature/menu/menu';

type Props = {
  menuIcons: MainMenuIcon[];
  handleClickIconId: (id: string) => void;
  selectedIconId: string;
};

const MenuIcons = ({ menuIcons, handleClickIconId, selectedIconId }: Props) => {
  return (
    <div className='max-h-[150px] border w-full py-3 flex justify-center flex-1 px-3 overflow-y-auto'>
      <div className='grid w-full grid-cols-5 overflow-y-auto gap-x-0 gap-y-0'>
        {menuIcons.map(icon => (
          <div
            key={icon.id}
            className={`${
              selectedIconId === icon.id && 'bg-state-blue_bg border border-[#444]'
            } cursor-pointer flex flex-col justify-center items-center hover:bg-state-blue_bg w-[60px] h-[60px]`}
            onClickCapture={handleClickIconId.bind(this, icon.id)}
          >
            <img className='w-[40px] h-[40px]' src={icon.mainIconUri} alt='icon' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuIcons;
