import Point from './point/Point';

const InternalDashboardContainer = () => {
  const POINT_LIST: string[] = [
    process.env.REACT_APP_DASHBOARD_POINT_ID || '',
    process.env.REACT_APP_DASHBOARD_OY_POINT_ID || '',
  ];

  //  TODO: 추후 point list를 구하는 api 호출해서 POINT_LIST에 넣어주기
  // useEffect(() => {}, []);

  return (
    <div className='bg-[#20232E] flex h-screen w-screen'>
      {POINT_LIST &&
        POINT_LIST.map((pointId: string, index: number) => {
          return pointId !== '' && <Point key={index} id={pointId} />;
        })}
    </div>
  );
};
export default InternalDashboardContainer;
