import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import Navigation from 'components/common/layout/nav/Navigation';
import { ICON } from 'constants/icons';
import { useNavigate } from 'react-router-dom';
import { SIZE } from 'styles/size';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={`absolute top-0 left-0 z-10 bg-white flex justify-center items-center ${SIZE.screen}`}>
      <Navigation />

      <div className='flex flex-col items-center justify-center gap-4'>
        <ICON.PiSmileySadThin size={80} />
        <div className='text-sm'>존재하지 않는 페이지입니다.</div>
        <div>
          <Button color={ButtonColor.primary} onClick={() => navigate(-1)} text='돌아가기' />
        </div>
      </div>
    </div>
  );
};
export default NotFound;
