/* eslint-disable no-console */

import { isDevelopMode } from './environment';

export const logConsole = (title: string, content1?: any, content2?: any) => {
  if (isDevelopMode()) {
    console.log(title, content1, content2);
  }
};

export const warnConsole = (title: string, content?: any) => {
  if (isDevelopMode()) {
    console.warn(title, content);
  }
};

export const errorConsole = (title: string, content?: any) => {
  if (isDevelopMode()) {
    console.error(title, content);
  }
};
