import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Point } from 'types/feature/workspace/workspace';

const usePoint = () => {
  const { api } = useAxios();

  const getPoints = async (workspaceId: string) => {
    const result = await api.get<ApiResponse<Point[]>>(Endpoint.points, {
      params: {
        workspaceId,
      },
    });

    if (result) {
      return result.data;
    }
  };

  const getPoint = async (pointId: string) => {
    const result = await api.get<ApiResponse<Point>>(`${Endpoint.points}/${pointId}`);

    if (result) {
      return result.data;
    }
  };

  const putPoint = async (pointId: string, point: Point) => {
    const result = await api.put<ApiResponse<Point>>(`${Endpoint.points}/${pointId}`, point);
    return result;
  };

  return {
    getPoints,
    getPoint,
    putPoint,
  };
};
export default usePoint;
