/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosInstance } from 'axios';
import useHandleError from 'hooks/common/useHandleError';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
}

const useAxiosMultipart = () => {
  const { handleResponseError } = useHandleError();
  const navigate = useNavigate();

  const apiFile: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });

  apiFile.interceptors.request.use(
    async config => {
      config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8';

      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        config.headers['API-KEY'] = localStorage.getItem('KIOSK_ADMIN_ACCESS');
      }

      return config;
    },
    error => Promise.reject(error),
  );

  apiFile.interceptors.response.use(
    response => {
      return response.data;
    },
    (error: AxiosError) => {
      const { response } = error;

      if (response) {
        // api 에러
        handleResponseError(error);
      }

      // 서버 장애, 네트워크 에러 등
      navigate(RoutePath.serverError);
      throw error;
    },
  );

  return { apiFile };
};

export default useAxiosMultipart;
