import { FileUploadType } from 'types/common/file/fileUpload';
import { FileExtensionName } from 'types/feature/content/contents';
import { formatMegaByte } from '../unit/fileUnit';

export const validateFileType = (file: File, fileType: FileUploadType) => {
  const { type } = file;

  if (fileType === FileUploadType.CONTENT_VIDEO) {
    if (type === FileExtensionName.WEBM || type === FileExtensionName.MP4) {
      return true;
    }
  }

  if (
    fileType === FileUploadType.CONTENT_IMAGE ||
    fileType === FileUploadType.TENANT_IMAGE ||
    fileType === FileUploadType.CUSTOM_BOARD_CONTENT_IMAGE ||
    fileType === FileUploadType.CUSTOM_BOARD_IMAGE ||
    fileType === FileUploadType.LOGO_IMAGE
  ) {
    if (type === FileExtensionName.JPEG) {
      return true;
    }

    if (type === FileExtensionName.JPG) {
      return true;
    }

    if (type === FileExtensionName.PNG) {
      return true;
    }
  }

  return false;
};

/**
 * file, fileType
 * 파라미터로 전달 받은 파일의 용량을 확인하고 특정 업로드 타입에 적절하지 않은 용량인 경우 메세지를 리턴한다.
 */
export const validateFileVolume = (fileSize: number, fileType: FileUploadType) => {
  if (fileType === FileUploadType.CONTENT_IMAGE || fileType === FileUploadType.TENANT_IMAGE) {
    if (Number(formatMegaByte(fileSize)) > 10) {
      return {
        enableUpload: false,
        message: '용량이 10MB를 초과해 업로드할 수 없습니다.',
      };
    }
  }

  if (fileType === FileUploadType.CONTENT_VIDEO) {
    if (Number(formatMegaByte(fileSize)) > 50) {
      return {
        enableUpload: false,
        message: '용량이 50MB를 초과해 업로드할 수 없습니다.',
      };
    }
  }

  if (fileType === FileUploadType.CUSTOM_BOARD_CONTENT_IMAGE) {
    if (Number(formatMegaByte(fileSize)) > 5) {
      return {
        enableUpload: false,
        message: '용량이 5MB를 초과해 업로드할 수 없습니다.',
      };
    }
  }

  if (fileType === FileUploadType.CUSTOM_BOARD_IMAGE || fileType === FileUploadType.LOGO_IMAGE) {
    if (Number(formatMegaByte(fileSize)) > 2) {
      return {
        enableUpload: false,
        message: '용량이 2MB를 초과해 업로드할 수 없습니다.',
      };
    }
  }

  return {
    enableUpload: true,
    message: '',
  };
};
