import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import FormControl from 'components/common/form-control/FormControl';
import Title from 'components/common/title/Title';
import { FIELD_CONTAINER } from 'styles/field';
import { Language } from 'types/feature/language/language';
import { Tenant } from 'types/feature/tenant/getTenant';
import DisplayToggleButton from '../../display-button/DisplayToggleButton';
import SelectPopupType from '../../popup/SelectPopupType';
import ImageUploadGuide from './image-editable/ImageUploadGuide';
import TenantImageField from './image-editable/TenantImageField';
import TenantMultiLanguageInput from './multi-lang-input/TenantMultiLanguageInput';
import NavigateLanguageSetting from './navigate/NavigateLanguageSetting';

type CommonDetailsProps = {
  tenant: Tenant;
  displayLanguageList: Language[];
  fetchTenants: () => Promise<void>;
};

const CommonDetails = ({
  tenant: { id: tenantId, name, description, mainLogoFile, subLogoFile, used, popupType },
  displayLanguageList,
  fetchTenants,
}: CommonDetailsProps) => {
  return (
    <div className={`${FIELD_CONTAINER} relative gap-3 p-10`}>
      <div className='flex justify-between'>
        <div className='flex items-center gap-2'>
          <Title text='매장 정보' titleType='h3_bold' />
          <DisplayToggleButton toggleOn={used} tenantId={tenantId} fetchTenants={fetchTenants} />
        </div>
        <NavigateLanguageSetting languageList={displayLanguageList} />
      </div>

      <div className='flex flex-col gap-4'>
        <FormControl
          name='팝업 타입'
          labelSize='sm'
          labelTextSize='h5_bold'
          control={<SelectPopupType tenantId={tenantId} title={popupType} fetchTenants={fetchTenants} />}
        />
        <TenantMultiLanguageInput
          title='노출 타이틀'
          contentKey='name'
          tenantId={tenantId}
          languageList={displayLanguageList}
          tenantName={name && name}
          fetchTenants={fetchTenants}
        />

        <TenantMultiLanguageInput
          title='상세 설명'
          contentKey='description'
          tenantId={tenantId}
          languageList={displayLanguageList}
          tenantName={description && description}
          fetchTenants={fetchTenants}
        />

        <Border borderColor={BorderColor.gray} />

        <div className='flex flex-col gap-3'>
          <div className='flex items-center justify-between gap-4'>
            <TenantImageField
              title='목록 이미지'
              tenantId={tenantId}
              logoFile={mainLogoFile && mainLogoFile}
              fieldId='mainLogoFile'
              fetchTenants={fetchTenants}
            />
            <TenantImageField
              title='상세 이미지'
              tenantId={tenantId}
              logoFile={subLogoFile && subLogoFile}
              fieldId='subLogoFile'
              fetchTenants={fetchTenants}
            />
          </div>
          <ImageUploadGuide />
        </div>
      </div>
    </div>
  );
};
export default CommonDetails;
