import { Axios, AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import Switch from 'components/common/switch/Switch';
import useTenant from 'hooks/feature/tenant/useTenant';
import React, { useEffect, useState } from 'react';
import { IndexType } from 'types/common';

type Props = {
  tenantId: string;
  disabled?: boolean;
  title: string;
  fetchTenants: () => Promise<void>;
};

const PopupType: IndexType = {
  DEFAULT: '기본형',
  SMALL: '축약형',
};

const SelectPopupType = ({ tenantId, disabled, title, fetchTenants }: Props) => {
  const [popupType, setPopupType] = useState(PopupType[title]);
  const { updateTenantDetail } = useTenant();
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setPopupType(PopupType[title]);
  }, [tenantId]);

  const handleChangePopupType = async (value: string) => {
    try {
      await updateTenantDetail(tenantId, { popupType: value });
      await fetchTenants();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.status === 400) {
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <div>
      <Switch
        refreshKey={tenantId}
        label={popupType}
        handleChangeOption={handleChangePopupType}
        options={[
          { label: '기본형', value: 'DEFAULT' },
          { label: '축악형', value: 'SMALL' },
        ]}
      />
      {openAlert && <AlertPopup onClick={() => setOpenAlert(false)} message='오류가 발생했습니다.' />}
    </div>
  );
};

export default SelectPopupType;
