import SelectBox from 'components/common/select/SelectBox';
import { ChangeEvent } from 'react';
import { MultiLangs } from 'types/feature/language/language';

type CustomPageSelectProps = {
  currentId: string | undefined;
  customPageList: { name: MultiLangs; customPageId: string }[];
  handleSelectItem: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const CustomPageSelect = ({ customPageList, currentId, handleSelectItem }: CustomPageSelectProps) => {
  return (
    <SelectBox
      selectedId={currentId}
      width='w-[200px]'
      options={customPageList.map(custom => {
        return {
          id: custom.customPageId,
          value: custom.name.ko,
        };
      })}
      onChange={handleSelectItem}
    />
  );
};

export default CustomPageSelect;
