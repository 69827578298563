import { useAxiosInternal } from 'api/useAxiosInternal';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { MachineCommand, MachineStatus } from 'types/internal/dashboard/internalDashboardMachine';

const useInternalDashboard = () => {
  const { api } = useAxiosInternal();

  /**
   * 대시보드(연결기기)를 조회한다
   */
  const getDashboardMachineStatuses = async (pointId: string): Promise<MachineStatus> => {
    const response = await api.get<ApiResponse<MachineStatus>>(Endpoint.internalDashBoardsMachineStatuses, {
      params: {
        pointId,
      },
    });
    return response.data;
  };

  /**
   * 기기에 명령을 내린다
   * @param machineId 명령을 내릴 기기 아이디
   * @param {MachineCommand} command 기기에게 내릴 명령
   */
  const postInternalFileManagerMachinesCommand = async (machineId: string, command: MachineCommand) => {
    await api.post(Endpoint.internalFileManagerMachinesCommand.replace(':machineId', machineId), {
      command,
    });
  };

  return { getDashboardMachineStatuses, postInternalFileManagerMachinesCommand };
};
export default useInternalDashboard;
