import { AxiosError } from 'axios';
import GuideDescription from 'components/common/description/GuideDescription';
import AlertPopup from 'components/common/popup/AlertPopup';
import Title from 'components/common/title/Title';
import { LANGUAGE_GUIDES } from 'data/guide/setting';
import useLanguage from 'hooks/feature/language/useLanguage';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { Language } from 'types/feature/language/language';
import { handleSortOrder } from 'utils/sort-order/handle/handleSortOrder';
import LanguageRows from './table/row/LanguageRows';

const LanguageSettingContainer = () => {
  const [languageList, setLanguageList] = useState<Language[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { workspaceId } = useParams();
  const { getLanguages, updateLanguage } = useLanguage();

  const handleAlert = () => {
    setAlertMessage('더 이상 순서를 변경할 수 없습니다.');
    setOpenAlert(true);
  };

  const handleRow = async (dropResult: DropResult) => {
    try {
      const sourceIndex = dropResult.source.index;
      const currentItemId = languageList[sourceIndex].id;

      const sortOrder = handleSortOrder(dropResult, languageList);

      if (sortOrder) {
        await updateLanguage(currentItemId, {
          sortOrder,
        });
        await fetchLanguageList();
      }
    } catch (e) {
      if (e instanceof Error) {
        handleAlert();
      }
    }
  };

  const fetchLanguageList = async () => {
    try {
      if (workspaceId) {
        const result = await getLanguages(workspaceId);
        setLanguageList(result);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.status && e.status === 400) {
          setAlertMessage(e.message);
          setOpenAlert(true);
        }
      }
    }
  };

  useEffect(() => {
    fetchLanguageList();
  }, []);

  return (
    <>
      <div className='flex flex-col justify-start w-full h-full gap-5'>
        <Title text='언어' titleType='h2' />

        <GuideDescription guides={LANGUAGE_GUIDES} width='w-[580px]' />

        <div className='w-full'>
          <table className='w-full'>
            <colgroup>
              <col width='5%' />
              <col width='10%' />
              <col width='10%' />
              <col width='10%' />
              <col width='10%' />
              <col width='10%' />
              <col width='10%' />
            </colgroup>

            <thead className='border-t border-gray-44'>
              <tr className='h-10 text-sm border-b-2 whitespace-nowrap border-gray-44'>
                <th> </th>
                <th>메인언어</th>
                <th>언어</th>
                <th>노출명</th>
                <th>언어코드</th>
                <th>사용 여부</th>
                <th>노출 여부</th>
              </tr>
            </thead>

            <DragDropContext onDragEnd={handleRow}>
              <Droppable droppableId='table'>
                {provided => {
                  return (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      {languageList
                        .sort((a: Language, b: Language) => a.sortOrder - b.sortOrder)
                        .map((language, index) => {
                          return (
                            <Draggable draggableId={`drag-${index}`} index={index} key={`drag-${index}`}>
                              {draggableProvided => (
                                <LanguageRows
                                  getLanguageList={fetchLanguageList}
                                  languageIndex={index}
                                  provided={draggableProvided}
                                  language={language}
                                />
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </tbody>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </table>
        </div>
      </div>
      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};

export default LanguageSettingContainer;
