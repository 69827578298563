import { IMAGES } from 'constants/images';
import { ColorSet } from 'types/feature/setting/theme/theme.type';
import ThemePreviewFooter from '../common/footer/ThemePreviewFooter';
import ThemePreviewMenu from '../common/menu/ThemePreviewMenu';

type Props = {
  usedColorSet: ColorSet | undefined;
};

const ThemeMainPreview = ({ usedColorSet }: Props) => {
  return (
    <div className='w-[280px] h-full'>
      <img src={IMAGES.PREVIEW_SCREENSAVER} alt='미리보기 스크린세이버' />
      <ThemePreviewMenu activeMenu='main' usedColorSet={usedColorSet} />
      <ThemePreviewFooter />
    </div>
  );
};
export default ThemeMainPreview;
