/* eslint-disable jsx-a11y/media-has-caption */
import { IMAGES } from 'constants/images';
import React from 'react';
import { FileType } from 'types/common/file/file';

type Props = {
  fileType: FileType;
  fileUri: string;
  fileName?: string;
  width?: string;
};

const FilePreview = ({ fileType, fileUri, fileName, width = 'w-64' }: Props) => {
  const fileNames = fileName && fileName.split('.');

  return (
    <div className={`h-full flex flex-col justify-center items-center gap-2.5 object-contain overflow-hidden ${width}`}>
      {fileType === FileType.image ? (
        <img
          className='object-contain overflow-hidden'
          src={fileUri}
          alt='콘텐츠'
          onError={e => {
            const element = e.target as HTMLInputElement;
            element.src = IMAGES.DEFAULT_THUMBNAIL;
          }}
        />
      ) : (
        <video className='object-contain h-full overflow-hidden' controls src={fileUri}>
          <source />
        </video>
      )}

      {fileName && (
        <div className='text-sm leading-5 text-gray-44 max-w-[360px] flex justify-center'>
          <div className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[180px] text-inherit'>
            {fileNames?.[0]}
          </div>
          <div className='text-inherit'>.{fileNames?.[1]}</div>
        </div>
      )}
    </div>
  );
};
export default FilePreview;
