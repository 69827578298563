import useAxiosMultipart from 'api/useAxiosMultipart';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { FileUploadType } from 'types/common/file/fileUpload';
import { UploadFile } from 'types/feature/content/contents';

const useFileUpload = () => {
  const { apiFile } = useAxiosMultipart();

  const uploadFile = async (file: File, fileType: FileUploadType) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploadType', fileType);

      const result = await apiFile.post<ApiResponse<UploadFile>>(Endpoint.files, formData);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error('파일 업로드에 실패하였습니다.');
      }
    }
  };

  return { uploadFile };
};
export default useFileUpload;
