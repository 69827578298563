/* eslint-disable no-nested-ternary */
import MultiLangLimitLength from 'components/common/length/MultiLangLimitLength';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { ICON } from 'constants/icons';
import { TenantDescWordLimit, TenantTitleWordLimit } from 'data/length';
import useTenant from 'hooks/feature/tenant/useTenant';
import { ChangeEvent, useEffect, useState } from 'react';
import { Language, MultiLangs, PatchLanguage } from 'types/feature/language/language';

type Props = {
  tenantId: string;
  contentKey: string;
  tenantName: MultiLangs;
  title: string;
  languageList: Language[];
  fetchTenants: () => Promise<void>;
};

const TenantMultiLanguageInput = ({ title, contentKey, tenantId, languageList, tenantName, fetchTenants }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [titleContent, setTitleContent] = useState<PatchLanguage>({
    ko: tenantName.ko,
    en: tenantName.en,
    ja: tenantName.ja,
    cn: tenantName.cn,
    tw: tenantName.tw,
  });
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const { updateTenantDetail } = useTenant();

  const handleShowEditBtn = () => {
    if (isEditMode) return;

    setShowEditMode(true);
  };

  const handleClickEditBtn = () => {
    setShowEditMode(false);
    setIsEditMode(!isEditMode);
  };

  const handleChangeMultiLangInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setTitleContent((prev: PatchLanguage) => ({ ...prev, [name]: value }));
  };

  const updateMultiLangContent = async () => {
    try {
      await updateTenantDetail(tenantId, {
        [contentKey]: titleContent,
      });
      await fetchTenants();

      setOpenConfirmPopup(false);
      setIsEditMode(false);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  useEffect(() => {
    setTitleContent((prev: PatchLanguage) => ({
      ...prev,
      ko: tenantName.ko,
      en: tenantName.en,
      ja: tenantName.ja,
      cn: tenantName.cn,
      tw: tenantName.tw,
    }));
    setIsEditMode(false);
  }, [tenantId]);

  return (
    <div
      className={`flex items-start ${showEditMode && 'bg-[#f9f9f9]'}`}
      onFocus={handleShowEditBtn}
      onMouseOver={handleShowEditBtn}
      onMouseLeave={() => setShowEditMode(false)}
    >
      <div className={`flex items-center w-24 h-9 text-xs font-bold whitespace-nowrap min-w-max text-gray-77`}>
        {title}
      </div>

      <div className='flex flex-col items-end w-full gap-2.5'>
        <div className='flex flex-col gap-2.5 w-full'>
          {languageList.map(language => {
            const multiLangLimit =
              contentKey === 'description'
                ? TenantDescWordLimit[language.code.toLowerCase()]
                : TenantTitleWordLimit[language.code.toLowerCase()];
            return (
              <div key={language.id} className={`flex items-center gap-2.5 h-9 w-full`}>
                {/* lang */}
                <div className={`flex items-center justify-center bg-gray-99 text-white w-[22px] text-[10px]`}>
                  {language.code}
                </div>

                {/* code가 key값이 됨 */}
                <div className='w-full'>
                  {isEditMode ? (
                    <div className='flex items-center justify-between w-full px-2 bg-white border border-gray-99 h-9'>
                      <input
                        maxLength={multiLangLimit}
                        className='w-full text-xs'
                        defaultValue={titleContent[language.code.toLowerCase()]}
                        placeholder={`${title}을 입력해주세요.`}
                        name={language.code.toLowerCase()}
                        onChange={e => {
                          const { length } = e.target.value;
                          if (length > multiLangLimit) return;
                          handleChangeMultiLangInput(e);
                        }}
                      />

                      <MultiLangLimitLength
                        length={titleContent[language.code.toLowerCase()].length}
                        wordLimit={multiLangLimit}
                      />
                    </div>
                  ) : (
                    <div
                      className={`${
                        !tenantName[language.code.toLowerCase()] ? 'text-gray-99' : 'text-gray-44'
                      } w-auto text-ellipsis whitespace-nowrap overflow-hidden text-xs`}
                    >
                      {tenantName[language.code.toLowerCase()] || `${title}이 없습니다.`}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {
          // 수정 버튼
          showEditMode ? (
            <div className='h-6 pr-1' onClick={handleClickEditBtn}>
              <img src={ICON.Pencil} alt='pencil' />
            </div>
          ) : // 취소, 저장 버튼
          !showEditMode && isEditMode ? (
            <div className='flex h-6 gap-2'>
              <div onClick={() => setIsEditMode(false)}>
                <img src={ICON.Cancel} alt='cancel' />
              </div>

              <div onClick={() => setOpenConfirmPopup(true)}>
                <img src={ICON.Success} alt='success' />
              </div>
            </div>
          ) : (
            <div className='h-6' />
          )
        }
      </div>

      {openConfirmPopup && (
        <ConfirmPopup
          message={`${title}을 저장하시겠습니까?`}
          onClickOk={updateMultiLangContent}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}
    </div>
  );
};

export default TenantMultiLanguageInput;
