import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import CustomPageAddForm from 'components/feature/custom-page/add/CustomPageAddForm';
import dayjs from 'dayjs';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useCustomPage from 'hooks/feature/custom-page/useCustomPage';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DisplayType } from 'types/common/display';
import { RoutePath } from 'types/common/paths';
import { PostCustomPage } from 'types/feature/custom-page/customPage';

const CustomPageAdd = () => {
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [addCustomPageForm, setAddCustomPageForm] = useState<PostCustomPage>({
    title: {
      ko: '',
      en: '',
      ja: '',
      cn: '',
      tw: '',
    },
    customPageId: '',
    displayType: DisplayType.always,
    startDate: dayjs(new Date()).format('YYYY-MM-DD'),
    endDate: dayjs(new Date()).format('YYYY-MM-DD'),
    mainImage: {
      name: '',
      originalName: '',
      delete: false,
    },
    contentImage: {
      name: '',
      originalName: '',
      delete: false,
    },
  });
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId, customPageId } = useParams();
  const { postCustomBoard } = useCustomPage();

  const moveToCustomBoards = () => {
    navigateToReplacedPath(RoutePath.customPage, workspaceId, ':customPageId', customPageId);
  };

  const showAlertPopup = (message: string) => {
    setOpenAlertPopup(true);
    setAlertMessage(message);
    setOpenConfirmPopup(false);
  };

  // validation
  const checkCustomPageForm = (form: PostCustomPage) => {
    if (form.displayType === DisplayType.duration && isEmpty(form.endDate)) {
      showAlertPopup('종료 날짜가 필요합니다.');
      return false;
    }

    if (checkImageEmpty(form.mainImage)) {
      showAlertPopup('메인 이미지가 필요합니다.');
      return false;
    }

    if (checkImageEmpty(form.contentImage)) {
      showAlertPopup('상세 이미지가 필요합니다.');
      return false;
    }

    return true;
  };

  // 이미지 파일 비었는지 확인
  // todo util로 분리
  const checkImageEmpty = (image: Record<string, any>) => {
    return Object.keys(image).every(key => {
      const value = image[key];
      return isEmpty(value);
    });
  };

  // 저장 버튼 클릭 시
  const handleSaveBtn = () => {
    if (checkCustomPageForm(addCustomPageForm)) {
      setOpenConfirmPopup(true);
    }
  };

  // 커스텀 페이지 생성
  const createCustomPage = async () => {
    try {
      if (customPageId) {
        const form = {
          ...addCustomPageForm,
          customPageId,
        };
        await postCustomBoard(form);
        moveToCustomBoards();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setOpenAlertPopup(true);
        setAlertMessage(error.message);
        setOpenConfirmPopup(false);
      }
    }
  };

  return (
    <>
      <div className='flex flex-col items-center w-full gap-10'>
        <CustomPageAddForm setCustomPageForm={setAddCustomPageForm} title={addCustomPageForm.title} />
        <div className='flex gap-3'>
          <Button text='취소' color={ButtonColor.secondary} onClick={moveToCustomBoards} />
          <Button text='저장' color={ButtonColor.primary} onClick={handleSaveBtn} />
        </div>
      </div>
      {openConfirmPopup && (
        <ConfirmPopup
          message='메뉴 콘텐츠를 등록 하시겠습니까?'
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={createCustomPage}
        />
      )}
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default CustomPageAdd;
