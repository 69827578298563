import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { MONITORING_TABLE_HEADER } from 'data/table/monitoring';
import { Floor } from 'types/feature/floor/floor';
import { ConnectedMachine } from 'types/feature/menu/machine/machine';
import MonitoringRow from './row/MonitoringRow';

type Props = {
  connectedMachineList: ConnectedMachine[];
  floorList: Floor[];
};

const MonitoringTable = ({ connectedMachineList, floorList }: Props) => {
  return (
    <table>
      <CommonTableHeader tableHead={MONITORING_TABLE_HEADER} />
      {connectedMachineList.map((machine, machineIndex) => {
        /**
         * 층 목록에서 기기 층을 찾는다
         */
        const machineFloor = floorList.find(floor => floor.id === machine.floorId);

        return (
          <MonitoringRow
            key={machine.id}
            machineIndex={machineIndex + 1}
            connectedMachine={machine}
            machineFloor={machineFloor}
          />
        );
      })}
    </table>
  );
};

export default MonitoringTable;
