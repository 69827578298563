import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import RadioContainer from 'components/feature/ad/screensaver/add/content/table/radio/RadioContainer';
import DraggableButton from 'components/feature/ad/screensaver/list/table/row/buttons/drag/DraggableButton';
import useLanguage from 'hooks/feature/language/useLanguage';
import { useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Language, LanguageExposureName, LanguageName, UpdateLanguageType } from 'types/feature/language/language';
import DropDownBtn from '../select/DropDownBtn';

type LanguageRowsProps = {
  languageIndex: number;
  provided: DraggableProvided;
  language: Language;
  getLanguageList: () => Promise<void>;
};

const LanguageRows = ({ provided, language, languageIndex, getLanguageList }: LanguageRowsProps) => {
  const { updateLanguage, updateMainLanguage } = useLanguage();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openUsedConfirmPopup, setOpenUsedConfirmPopup] = useState(false);
  const [openDisplayConfirmPopup, setOpenDisplayConfirmPopup] = useState(false);
  const [openMainLangConfirmPopup, setOpenMainLangConfirmPopup] = useState(false);

  const isDisabledLanguage = !language.used && !language.display;

  const handleChangeRadioBtn = () => {
    setOpenMainLangConfirmPopup(true);
  };

  const handleAlert = (message: string) => {
    setOpenAlert(true);
    setAlertMessage(message);
  };

  // 사용여부
  const updateLanguageUsed = async () => {
    try {
      if (language.main) {
        setOpenUsedConfirmPopup(false);
        handleAlert('메인 언어는 사용 여부를 변경할 수 없습니다.');
        return;
      }
      if (language.id) {
        await updateLanguage(language.id, { used: !language.used });
        await getLanguageList();
        setOpenUsedConfirmPopup(false);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.status && e.status === 400) {
          handleAlert(e.message);
        }
      }
    }
  };

  // 노출 여부
  const updateLanguageDisplay = async () => {
    try {
      if (language.main) {
        setOpenDisplayConfirmPopup(false);
        handleAlert('메인 언어는 노츨 여부를 변경할 수 없습니다.');
        return;
      }
      if (language.id) {
        await updateLanguage(language.id, { display: !language.display });
        await getLanguageList();
        setOpenDisplayConfirmPopup(false);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.status && e.status === 400) {
          handleAlert(e.message);
        }
      }
    }
  };

  // 메인언어 변경
  const changeMainLanguage = async () => {
    try {
      if (language.id) {
        await updateMainLanguage(language.id);
        await getLanguageList();
        setOpenMainLangConfirmPopup(false);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.status && e.status === 400) {
          handleAlert(e.message);
        }
      }
    }
  };
  return (
    <>
      <tr
        className={`h-12 ${isDisabledLanguage && 'bg-bg-f9 cursor-default'}`}
        {...provided.draggableProps}
        ref={provided.innerRef}
        key={language.code}
      >
        <td {...provided.dragHandleProps}>
          <div className='flex items-center justify-center'>{!isDisabledLanguage && <DraggableButton />}</div>
        </td>
        <td>
          <RadioContainer
            disabled={isDisabledLanguage}
            value={language.code}
            checked={language.main}
            onChange={handleChangeRadioBtn}
          />
        </td>
        <td className={`${isDisabledLanguage && 'text-gray-99'}`}>{LanguageName[language.code]}</td>
        <td className={`${isDisabledLanguage && 'text-gray-99'}`}>{LanguageExposureName[language.code]}</td>
        <td className={`${isDisabledLanguage && 'text-gray-99'}`}>{language.code}</td>
        <td className='text-center'>
          <DropDownBtn
            language={language}
            name={UpdateLanguageType.used}
            currentState={language.used}
            openConfirm={setOpenUsedConfirmPopup}
            options={[{ name: '사용' }, { name: '미사용' }]}
          />
        </td>
        <td>
          <DropDownBtn
            language={language}
            name={UpdateLanguageType.display}
            currentState={language.display}
            openConfirm={setOpenDisplayConfirmPopup}
            options={[{ name: '노출' }, { name: '비노출' }]}
          />
        </td>
      </tr>
      {openUsedConfirmPopup && (
        <ConfirmPopup
          message='사용 여부를 변경 하시겠습니까?'
          okMessage='변경'
          cancelMessage='취소'
          onClickOk={updateLanguageUsed}
          onClickCancel={() => setOpenUsedConfirmPopup(false)}
        />
      )}

      {openDisplayConfirmPopup && (
        <ConfirmPopup
          message='노출 여부를 변경 하시겠습니까?'
          okMessage='변경'
          cancelMessage='취소'
          onClickOk={updateLanguageDisplay}
          onClickCancel={() => setOpenDisplayConfirmPopup(false)}
        />
      )}

      {openMainLangConfirmPopup && (
        <ConfirmPopup
          message='해당 언어를 메인 언어로 변경 하시겠습니까?'
          okMessage='변경'
          cancelMessage='취소'
          onClickOk={changeMainLanguage}
          onClickCancel={() => setOpenMainLangConfirmPopup(false)}
        />
      )}

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};

export default LanguageRows;
