import { InputProps } from './Input.type';

const Input = ({
  width = 'w-52',
  height = 'h-9',
  placeholder,
  type,
  readOnly,
  value,
  defaultValue,
  min,
  max,
  maxLength,
  onChange,
  id,
  autoComplete,
  inputRef,
  name,
  textStyle = 'h4',
  disabled,
  icon,
  showWarning,
  required,
}: InputProps) => {
  const wordLength = defaultValue?.length || value?.length;
  return (
    <label
      className={`${width} ${height} flex items-center justify-start gap-2 ${readOnly ? 'border-none' : 'border'}
    ${showWarning ? 'border-[#D94545] focus:border-[#D94545]' : 'border-gray-99 focus:border-gray-99'}
    ${disabled && 'opacity-50'}
    `}
    >
      {icon && <div className='pl-2'>{icon}</div>}
      <input
        className={` 
        placeholder:text-gray-cc placeholder:text-xs placeholder:font-light
        text-xs font-light
        outline-none focus:outline-none border-none
        px-2
        w-full
        `}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
        id={id}
        min={min}
        max={max}
        maxLength={maxLength}
        onChange={onChange}
        defaultValue={defaultValue}
        readOnly={readOnly}
        type={type ?? 'text'}
        value={value}
        ref={inputRef}
        name={name}
        required={required}
      />

      {maxLength && (
        <div className='ml-3 mr-3'>
          <div className='text-gray-ea text-[10px] leading-4 font-normal'>
            <span className={`${Number(wordLength) > 0 ? 'text-gray-ea font-h3_bold' : 'text-gray-ea'}`}>
              {wordLength || 0}
            </span>
            <span className='text-inherit'>/</span>
            <span className={`${wordLength === maxLength ? 'text-gray-ea' : 'text-gray-ea'} `}>{maxLength || 0}</span>
          </div>
        </div>
      )}
    </label>
  );
};
export default Input;
