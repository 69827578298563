import { useAxios } from 'api/useAxios';
import useAxiosMultipart from 'api/useAxiosMultipart';
import { PAGINATION_LIMIT } from 'data/common';
import { Endpoint } from 'data/endPoint';
import { useParams } from 'react-router-dom';
import { ApiResponse } from 'types/api/response';
import { FileUploadType } from 'types/common/file/fileUpload';
import { Content, ContentItem, ContentTypeCode, UploadFile } from 'types/feature/content/contents';

const useContents = () => {
  const { api } = useAxios();
  const { apiFile } = useAxiosMultipart();
  const { workspaceId } = useParams();

  // 목록 조회
  const getContents = async (currentPage: number, title?: string) => {
    if (!workspaceId) return;

    const result = await api.get<ApiResponse<Content>>(Endpoint.contents, {
      params: {
        workspaceId,
        page: currentPage,
        size: PAGINATION_LIMIT,
        title,
      },
    });

    if (result) {
      return result.data;
    }
  };

  // 조회
  const getContent = async (contentId: string) => {
    const result = await api.get<ApiResponse<ContentItem>>(Endpoint.content.replace(':contentId', contentId));
    if (result) {
      return result.data;
    }
  };

  // 파일 등록
  const uploadFile = async (file: FileList, fileType: FileUploadType) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('uploadType', fileType);
    const result = await apiFile.post<ApiResponse<UploadFile>>(Endpoint.files, formData);
    if (result) {
      return result;
    }
  };

  // 파일 등록
  const uploadFileItem = async (file: File, fileType: FileUploadType) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploadType', fileType);
    const result = await apiFile.post<ApiResponse<UploadFile>>(Endpoint.files, formData);
    if (result) {
      return result;
    }
  };

  // 콘텐츠 등록
  const uploadContent = async (form: any) => {
    const result = await api.post<ApiResponse<any>>(Endpoint.contents, form);

    if (result) {
      return result;
    }
  };

  // 삭제
  const deleteContent = async (contentId: string) => {
    await api.delete<any>(Endpoint.content.replace(':contentId', contentId));
  };

  // 수정
  const updateContent = async (contentId: string, updateTitle: string) => {
    await api.put<ApiResponse<any>>(Endpoint.content.replace(':contentId', contentId), {
      title: updateTitle,
    });
  };

  return {
    getContents,
    getContent,
    uploadFile,
    uploadContent,
    updateContent,
    deleteContent,
    uploadFileItem,
  };
};

export default useContents;
