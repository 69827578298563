import { TextType } from 'types/common/text';
import InfoSvg from './info/InfoSvg';

type Props = {
  guides?: string[];
  width?: string;
  icon?: boolean;
  listDisc?: boolean;
  manualInfo?: string;
  bgColor?: 'default' | 'none';
  infoColor?: 'default' | 'red';
};

const GuideDescription = ({
  bgColor = 'default',
  guides,
  width,
  icon = true,
  listDisc = false,
  manualInfo,
  infoColor = 'default',
}: Props) => {
  const iconColor = infoColor === 'red' ? '#EC4545' : '#777';
  const textColor = infoColor === 'red' ? 'text-primary-DA_Red' : 'text-gray-77';

  return (
    <div
      className={`${width} ${bgColor === 'default' ? 'bg-gray-50' : 'bg-transparent'} shrink-0 
      ${manualInfo ? 'flex-col' : 'flex-row'}
      ${guides && 'py-4'}
      `}
    >
      {/* 안내 문구 */}
      {manualInfo && (
        <div className={`${guides ? 'px-5' : 'p-0'} flex items-center gap-2.5 h-9`}>
          <InfoSvg color={iconColor} />
          <span className={`${TextType.h4} whitespace-break-spaces ${textColor}`}>{manualInfo}</span>
        </div>
      )}

      {/* 가이드 */}
      {guides && (
        <div className={`px-5 flex items-start justify-start gap-2.5`}>
          {icon && <InfoSvg color={iconColor} />}

          <ul className={`${textColor}`}>
            {guides?.map((guide, index) => (
              <li
                key={index}
                className={`text-xs whitespace-break-spaces text-inherit tracking-tighter leading-normal`}
              >
                {listDisc && <span>﹒</span>}
                {guide}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default GuideDescription;
