import NoContent from 'components/common/no-content/NoContent';
import useWaitingListStore from 'stores/waitingList';
import { BG_COLOR } from 'styles/color';
import { FILE_TYPES } from 'types/common/file/file';
import { SCREEN_TYPE_VALUES } from 'types/common/screenType';
import DisplayRangeContainer from './display/DisplayRangeContainer';
import ScreensaverAddTableHeader from './header/ScreensaverAddTableHeader';
import RadioContainer from './radio/RadioContainer';

type ScreensaverAddTableProps = {
  selectedId: string;
  handleSelectedId: (id: string) => void;
};

const ScreensaverAddTable = ({ selectedId, handleSelectedId }: ScreensaverAddTableProps) => {
  const { waitingList } = useWaitingListStore();

  return (
    <div className='w-full h-full overflow-y-auto'>
      <table className='w-full'>
        <ScreensaverAddTableHeader />

        <tbody>
          {waitingList.map(row => {
            const disabled = selectedId !== String(row.id);

            return (
              <tr
                className={`${disabled ? 'bg-neutral-100' : BG_COLOR.white}
                  h-14 text-left cursor-pointer
                `}
                onClick={handleSelectedId.bind(this, row.id)}
                key={row.id}
              >
                <td>
                  <RadioContainer
                    value={String(row.id)}
                    onChange={handleSelectedId.bind(this, row.id)}
                    checked={String(row.id) === selectedId}
                  />
                </td>
                <td className={`text-left ${disabled && 'text-gray-300'}`}>{SCREEN_TYPE_VALUES[row.screenType]}</td>
                <td className={`text-left ${disabled && 'text-gray-300'}`}>{FILE_TYPES[row.fileType]}</td>
                <td className={`px-2 py-2 text-left ${disabled && 'text-gray-300'}`}>{row.title}</td>
                <td className={`pr-4 ${disabled && 'text-gray-300'}`} colSpan={2}>
                  <DisplayRangeContainer disabled={disabled} rowId={row.id} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <NoContent visible={waitingList.length < 1} text='등록된 콘텐츠가 없습니다.' />
    </div>
  );
};
export default ScreensaverAddTable;
