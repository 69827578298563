import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useContents from 'hooks/feature/content/useContents';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FLEX_CENTER } from 'styles/flex';
import { RoutePath } from 'types/common/paths';
import { ContentItem, ContentTypeCodeKo, ScreenTypeCodeKo } from 'types/feature/content/contents';
import { formatMegaByte } from 'utils/file/unit/fileUnit';
import PreviewContainer from './preview/PreviewContainer';

const ContentDetailContainer = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [currentContent, setCurrentContent] = useState<ContentItem>();

  const { getContent, updateContent, deleteContent } = useContents();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { contentId, workspaceId } = useParams();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmSaveTitle, setConfirmSaveTitle] = useState(false);
  const [openFailPopup, setOpenFailPopup] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    if (contentId) {
      const result = await getContent(contentId);
      setCurrentContent(result);
    }
  };

  const handleEditBtn = () => {
    setIsEdit(true);
  };

  const handleSaveTitle = async () => {
    if (inputRef.current && contentId) {
      const { value } = inputRef.current;
      // validate
      if (isEmpty(value)) {
        setOpenFailPopup(true);
        setConfirmSaveTitle(false);
        return;
      }

      await updateContent(contentId, value).then(() => {
        setIsEdit(false);
        setConfirmSaveTitle(false);
        fetchContent();
      });
    }
  };

  const handleDeleteContent = async () => {
    if (contentId) {
      await deleteContent(contentId);
    }
    moveToContentPage();
  };

  const moveToContentPage = () => {
    if (contentId && workspaceId) {
      navigateToReplacedPath(RoutePath.content, workspaceId, ':contentId', contentId);
    }
  };

  return (
    <>
      <div className='flex flex-col w-full h-full gap-6 pb-20'>
        {currentContent && (
          <>
            <div className='grid grid-cols-2 justify-items-start gap-y-2'>
              <FormControl
                name='타이틀'
                required
                control={
                  <div className={`${FLEX_CENTER.row} gap-2`}>
                    <Input
                      key={currentContent?.title}
                      defaultValue={currentContent?.title}
                      readOnly={!isEdit}
                      inputRef={inputRef}
                    />
                    {!isEdit ? (
                      <Button text='수정' color={ButtonColor.secondary} onClick={handleEditBtn} />
                    ) : (
                      <>
                        <Button text='저장' color={ButtonColor.primary} onClick={() => setConfirmSaveTitle(true)} />
                        <Button text='취소' color={ButtonColor.secondary} onClick={() => setIsEdit(false)} />
                      </>
                    )}
                  </div>
                }
              />
              <FormControl
                name='구분'
                required
                control={<div className='text-sm'>{ScreenTypeCodeKo[currentContent.screenType]}</div>}
              />
              <FormControl
                name='미리보기'
                required
                control={
                  <div className='text-sm'>{`${currentContent.file.originalName} (${formatMegaByte(
                    currentContent.file.size,
                  )})MB`}</div>
                }
              />
              <FormControl
                name='유형'
                required
                control={<div className='text-sm'>{ContentTypeCodeKo[currentContent.fileType]}</div>}
              />
            </div>

            {/* 프리뷰 */}
            <PreviewContainer contentFile={currentContent.file} fileType={currentContent.fileType} />
          </>
        )}

        {/* 버튼 */}
        <div className='flex justify-between'>
          <Button text='목록' color={ButtonColor.secondary} onClick={moveToContentPage} />
          <Button text='삭제' color={ButtonColor.primary} onClick={() => setConfirmDelete(true)} />
        </div>
      </div>

      {confirmDelete && (
        <ConfirmPopup
          message='삭제하시겠습니까'
          onClickCancel={() => setConfirmDelete(false)}
          onClickOk={handleDeleteContent}
        />
      )}

      {confirmSaveTitle && (
        <ConfirmPopup
          message='타이틀을 변경하시겠습니까'
          onClickCancel={() => setConfirmSaveTitle(false)}
          onClickOk={handleSaveTitle}
        />
      )}

      {openFailPopup && <FailPopup closeModal={() => setOpenFailPopup(false)} text='타이틀을 입력해주세요.' />}
    </>
  );
};

export default ContentDetailContainer;
