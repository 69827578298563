import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import Navigation from 'components/common/layout/nav/Navigation';
import { ICON } from 'constants/icons';
import { useNavigate } from 'react-router-dom';

const ServerErrorPage = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate(-1);
  };
  return (
    <div className='absolute top-0 left-0 flex flex-col items-center justify-center w-screen h-screen gap-3 bg-white '>
      <Navigation />

      <ICON.PiSmileySadThin size={80} />
      <div className='text-sm text-center text-gray-600'>
        서버에 일시적인 오류가 발생했습니다.
        <br />
        잠시 후 다시 시도해주세요.
      </div>
      <Button color={ButtonColor.primary} onClick={handleRetry} text='다시 시도하기' />
    </div>
  );
};

export default ServerErrorPage;
