export type FontType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h3_bold'
  | 'h4'
  | 'h4_bold'
  | 'h5'
  | 'h5_bold'
  | 'body1'
  | 'body2'
  | 'body3';

export enum TextType {
  h1 = 'text-h1 font-h1 leading-h1',
  h2 = 'text-h2 font-h2 leading-h2',
  h3 = 'text-h3 font-h3 leading-h3', // 16px
  h3_bold = 'text-h3 font-h3_bold leading-h3',
  h4 = 'text-h4 font-h4 leading-h4', // 14px
  h4_bold = 'text-h4 font-h4_bold leading-h4',
  h5 = 'text-h5 font-h5 leading-h5',
  h5_bold = 'text-h5 font-h5_bold leading-h5',
  body1 = 'text-body1 font-body leading-body1',
  body2 = 'text-body2 font-body leading-body2',
  body3 = 'text-body3 font-body leading-body3',
}
