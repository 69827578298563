import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { FLEX_CENTER } from 'styles/flex';
import { DisplayType } from 'types/common/display';
import CalendarHeader, { CalendarHeaderProps } from './header/CalendarHeader';
import './style/react-datepicker.css';

type CalendarProps = {
  displayType: DisplayType;
  style?: string;
  disabled?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  handleDate: any;
  handleRange: any;
  hasIcon?: boolean;
};

const Calendar = ({
  displayType,
  style = 'h-9',
  disabled,
  startDate,
  endDate,
  handleRange,
  handleDate,
  hasIcon = true,
}: CalendarProps) => {
  const calendarRef = useRef<DatePicker>(null);

  const renderHeader = ({ decreaseMonth, increaseMonth, date }: CalendarHeaderProps) => (
    <CalendarHeader decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} date={date} />
  );

  const hasRange = displayType === DisplayType.duration;

  return (
    <div className={`${FLEX_CENTER.row} w-52 h-9 border border-gray-99 px-2 overflow-hidden`}>
      {hasRange ? (
        <DatePicker
          className={`${style} ${disabled && 'text-gray-300 border-gray-300'} text-xs w-[220px] h-8`}
          // selected={new Date(startDate)}
          dateFormat='yyyy/MM/dd'
          onChange={handleRange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={hasRange}
          minDate={new Date()}
          // maxDate={new Date(endDate)}
          renderCustomHeader={renderHeader}
          ref={calendarRef}
        />
      ) : (
        <DatePicker
          className={`${style} ${disabled && 'text-gray-300 border-gray-300'} text-xs h-8`}
          selected={startDate}
          dateFormat='yyyy/MM/dd'
          onChange={handleDate}
          startDate={startDate}
          selectsRange={hasRange}
          minDate={new Date()}
          renderCustomHeader={renderHeader}
          ref={calendarRef}
        />
      )}

      {!disabled && hasIcon ? (
        <div onClick={() => calendarRef.current?.setOpen(true)} className='ml-2 cursor-pointer'>
          <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
            <rect x='0.6' y='1.7355' width='12.8' height='11.6647' stroke='#444444' strokeWidth='1.2' />
            <rect x='3.38281' width='1.4' height='3.40537' fill='#444444' />
            <rect x='9.21582' width='1.4' height='3.40537' fill='#444444' />
            <path d='M0.875558 6.26687L13.1231 6.27273' stroke='#444444' strokeWidth='1.2' />
          </svg>
        </div>
      ) : (
        <div className='w-9' />
      )}
    </div>
  );
};

export default Calendar;
