/* eslint-disable react/jsx-no-useless-fragment */
import { Fragment } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PoiCategory } from 'types/feature/category/poiCategory';
import NoChildrenCategory from '../no-children/NoChildrenCategory';

type UserCategoryItemProps = {
  category: PoiCategory;
  poiCategoryCodes: string[];
};

const UserCategoryItem = ({ category, poiCategoryCodes }: UserCategoryItemProps) => {
  return (
    <Droppable isDropDisabled droppableId='user-category-item-wrapper'>
      {provided => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className='flex flex-col items-center justify-center w-full gap-4 py-3 border'
        >
          <span className='text-xl font-medium text-gray-700 title'>{category.title.ko}</span>

          <div className='grid w-full grid-cols-3 max-h-[25rem] px-4 overflow-y-auto gap-2'>
            {category.children.map((child, idx) => {
              const isAddedInList = poiCategoryCodes?.includes(child.code);
              return (
                <Fragment key={child.code}>
                  {!isAddedInList && (
                    <Draggable draggableId={`${child.code}`} index={idx} key={`${child.code}`}>
                      {draggableProvided => (
                        <NoChildrenCategory
                          isAddedInList={isAddedInList}
                          draggableProvided={draggableProvided}
                          category={child}
                        />
                      )}
                    </Draggable>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </Droppable>
  );
};
export default UserCategoryItem;
