import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { LocationForm } from 'types/feature/menu/machine/machine';

type Props = {
  setMachinePlaceForm: Dispatch<SetStateAction<LocationForm>>;
};

const PositionController = ({ setMachinePlaceForm }: Props) => {
  const handleDrawKioskMarker = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setMachinePlaceForm((prev: LocationForm) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='기기 위치 좌표'
        direction='column'
        gap='gap-2.5'
        control={
          <div className='flex gap-5'>
            <FormControl
              name='X'
              labelSize={50}
              labelTextSize='h5'
              control={
                <Input
                  placeholder='X'
                  textStyle='h5'
                  width='w-[60px]'
                  name='positionX'
                  onChange={handleDrawKioskMarker}
                />
              }
            />
            <FormControl
              name='Y'
              labelSize={50}
              labelTextSize='h5'
              control={
                <Input
                  placeholder='Y'
                  textStyle='h5'
                  width='w-[60px]'
                  name='positionY'
                  onChange={handleDrawKioskMarker}
                />
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default PositionController;
