import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { LocationForm } from 'types/feature/menu/machine/machine';
import { changeRotation } from 'utils/map/mapControl';

type Props = {
  setMachinePlaceForm: Dispatch<SetStateAction<LocationForm>>;
};

const RotationController = ({ setMachinePlaceForm }: Props) => {
  const handleChangeRotationMap = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    changeRotation(Number(value));
    setMachinePlaceForm((prev: LocationForm) => ({
      ...prev,
      mapPosition: {
        ...prev.mapPosition,
        rotation: value,
      },
    }));
  };
  return (
    <div className='p-5 border-b'>
      <FormControl
        name='방향각'
        direction='column'
        control={
          <FormControl
            name='Rotation'
            labelTextSize='h5'
            control={
              <Input
                placeholder='방향각'
                type='number'
                textStyle='h5'
                width='w-[100px]'
                onChange={handleChangeRotationMap}
              />
            }
          />
        }
      />
    </div>
  );
};

export default RotationController;
