import Badge from 'components/common/badge/Badge';

type Props = {
  used: boolean;
  toggleBadge: () => void;
};

const UsedBadge = ({ used, toggleBadge }: Props) => {
  return (
    <div onClick={toggleBadge}>
      <Badge name={used ? '사용' : '미사용'} badgeType={used ? 'USED' : 'DISABLED'} />
    </div>
  );
};
export default UsedBadge;
