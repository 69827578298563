import { OverlayPosition } from 'hooks/common/useTableAction';
import React from 'react';
import { ContentFile } from 'types/feature/content/contents';
import { formatMegaByte } from 'utils/file/unit/fileUnit';

type ThumbNailOverlayProps = {
  overlayPosition: OverlayPosition;
  contentFile: ContentFile;
  fileType: string;
};

const ThumbnailOverlay = ({ overlayPosition, contentFile, fileType }: ThumbNailOverlayProps) => {
  return (
    <div
      style={{ left: overlayPosition.left, top: overlayPosition.top + 10 }}
      className='absolute z-50 flex flex-col justify-start w-48 h-auto gap-2 p-2 bg-white border rounded-lg'
    >
      <div className='w-full'>
        <div className='w-30'>
          {fileType === 'IMAGE' ? (
            <img className='w-full' src={contentFile.uri} alt='thumbnail' />
          ) : (
            <video src={contentFile.uri} autoPlay muted />
          )}
        </div>

        <ul className='w-full h-full p-2 pl-4 list-disc'>
          <li>
            <div className='flex gap-2'>
              <span className='text-xs whitespace-nowrap'>파일명 : </span>
              <span className='w-full overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap'>
                {contentFile.originalName}
              </span>
            </div>
          </li>

          <li>
            <div className='flex gap-2'>
              <span className='text-xs whitespace-nowrap '>크기 : </span>
              <span className='text-xs'>{formatMegaByte(contentFile.size)}MB</span>
            </div>
          </li>

          {/* <li>
            <div className='flex gap-2'>
              <span className='whitespace-nowrap'>프레임 : </span>
              <span>
                {contentFile.width} * {contentFile.height}
              </span>
            </div>
          </li> */}
        </ul>
      </div>
    </div>
  );
};
export default ThumbnailOverlay;
