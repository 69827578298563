import { ConnectedMachine } from 'types/feature/menu/machine/machine';

export type ConnectedStatus = {
  connected: number;
  disconnected: number;
};

export type MachineStatus = {
  machines: ConnectedMachine[];
  status: ConnectedStatus;
};

export enum MachineCommand {
  'RESET' = 'RESET',
}
