import React from 'react';

const CustomFieldTableHeader = () => {
  return (
    <>
      <colgroup>
        <col width='5%' />
        <col width='15%' />
        <col width='15%' />
        <col width='15%' />
        <col width='15%' />
        <col width='15%' />
        <col width='10%' />
      </colgroup>
      <thead className='h-12 border-b-2 border-t-[1px] border-[#222] '>
        <th> </th>
        <th className='bg-[#f9f9f9]'>항목 타이틀</th>
        <th>영어</th>
        <th>일본어</th>
        <th>중국어 간체</th>
        <th>중국어 번체</th>
        <th> </th>
      </thead>
    </>
  );
};

export default CustomFieldTableHeader;
