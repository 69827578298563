import { Workspace, MachineType } from 'types/feature/workspace/workspace';
import { create } from 'zustand';

type WorkspaceProps = {
  workspace: Workspace | null;
  setWorkspace: (workspace: Workspace) => void;

  workspaceMachineType: MachineType;
  setWorkspaceMachineType: (workspaceMachineType: MachineType) => void;
};

const useWorkspaceStore = create<WorkspaceProps>((set, get) => ({
  workspaceMachineType: 'WEB',
  setWorkspaceMachineType: (workspaceMachineType: MachineType) => set(() => ({ workspaceMachineType })),

  workspace: null,
  setWorkspace: (workspace: Workspace) => set(() => ({ workspace })),
}));

export default useWorkspaceStore;
