import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import useLanguage from 'hooks/feature/language/useLanguage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Language } from 'types/feature/language/language';
import { Tenant } from 'types/feature/tenant/getTenant';
import CommonDetails from './details/common/CommonDetails';
import CustomDetails from './details/custom/CustomDetails';
import MapDetails from './details/map/MapDetails';
import FoldIcon from './fold-icon/FoldIcon';

type TenantDetailProps = {
  tenant: Tenant | null;
  fetchTenants: () => Promise<void>;
  visibleFoldIcon: boolean;
  closeDetail: () => void;
};

const TenantDetail = ({ tenant, fetchTenants, visibleFoldIcon, closeDetail }: TenantDetailProps) => {
  const [displayLanguageList, setDisplayLanguageList] = useState<Language[]>([]);
  const { workspaceId } = useParams();
  const { getLanguages } = useLanguage();

  const getLanguageList = async () => {
    if (workspaceId) {
      const result = await getLanguages(workspaceId);
      const displayLanguages: Language[] = result.filter(lang => lang.used);
      setDisplayLanguageList(displayLanguages);
    }
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  if (!tenant) return <> </>;

  return (
    <div className='w-[480px] h-full justify-between relative border border-gray-99'>
      <div className={`w-full h-full flex flex-col overflow-y-auto`}>
        <MapDetails tenant={tenant} />

        <Border borderColor={BorderColor.lightGray} />

        <CommonDetails tenant={tenant} fetchTenants={fetchTenants} displayLanguageList={displayLanguageList} />

        {tenant.tenantCustomFields.length > 0 && (
          <>
            <Border borderColor={BorderColor.lightGray} />

            <CustomDetails
              fetchTenants={fetchTenants}
              fields={tenant.tenantCustomFields}
              tenantId={tenant.id}
              displayLanguageList={displayLanguageList}
            />
          </>
        )}

        {visibleFoldIcon && <FoldIcon onClick={closeDetail} />}
      </div>
    </div>
  );
};
export default TenantDetail;
