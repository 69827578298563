import React from 'react';

const CategoryGroupsTableHeader = () => {
  return (
    <>
      <colgroup>
        <col width='10%' />
        <col width='10%' />
        <col width='15%' />
        <col width='45%' />
        <col width='10%' />
        <col width='10%' />
      </colgroup>

      <thead>
        <tr className='whitespace-nowrap'>
          <th />
          <th>노출 순서</th>
          <th>코드</th>
          <th className='text-left'>타이틀</th>
          <th>설정</th>
          <th>삭제</th>
        </tr>
      </thead>
    </>
  );
};
export default CategoryGroupsTableHeader;
