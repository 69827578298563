import { TextType } from 'types/common/text';
import { CustomPageType } from 'types/feature/custom-page/customPage';

type Props = {
  handleClickMenuType: (type: string) => void;
  selectedCustomMenuType: CustomPageType;
};

const MenuTypes = ({ handleClickMenuType, selectedCustomMenuType }: Props) => {
  return (
    <div className='flex items-center w-full'>
      {CUSTOM_MENUS.map(({ type, title }) => {
        return (
          <div
            key={type}
            className={`
              border p-3 flex-1
              flex items-center justify-center cursor-pointer
              ${TextType.h4}
              ${
                selectedCustomMenuType === type
                  ? 'bg-state-blue_bg border-gray-44 text-gray-44  font-semibold'
                  : 'border-gray-200 text-gray-99'
              }`}
            onClick={handleClickMenuType.bind(this, type)}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};
export default MenuTypes;

const CUSTOM_MENUS = [
  { type: 'LIST', title: '목록형' },
  { type: 'CARD', title: '카드형' },
];
