import React, { ChangeEvent } from 'react';
import { FLEX_CENTER } from 'styles/flex';

type RadioProps = {
  value: string;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Radio = ({ value, label, disabled, checked, onChange }: RadioProps) => {
  return (
    <div className={`${FLEX_CENTER.row} gap-4 w-full`}>
      <input
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className={`w-4 h-4 ${!disabled && 'cursor-pointer'}
       ${label && 'peer/label'}
        `}
        type='radio'
        id={label}
        value={value}
        name='radio'
      />

      {label && (
        <label
          className={`h-8 flex-1 ${FLEX_CENTER.row} text-sm
        ${!disabled && 'cursor-pointer'}
        `}
          htmlFor={label}
        >
          {label}
        </label>
      )}
    </div>
  );
};
export default Radio;
