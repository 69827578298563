import React from 'react';

const OnBlue = () => {
  return (
    <div className='w-[14px] h-[20px] flex items-center'>
      <div className='w-[14px] h-[14px] shrink-0 bg-[#586DB9] rounded-full border border-[#586DB9]' />
    </div>
  );
};
export default OnBlue;
