export const PAGINATION_LIMIT = 10;

// Poc 기능을 시연할 workspaceId (coldbox) [Demo 즐겨찾기]
export const COLDBOX_POC_WORKSPACE_ID = '968D0F30B9B147B9898FE50FD083B4A8';

// Poc 기능을 시연할 workspaceId (상용) [Demo 즐겨찾기 및 공유]
export const PROD_POC_WORKSPACE_ID = '7AC8A238E51F44E2AA411C0AE4514B47';

// 기존 영업에서 사용하던 가산 workspaceId (상용) [Demo 주차위치]
export const PROD_SALES_WORKSPACE_ID = 'C92E22AEB9F246FD82EB14638693A5D6';
